import { Blockchain } from '../enums/Blockchain'
import { SearchAsyncItemQueryParams } from '../enums/SearchAsyncItemQueryParams'
import { SearchAsyncItemsResponse } from '../enums/SearchAsyncItemsResponse'
import { AsyncItem } from '../types/AsyncItem'
import { EmptyResponse } from '../types/EmptyResponse'
import { PatchAsyncItemRequest } from '../types/PatchAsyncItemRequest'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface AsyncItemInterface {
    get(id: string): Promise<AxiosResponse<AsyncItem>>
    search(filters: SearchAsyncItemQueryParams): Promise<AxiosResponse<SearchAsyncItemsResponse>>
    approve(id: string): Promise<AxiosResponse<EmptyResponse>>
    approveAndChooseBlockchain(
        id: string,
        blockchain: Blockchain,
        beneficiaryTenantId?: string,
    ): Promise<AxiosResponse<EmptyResponse>>
    approveAndSetTenantCustomer(
        id: string,
        tenantCustomerId: string,
    ): Promise<AxiosResponse<EmptyResponse>>
    rejectOnboarding(id: string): Promise<AxiosResponse<EmptyResponse>>
    setExpectedBlock(id: string, expectedBlockId: string): Promise<AxiosResponse<EmptyResponse>>
    retry(id: string): Promise<AxiosResponse<EmptyResponse>>
    patch(id: string, data: PatchAsyncItemRequest): Promise<AxiosResponse<EmptyResponse>>
    setCreditsReturned(id: string): Promise<AxiosResponse<EmptyResponse>>
}

export const AsyncItemApi: AsyncItemInterface = Object.freeze({
    get(id: string): Promise<AxiosResponse<AsyncItem>> {
        return ApiService.get('/admin/async-item', id)
    },
    search(filters: SearchAsyncItemQueryParams): Promise<AxiosResponse<SearchAsyncItemsResponse>> {
        return ApiService.get('/admin/async-item/async-items', null, filters)
    },

    approve(id: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/async-item/approve-async-item', { asyncItemId: id })
    },

    approveAndChooseBlockchain(
        id: string,
        blockchain: Blockchain,
        beneficiaryTenantId?: string,
    ): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/async-item/approve-async-item/set-blockchain', {
            asyncItemId: id,
            blockchain,
            beneficiaryTenantId,
        })
    },
    approveAndSetTenantCustomer(
        id: string,
        tenantCustomerId: string,
    ): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/async-item/approve-async-item/set-tenant-customer', {
            async_item_id: id,
            tenant_customer_id: tenantCustomerId,
        })
    },
    rejectOnboarding(id: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/async-item/reject-onboarding', {
            async_item_id: id,
        })
    },
    setExpectedBlock(id: string, expectedBlockId: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/async-item/set-expected-block', {
            async_item_id: id,
            expected_block_id: expectedBlockId,
        })
    },

    retry(id: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.get(`/admin/async-item/${id}/retry`)
    },

    patch(id: string, data: PatchAsyncItemRequest): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.patch(`/admin/async-item/${id}`, data)
    },
    setCreditsReturned(id: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/async-item/set-credits-returned', {
            async_item_id: id,
        })
    },
})
