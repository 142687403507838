import { CreateApiClient } from './createApiClient'
import { AxiosRequestHeaders } from 'axios'

const ApiService = {
    async get(resource: string, id: string | null = null, queryParams: any = {}) {
        const apiClient = await CreateApiClient()
        if (id) {
            resource += '/' + id
        }
        return apiClient.get(resource, { params: queryParams })
    },
    async post(resource: string, data: any, headers: AxiosRequestHeaders | {} = {}) {
        const apiClient = await CreateApiClient()
        return apiClient.post(
            resource,
            Object.keys(data).reduce(
                (acc, key) => (data[key] === undefined ? acc : { ...acc, [key]: data[key] }),
                {},
            ),
            {
                headers,
            },
        )
    },
    async patch(resource: string, data: any = {}) {
        const apiClient = await CreateApiClient()
        return apiClient.patch(resource, data)
    },
    async put(resource: string, data: any = {}) {
        const apiClient = await CreateApiClient()
        return apiClient.put(resource, data)
    },
    async delete(resource: string, data: any = {}) {
        const apiClient = await CreateApiClient()
        return apiClient.delete(resource, {
            data,
        })
    },
}

export default ApiService
