import ListItem from './ListItem'
import { UnbridgeEventTypesViewProps } from './UnbridgeEventTypes'

const ListView = ({
    selectedBlocks,
    getSelectOptions,
    onChangeSelectSerialInputHandler,
    getSelectFilterOptions,
    onRemoveBlockHandler,
    onChangeAmountToUnbridgeForSelectedBlockHandler,
    onBlurBlockInput,
    validationUnbridgingBlocksData,
}: UnbridgeEventTypesViewProps): JSX.Element => {
    const getValidationDataForListItem = (index: number) => {
        return validationUnbridgingBlocksData.find((item) => item.index === index)
    }

    return (
        <ul className="px-10px">
            {selectedBlocks.map((block, index) => (
                <ListItem
                    key={block.id + index.toString()}
                    index={index}
                    selectedBlock={block}
                    getSelectOptions={getSelectOptions}
                    onChangeSelectSerialInputHandler={onChangeSelectSerialInputHandler}
                    getSelectFilterOptions={getSelectFilterOptions}
                    onRemoveBlockHandler={onRemoveBlockHandler}
                    onChangeAmountToUnbridgeForSelectedBlockHandler={
                        onChangeAmountToUnbridgeForSelectedBlockHandler
                    }
                    onBlurBlockInput={onBlurBlockInput}
                    validationUnbridgingBlocksData={getValidationDataForListItem(index) ?? null}
                />
            ))}
        </ul>
    )
}

export default ListView
