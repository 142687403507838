import { User } from '../types/User'
import ConfirmationModal from './ConfirmationModal'
import React, { useState } from 'react'

interface ToggleIsUserActiveProps {
    user: User
    disable?: boolean
    onChange: (userId: string, isActive: boolean) => Promise<void>
}

export const ToggleIsUserActive = ({
    user,
    onChange,
    disable = false,
}: ToggleIsUserActiveProps): JSX.Element | null => {
    const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false)

    return (
        <>
            <label className="flex items-center relative cursor-pointer">
                <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={user.is_active}
                    disabled={disable}
                    onChange={() => setShowConfirmationModal(true)}
                />
                <div
                    className={`${
                        disable
                            ? "w-11 h-6 bg-gray-200 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600"
                            : "w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"
                    }`}
                    title={disable ? 'You cannot disable yourself!' : ''}
                ></div>
                <span
                    className={`${
                        disable
                            ? 'ml-3 text-sm font-medium text-gray-400 dark:text-gray-500'
                            : 'ml-3 text-sm font-medium text-gray-900 dark:text-gray-300'
                    }`}
                >
                    {(user.is_active && !disable && 'Active') ||
                        (!user.is_active && !disable && 'Inactive') ||
                        (disable && '')}
                </span>
            </label>
            <ConfirmationModal
                question={
                    <div>
                        <p>
                            Are you sure you want to {user.is_active ? 'inactivate' : 'activate'}{' '}
                            current user?
                        </p>
                    </div>
                }
                confirmButtonText="Confirm"
                cancelButtonText="Cancel"
                show={showConfirmationModal}
                onClose={() => setShowConfirmationModal(false)}
                onConfirm={() => {
                    setShowConfirmationModal(false)
                    void onChange(user.id, !user.is_active)
                }}
            />
        </>
    )
}
