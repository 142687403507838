import { AsyncItemApi } from '../../api/asyncItem'
import { UnbridgeRequestApi } from '../../api/unbridgeRequest'
import { AsyncItemStatuses } from '../../enums/AsyncItemStatuses'
import { Page } from '../../enums/Page'
import { UnbridgeCreditsSubStatuses } from '../../enums/UnbridgeCreditsSubstatuses'
import { UnbridgeRequest } from '../../types/unbridgeRequest/UnbridgeRequest'
import { UnbridgeRequestStatuses } from '../../types/unbridgeRequest/UnbridgeRequestStatuses'
import ConfirmationModal from '../ConfirmationModal'
import { CopyButton } from '../CopyButton'
import ErrorLabel from '../ErrorLabel'
import UnbridgeEventSourceBlocks from './UnbridgeEventSourceBlocks'
import UnbridgingEventDetails from './UnbridgingEventDetails'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export interface SelectedSourceBlock {
    id: string
    vintage_id: string
    registry: string
    amount: string
    serial: string
    unbridgingAmountForThisBlock: number
    unbridgingBlockSerial: string
    remainingBlockSerial: string
    registry_id: string
}

const UnbridgingEvent = (): JSX.Element => {
    const navigate = useNavigate()
    const [unbridgingRequest, setUnbridgingRequest] = useState<UnbridgeRequest | null>(null) // to be added type
    const [error, setError] = useState<string | null>(null)
    const [haveBlocksBeenUnbridged, setHaveBlocksBeenUnbridged] = useState<boolean>(true)
    const [canUnbridgeBlocks, setCanUnbridgeBlocks] = useState<boolean>(false)
    const [selectedSourceBlocks, setSelectedSourceBlocks] = useState<SelectedSourceBlock[]>([])
    const [showUnbridgingConfirmationModal, setShowUnbridgingConfirmationModal] =
        useState<boolean>(false)
    const [serialsValidated, setSerialsValidated] = useState<boolean>(false)
    const { id } = useParams<{ id: string }>()

    const generateDescription = (): string => {
        if (!unbridgingRequest) {
            return ''
        }
        return `Thallo Two-Way Bridge Unbridging: ${
            unbridgingRequest!.tenant.entity_name
        } is unbridging ${unbridgingRequest?.quantity.toString()} tCO2e from project ${
            unbridgingRequest!.vintage.project.name
        } vintage ${unbridgingRequest?.vintage.vintage}`
    }

    const fetchUnbridgingRequest = async (): Promise<void> => {
        if (!id) {
            return
        }

        const [unbridgingResponse, asyncItemResponse] = await Promise.all([
            UnbridgeRequestApi.getByAsyncItemId(id),
            AsyncItemApi.get(id),
        ])
        setUnbridgingRequest(unbridgingResponse.data)
        if (
            unbridgingResponse.data.on_chain_request_id &&
            unbridgingResponse.data.status === UnbridgeRequestStatuses.PENDING &&
            asyncItemResponse.data.status === AsyncItemStatuses.MANUAL &&
            asyncItemResponse.data.sub_status ===
                UnbridgeCreditsSubStatuses.PENDING_UNBRIDGE_BLOCKS_DB
        ) {
            setCanUnbridgeBlocks(true)
        }
    }

    const onUnbridgeBlocksButtonClicked = (): void => {
        setError(null)
        if (!selectedSourceBlocks.length) {
            setError('Please add block')
            return
        } else if (selectedSourceBlocks.find((block) => !block.serial.length)) {
            setError('Please select block serial')
            return
        } else if (
            selectedSourceBlocks.find(
                (block) =>
                    Number.isNaN(block.unbridgingAmountForThisBlock) ||
                    block.unbridgingAmountForThisBlock <= 0,
            )
        ) {
            setError('Please insert valid amount')
            return
        } else if (
            !BigNumber.from(unbridgingRequest?.quantity).eq(
                selectedSourceBlocks.reduce(
                    (acc, currentValue) => acc + currentValue.unbridgingAmountForThisBlock,
                    0,
                ),
            )
        ) {
            setError(
                'The total unbridging from all blocks should equal the amount in the original unbridging request',
            )
            return
        } else if (
            selectedSourceBlocks.find((block) => {
                if (
                    BigNumber.from(block.unbridgingAmountForThisBlock).lt(
                        BigNumber.from(block.amount),
                    ) &&
                    (!block.unbridgingBlockSerial || !block.remainingBlockSerial)
                ) {
                    setError(
                        `Amount unbridging from a block: '${block.serial}' is less than the total contained in the block, unbridging and remaining serials are required`,
                    )
                    return block
                }
            })
        ) {
            return
        } else if (!serialsValidated) {
            setError('Please ensure unbridging and remaining block serials are valid')
        } else {
            setShowUnbridgingConfirmationModal(true)
        }
    }

    const handleUnbridgeBlocks = async () => {
        if (id && selectedSourceBlocks.length > 0) {
            await UnbridgeRequestApi.unbridgeBlocks({
                async_item_id: id,
                unbridge_blocks: selectedSourceBlocks.map((block) => {
                    return {
                        id: block.id,
                        serial: block.serial,
                        unbridge_quantity: String(block.unbridgingAmountForThisBlock),
                        unbridged_serial: block.unbridgingBlockSerial,
                        remaining_serial: block.remainingBlockSerial,
                    }
                }),
            })
            navigate(Page.ASYNC_ITEMS)
        }
    }

    useEffect(() => {
        void fetchUnbridgingRequest()
    }, [])

    if (!unbridgingRequest) {
        return <></>
    }

    return (
        <div>
            <h2 className="mb-5 text-left">Unbridging Event</h2>
            <UnbridgingEventDetails
                unbridgingRequest={unbridgingRequest}
                hasBlockBeenUnbridged={false}
            />
            <div className="text-left mb-5">
                <h2 className="mb-5 text-left">Unbridging Decsription</h2>
                <div className="flex flex-row">
                    <CopyButton text={generateDescription()}></CopyButton>
                    <p className="text-xs my-auto">{generateDescription()}</p>
                </div>
            </div>
            <UnbridgeEventSourceBlocks
                selectedSourceBlocks={selectedSourceBlocks}
                onChange={setSelectedSourceBlocks}
                asyncItemId={id}
                haveBlocksBeenUnbridged={haveBlocksBeenUnbridged}
                setHaveBlocksBeenUnbridged={setHaveBlocksBeenUnbridged}
                canUnbridgeBlocks={canUnbridgeBlocks}
                unbridgingRequest={unbridgingRequest}
                setSerialsValidated={setSerialsValidated}
            />
            <hr className="bg-black my-10 h-0.5 w-full" />
            <>
                {!haveBlocksBeenUnbridged && canUnbridgeBlocks && (
                    <div className="flex">
                        <button
                            className="px-4 py-2.5 rounded-lg text-white bg-[#3e3d3d]"
                            onClick={onUnbridgeBlocksButtonClicked}
                            disabled={
                                haveBlocksBeenUnbridged ||
                                showUnbridgingConfirmationModal ||
                                !serialsValidated
                            }
                        >
                            Unbridge Blocks
                        </button>
                    </div>
                )}
            </>
            <div className="h-4 mt-2 text-left">{error && <ErrorLabel error={error} />}</div>
            <ConfirmationModal
                show={showUnbridgingConfirmationModal}
                onClose={() => setShowUnbridgingConfirmationModal(false)}
                onConfirm={handleUnbridgeBlocks}
                confirmButtonText="OK"
                cancelButtonText="Cancel"
            />
        </div>
    )
}

export default UnbridgingEvent
