import { UnbridgeRequestApi } from '../../api/unbridgeRequest'
import { BridgedBlockStatus } from '../../types/BridgedBlockStatus'
import { UnbridgeRequest } from '../../types/unbridgeRequest/UnbridgeRequest'
import { UnbridgeSourceBlock } from '../../types/unbridgeRequest/UnbridgeSourceBlock'
import UnbridgeEventUnbridgedBlocks from './UnbridgeEventUnbridgedBlocks'
import UnbridgeSourceBlocksSelect from './UnbridgeSourceBlocksSelect'
import { SelectedSourceBlock } from './UnbridgingEvent'
import { useEffect, useRef, useState } from 'react'

interface UnbridgeEventSourceBlocksProps {
    selectedSourceBlocks: SelectedSourceBlock[]
    onChange: (blocks: SelectedSourceBlock[]) => void
    asyncItemId?: string
    haveBlocksBeenUnbridged: boolean
    setHaveBlocksBeenUnbridged: (isBridged: boolean) => void
    canUnbridgeBlocks: boolean
    unbridgingRequest: UnbridgeRequest
    setSerialsValidated: (serialsValidated: boolean) => void
}

const UnbridgeEventSourceBlocks = ({
    selectedSourceBlocks,
    onChange,
    asyncItemId,
    haveBlocksBeenUnbridged,
    setHaveBlocksBeenUnbridged,
    canUnbridgeBlocks,
    unbridgingRequest,
    setSerialsValidated,
}: UnbridgeEventSourceBlocksProps): JSX.Element => {
    const [bridgedBlocks, setBridgedBlocks] = useState<UnbridgeSourceBlock[]>([])
    const [associatedBlocks, setAssociatedBlocks] = useState<UnbridgeSourceBlock[]>([])
    const intervalRef = useRef<any>(null)

    const fetchBridgedSourceBlocks = async (): Promise<void> => {
        if (asyncItemId && unbridgingRequest.id !== void 0) {
            const response = await UnbridgeRequestApi.getSourceBlocks(asyncItemId)
            const unbridgingBlocks = response.data.filter(
                (block) =>
                    unbridgingRequest.id && block.unbridge_request_id === unbridgingRequest.id,
            )

            if (unbridgingBlocks.length === 0) {
                setBridgedBlocks(response.data)
                setHaveBlocksBeenUnbridged(false)
                return
            }
            if (unbridgingBlocks.length === 1) {
                setBridgedBlocks(unbridgingBlocks)
                setHaveBlocksBeenUnbridged(true)
                return
            }

            let parentBlockIds: string[] = []

            unbridgingBlocks.map((block) => {
                if (block.status === BridgedBlockStatus.SUPERSEDED) {
                    return
                }
                parentBlockIds.push(block.id)
            })
            const childBlocks = response.data.filter(
                (block) => block.parent_id && parentBlockIds.includes(block.parent_id),
            )
            setBridgedBlocks([...new Set([...unbridgingBlocks, ...childBlocks])])
            setHaveBlocksBeenUnbridged(true)
        }
    }

    const fetchAssociatedBlocks = async (): Promise<void> => {
        if (haveBlocksBeenUnbridged && asyncItemId && unbridgingRequest.id) {
            const response = await UnbridgeRequestApi.getAssociatedBlocks(
                asyncItemId,
                unbridgingRequest.id,
            )
            setAssociatedBlocks(response.data)
        }
    }

    useEffect(() => {
        intervalRef.current = setTimeout(() => {
            void fetchBridgedSourceBlocks()
        }, 0)
        return () => clearTimeout(intervalRef.current)
    }, [unbridgingRequest])

    useEffect(() => {
        void fetchAssociatedBlocks()
    }, [unbridgingRequest, haveBlocksBeenUnbridged])

    return (
        <>
            <h2 className="my-5 text-left">Unbridging Event Source Blocks</h2>
            {haveBlocksBeenUnbridged ? (
                <UnbridgeEventUnbridgedBlocks
                    bridgedBlocks={associatedBlocks}
                    unbridgeRequest={unbridgingRequest}
                />
            ) : (
                <UnbridgeSourceBlocksSelect
                    selectedBlocks={selectedSourceBlocks}
                    onChange={onChange}
                    bridgedBlocks={bridgedBlocks}
                    canUnbridgeBlocks={canUnbridgeBlocks}
                    unbridgingRequest={unbridgingRequest}
                    setSerialsValidated={setSerialsValidated}
                />
            )}
        </>
    )
}

export default UnbridgeEventSourceBlocks
