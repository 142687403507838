import TableViewRow from './TableViewRow'
import { UnbridgeEventTypesViewProps } from './UnbridgeEventTypes'

const TableView = ({
    selectedBlocks,
    getSelectOptions,
    onChangeSelectSerialInputHandler,
    getSelectFilterOptions,
    onRemoveBlockHandler,
    onChangeAmountToUnbridgeForSelectedBlockHandler,
    onBlurBlockInput,
    validationUnbridgingBlocksData,
}: UnbridgeEventTypesViewProps): JSX.Element => {
    const getValidationDataForTableRow = (index: number) => {
        return validationUnbridgingBlocksData.find((item) => item.index === index)
    }

    return (
        <table
            id="serial-table"
            className="overflow-x-scroll md:overflow-auto block md:table md:table-auto w-full"
        >
            <thead>
                <tr className="h-11 items-center bg-[#3e3d3d] text-white px-4 divide-x-[1px] divide-x-stone-300">
                    <th className="w-[250px] font-semibold text-xs text-center px-2">
                        Block Serial
                    </th>
                    <th className="min-w-[150px] font-semibold text-xs text-center px-2">Id</th>
                    <th className="min-w-[150px] font-semibold text-xs text-center px-2">
                        Block Registry
                    </th>
                    <th className="min-w-[150px] font-semibold text-xs text-center px-2">
                        Block Amount
                    </th>
                    <th className="min-w-[200px] font-semibold text-xs text-center px-2">
                        Unbridging Amount For This Block
                    </th>
                    <th className="min-w-[240px] font-semibold text-xs text-center px-2">
                        Unbridging Block Serial
                    </th>
                    <th className="min-w-[240px] font-semibold text-xs text-center px-2">
                        Remaining Block Serial
                    </th>
                    <th className="font-semibold text-xs text-center px-2">Actions</th>
                </tr>
            </thead>
            <tbody>
                {selectedBlocks.map((block, index: number) => (
                    <TableViewRow
                        index={index}
                        key={index}
                        selectedBlock={block}
                        getSelectOptions={getSelectOptions}
                        onChangeSelectSerialInputHandler={onChangeSelectSerialInputHandler}
                        getSelectFilterOptions={getSelectFilterOptions}
                        onRemoveBlockHandler={onRemoveBlockHandler}
                        onChangeAmountToUnbridgeForSelectedBlockHandler={
                            onChangeAmountToUnbridgeForSelectedBlockHandler
                        }
                        onBlurBlockInput={onBlurBlockInput}
                        validationUnbridgingBlocksData={getValidationDataForTableRow(index) ?? null}
                    />
                ))}
            </tbody>
        </table>
    )
}

export default TableView
