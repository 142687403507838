import ConfirmationModal from './ConfirmationModal'
// @ts-ignore - no types
import { JsonEditor } from 'jsoneditor-react'
import { useEffect, useState } from 'react'

interface JsonEditorModalProps {
    show: boolean
    json: any
    onClose: () => void
    onConfirm: (data: any) => void
}

export const JsonEditorModal = ({
    show,
    json,
    onClose,
    onConfirm,
}: JsonEditorModalProps): JSX.Element | null => {
    const [data, setData] = useState<any>()
    const [showAreYouSure, setShowAreYouSure] = useState(false)

    useEffect(() => {
        setData(json)
    }, [json])

    if (!show) return null
    return (
        <ConfirmationModal
            size="xl"
            show={show}
            onClose={() => {
                setShowAreYouSure(false)
                onClose()
            }}
            onConfirm={async () => {
                if (!showAreYouSure) {
                    setShowAreYouSure(true)
                } else {
                    onConfirm(data)
                }
            }}
            showHeader={false}
            question={
                <div>
                    <JsonEditor
                        value={json}
                        onChange={(data: any) => {
                            setShowAreYouSure(false)
                            setData(data)
                        }}
                        search={true}
                        history={true}
                    />
                </div>
            }
            confirmButtonText={showAreYouSure ? 'Are you sure?' : 'Save'}
            cancelButtonText="Cancel"
        />
    )
}
