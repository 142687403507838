import Layout from '../components/Layout'
import CreateUserForm from '../components/forms/CreateUserForm'
import { Page } from '../enums/Page'
import { useNavigate } from 'react-router-dom'

const CreateUser = (): JSX.Element => {
    const navigate = useNavigate()
    return (
        <Layout>
            <CreateUserForm
                onSaved={() => navigate(Page.USERS)}
                onReturn={() => navigate(Page.USERS)}
            />
        </Layout>
    )
}

export default CreateUser
