/* eslint-disable indent */
import { AsyncItemApi } from '../api/asyncItem'
import { RetirementRequestApi } from '../api/retirementRequest'
import ConfirmationModal from '../components/ConfirmationModal'
import { CopyButton } from '../components/CopyButton'
import ErrorLabel from '../components/ErrorLabel'
import Layout from '../components/Layout'
import RetirementEventDetails from '../components/RetirementEventDetails'
import RetirementEventSourceBlocks from '../components/RetirementEventSourceBlocks'
import { AsyncItemStatuses } from '../enums/AsyncItemStatuses'
import { AsyncItemSubStatuses } from '../enums/AsyncItemSubStatuses'
import { Blockchain } from '../enums/Blockchain'
import { Page } from '../enums/Page'
import { RetirementRequest } from '../types/RetirementRequest'
import { RetirementRequestStatuses } from '../types/RetirementRequestStatuses'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

export interface Block {
    id: string
    vintage_id: string
    registry: string
    amount: string
    serial: string
    retirementAmountForThisBlock: number
    retirementBlockSerial: string
    remainingBlockSerial: string
    registry_id: string
    blockchain: Blockchain | string
}

const Retirement = (): JSX.Element => {
    const navigate = useNavigate()
    const { id } = useParams<{ id: string }>()
    const [showRetireBlocksConfirmationModal, setShowRetireBlocksConfirmationModal] =
        useState<boolean>(false)
    const [retirementRequest, setRetirementRequest] = useState<RetirementRequest | null>(null)
    const [blocks, setBlocks] = useState<Block[]>([])
    const [error, setError] = useState<string | null>(null)
    const [haveBlocksBeenRetired, setHaveBlocksBeenRetired] = useState<boolean>(true)
    const [canRetireBlocks, setCanRetireBlocks] = useState<boolean>(false)
    const [serialsValidated, setSerialsValidated] = useState<boolean>(false)

    const handleRetireBlocks = async (): Promise<void> => {
        if (id) {
            await RetirementRequestApi.retireBlocks({
                async_item_id: id,
                retirement_blocks: blocks.map((block) => {
                    return {
                        id: block.id,
                        serial: block.serial,
                        vintage_id: block.vintage_id,
                        retire_quantity: String(block.retirementAmountForThisBlock),
                        retired_serial: block.retirementBlockSerial,
                        remaining_serial: block.remainingBlockSerial,
                    }
                }),
            })
            navigate(Page.ASYNC_ITEMS)
        }
    }

    const fetchRetirementRequest = async (): Promise<void> => {
        if (!id) {
            return
        }
        const [retirementResponse, asyncItemResponse] = await Promise.all([
            RetirementRequestApi.getByAsyncItemId(id),
            AsyncItemApi.get(id),
        ])
        setRetirementRequest(retirementResponse.data)
        if (
            retirementResponse.data.on_chain_request_id &&
            retirementResponse.data.status === RetirementRequestStatuses.PENDING &&
            asyncItemResponse.data.status === AsyncItemStatuses.MANUAL &&
            asyncItemResponse.data.sub_status === AsyncItemSubStatuses.PENDING_RETIRE_BLOCKS_DB
        ) {
            setCanRetireBlocks(true)
        }
    }

    useEffect(() => {
        fetchRetirementRequest()
    }, [])

    const onRetireBLocks = (): void => {
        setError(null)
        if (!blocks.length) {
            setError('Please add block')
            return
        } else if (blocks.find((block) => !block.serial.length)) {
            setError('Please select block serial')
            return
        } else if (
            blocks.find(
                (block) =>
                    Number.isNaN(block.retirementAmountForThisBlock) ||
                    block.retirementAmountForThisBlock <= 0,
            )
        ) {
            setError('Please insert valid amount')
            return
        } else if (
            !BigNumber.from(retirementRequest?.quantity).eq(
                blocks.reduce(
                    (acc, currentValue) => acc + currentValue.retirementAmountForThisBlock,
                    0,
                ),
            )
        ) {
            setError(
                'The total retired from all blocks should equal the amount in the original retirement request',
            )
            return
        } else if (
            blocks.find((block) => {
                if (
                    BigNumber.from(block.retirementAmountForThisBlock).lt(
                        BigNumber.from(block.amount),
                    ) &&
                    (!block.retirementBlockSerial || !block.remainingBlockSerial)
                ) {
                    setError(
                        `Amount retired from a block: '${block.serial}' is less than the total contained in the block, retired and remaining serials are required`,
                    )
                    return block
                }
            })
        ) {
            return
        } else if (!serialsValidated) {
            setError('Please ensure retired and remaining block serials are valid')
        } else {
            setShowRetireBlocksConfirmationModal(true)
        }
    }

    const generateArtworkElement = () => {
        if (!retirementRequest?.nft_artwork_url) {
            return <></>
        }
        const path = new URL(retirementRequest.nft_artwork_url)

        if (['https:', 'http:'].includes(path.protocol)) {
            return <img className="w-[400px] max-h-[258px]" src={path.href} alt={path.href} />
        }

        return <></>
    }

    const generateDescription = (): string => {
        return `Thallo Two-Way Bridge Retirement: ${
            retirementRequest?.retiree_override ?? retirementRequest?.tenant.entity_name
        } is retiring ${retirementRequest?.quantity.toString()} tCO2e from project ${
            retirementRequest?.vintage.project.name
        } vintage ${retirementRequest?.vintage.vintage}`
    }

    return (
        <Layout>
            <h2 className="mb-5 text-left">Retirement Event</h2>
            <RetirementEventDetails
                retirementRequest={retirementRequest}
                haveBlocksBeenRetired={haveBlocksBeenRetired}
            />
            <div className="text-left mb-5">
                <h2 className="mb-5 text-left">Retirement Description</h2>
                <div className="flex flex-row">
                    <CopyButton text={generateDescription()}></CopyButton>
                    <p className="text-xs my-auto">{generateDescription()}</p>
                </div>
            </div>
            <>
                {retirementRequest?.nft_artwork_url && (
                    <>
                        <h2 className="mb-5 text-left">NFT Artwork</h2>
                        <div className="">{generateArtworkElement()}</div>
                    </>
                )}
            </>
            <h2 className="my-5 text-left">Retirement Event Source Blocks</h2>
            <RetirementEventSourceBlocks
                haveBlocksBeenRetired={haveBlocksBeenRetired}
                setHaveBlocksBeenRetired={setHaveBlocksBeenRetired}
                canRetireBlocks={canRetireBlocks}
                asyncItemId={id}
                onChange={setBlocks}
                blocks={blocks}
                retirementRequest={retirementRequest}
                setSerialsValidated={setSerialsValidated}
            />
            <hr className="bg-black my-10 h-0.5 w-full" />
            <>
                {!haveBlocksBeenRetired && canRetireBlocks && (
                    <div className="flex">
                        <button
                            className="px-4 py-2.5 rounded-lg text-white bg-[#3e3d3d]"
                            onClick={onRetireBLocks}
                            disabled={
                                showRetireBlocksConfirmationModal ||
                                haveBlocksBeenRetired ||
                                !serialsValidated
                            }
                        >
                            Retire Blocks
                        </button>
                    </div>
                )}
            </>
            <div className="h-4 mt-2 text-left">{error && <ErrorLabel error={error} />}</div>
            <ConfirmationModal
                show={showRetireBlocksConfirmationModal}
                onClose={() => setShowRetireBlocksConfirmationModal(false)}
                onConfirm={handleRetireBlocks}
                confirmButtonText="OK"
                cancelButtonText="Cancel"
            />
        </Layout>
    )
}

export default Retirement
