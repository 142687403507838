export const sortArrByStringProp = (arr: Array<any>, propName: string) => {
    arr.sort((a, b) => {
        if (a[propName] < b[propName]) {
            return -1
        }
        if (a[propName] > b[propName]) {
            return 1
        }
        return 0
    })
}
