import { UserApi } from '../../api/user'
import { ErrorResponse } from '../../types/ErrorResponse'
import { UpdateUserPayload } from '../../types/UpdateUserPayload'
import { User } from '../../types/User'
import { getEmailRegex } from '../../utils/getEmailRegex'
import ErrorLabel from '../ErrorLabel'
import { AxiosError } from 'axios'
import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'

interface UpdateUserFormProps {
    onSaved: () => void
    onReturn: () => void
    user: User
}

interface FormData {
    email: string
    firstName: string
    lastName: string
}

const UpdateUserForm = ({ onSaved, onReturn, user }: UpdateUserFormProps): JSX.Element => {
    const [error, setError] = useState<string | null>(null)
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormData>()

    const emailField = register('email', {
        required: true,
        pattern: getEmailRegex(),
    })

    const firstNameField = register('firstName', {
        required: true,
    })

    const lastNameField = register('lastName', {
        required: true,
    })

    const onSubmit = async (updateData: FormData) => {
        try {
            setError(null)
            const updateUserPayload: UpdateUserPayload = {
                ...(updateData.email.trim() !== user.email && { email: updateData.email }),
                ...(updateData.firstName.trim() !== user.first_name && {
                    first_name: updateData.firstName.trim(),
                }),
                ...(updateData.lastName.trim() !== user.last_name && {
                    last_name: updateData.lastName.trim(),
                }),
            }
            await UserApi.updateUser(user.id, updateUserPayload)
            onSaved()
        } catch (e: unknown) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            }
        }
    }

    useEffect(() => {
        setValue('email', user.email)
        setValue('firstName', user.first_name)
        setValue('lastName', user.last_name)
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[2%]">
                    <button
                        className="px-2 h-12 flex justify-center items-center border border-black rounded-lg "
                        onClick={onReturn}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            width="20"
                            height="20"
                        >
                            <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                        </svg>
                    </button>
                </div>
                <div className="flex flex-col mt-8">
                    <label className="text-sm text-left font-semibold text-gray-700">Email*</label>
                    <input
                        type="email"
                        // disabled={isFormDisabled}
                        {...emailField}
                        placeholder="Email"
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.email?.type === 'required' && (
                            <ErrorLabel error="Email is required" />
                        )}
                        {errors?.email?.type === 'pattern' && <ErrorLabel error="Invalid email" />}
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">
                        First Name*
                    </label>
                    <input
                        type="text"
                        placeholder="Enter First Name"
                        {...firstNameField}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.firstName?.type === 'required' && (
                            <ErrorLabel error="First Name is required" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">
                        Last Name*
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Last Name"
                        {...lastNameField}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.lastName?.type === 'required' && (
                            <ErrorLabel error="Last Name is required" />
                        )}
                    </div>
                </div>
                {error && <ErrorLabel error={error} />}
                <div className="flex justify-end items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                        data-modal-toggle="defaultModal"
                        onClick={onReturn}
                        className="border-2 border-gray-100 hover:bg-gray-100 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Cancel
                    </button>
                    <button
                        data-modal-toggle="defaultModal"
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Save
                    </button>
                </div>
            </form>
        </>
    )
}

export default UpdateUserForm
