import Layout from '../components/Layout'
import CreateUnbridgeRequestForm from '../components/UnbridgeRequest/CreateUnbridgeRequestForm'

const CreateUnbridgeRequest = (): JSX.Element => {
    return (
        <Layout>
            <CreateUnbridgeRequestForm />
        </Layout>
    )
}

export default CreateUnbridgeRequest
