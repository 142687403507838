import { ExpectedBlockApi } from '../api/expectedBlock'
import { ProjectApi } from '../api/project'
import { RegistryApi } from '../api/registry'
import { TenantApi } from '../api/tenant'
import { TenantCustomerApi } from '../api/tenantCustomer'
import CheckBoxWrapper from '../components/CheckBoxWrapper'
import ErrorLabel from '../components/ErrorLabel'
import Layout from '../components/Layout'
import NumberInput from '../components/NumberInput'
import SdgLogo from '../components/SdgLogo'
import {
    CreateExpectedBlockFormErrors,
    CreateExpectedBlockForm,
} from '../components/forms/CreateExpectedBlockForm'
import { ExpectedBlockStatus } from '../enums/ExpectedBLockStatus'
import { InvoiceStatus } from '../enums/InvoiceStatus'
import { Page } from '../enums/Page'
import { ProjectLocation } from '../enums/ProjectLocation'
import { SDG } from '../enums/SDG'
import { ProjectType } from '../types/ProjectType'
import { Registry } from '../types/Registry'
import { Tenant } from '../types/Tenant'
import { TenantCustomer } from '../types/TenantCustomer'
import { convertCentsToUsd } from '../utils/convertCentsToUsd'
import { convertUsdToCents } from '../utils/convertUsdToCents'
import { getCountryNameFromCountryCode } from '../utils/getCountryNameFromCountryCode'
import { ChangeEvent, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const EditExpectedBLock = (): JSX.Element => {
    const params = useParams<{ id: string }>()
    const [formData, setFormData] = useState<Partial<CreateExpectedBlockForm>>({})
    const [formError, setFormError] = useState<Partial<CreateExpectedBlockFormErrors>>({})
    const [registries, setRegistries] = useState<Registry[]>([])
    const [projectTypes, setProjectTypes] = useState<ProjectType[]>([])
    const [status, setStatus] = useState<ExpectedBlockStatus | null>(null)
    const navigate = useNavigate()
    const [beneficiaryTenants, setBeneficiaryTenants] = useState<Tenant[]>([])
    const [tenantCustomers, setTenantCustomers] = useState<TenantCustomer[]>([])

    const fetchExpectedBlock = async () => {
        if (params.id) {
            const response = await ExpectedBlockApi.getByid(params.id)
            setFormData({
                project_name: response.data.project_name,
                project_description: response.data.project_description,
                type: response.data.project_type,
                registry_id: response.data.registry_id,
                registry_project_id: response.data.registry_project_id,
                vintage: response.data.vintage ? String(response.data.vintage) : undefined,
                vintage_registry_url: response.data.vintage_registry_url,
                amount: response.data.expected_amount,
                price_paid_per_credit: response.data?.price_paid_per_credit
                    ? convertCentsToUsd(response.data.price_paid_per_credit)
                    : undefined,
                invoice_reference: response.data.invoice_reference,
                invoice_status: response.data.invoice_status,
                sdgs: response.data.sdgs,
                location: response.data.location,
                registry_url: response.data.registry_url,
                beneficiary_tenant_id: response.data.beneficiary_tenant_id,
                tenant_customer_id: response.data.tenant_customer_id || null,
            })
            setStatus(response.data.status)
        }
    }

    const fetchRegistries = async () => {
        const response = await RegistryApi.getAll()
        setRegistries(response.data)
    }

    const fetchProjectTypes = async () => {
        if (formData.registry_id) {
            const response = await ProjectApi.getProjectTypesByRegistryId(formData.registry_id)
            setProjectTypes(response.data)
        }
    }

    const fetchBeneficiaries = async () => {
        const response = await TenantApi.getDevelopers()
        setBeneficiaryTenants(response.data)
    }

    const fetchTenantCustomers = async () => {
        if (formData.beneficiary_tenant_id) {
            const response = await TenantCustomerApi.getTenantCustomersByTenantId(
                formData.beneficiary_tenant_id,
            )
            setTenantCustomers(response.data.tenant_customers)
        }
    }

    useEffect(() => {
        fetchExpectedBlock()
    }, [])

    useEffect(() => {
        fetchProjectTypes()
    }, [formData.registry_id])

    useEffect(() => {
        fetchRegistries()
        fetchBeneficiaries()
    }, [])

    useEffect(() => {
        fetchTenantCustomers()
    }, [formData.beneficiary_tenant_id])

    const onSubmit = async (action: 'save' | 'saveAndValidate'): Promise<void> => {
        if (!params.id) return
        if (action === 'save') {
            if (formData.amount && Number(formData.amount) <= 0) {
                setFormError({ amount: 'Expected amount must be greater than 0' })
                return
            }

            await ExpectedBlockApi.update(params.id, {
                registry_id: formData?.registry_id === 'Choose' ? undefined : formData?.registry_id,
                registry_project_id: formData.registry_project_id?.trim(),
                project_name: formData?.project_name?.trim(),
                project_type: formData?.type === 'Choose' ? undefined : formData?.type,
                project_description: formData?.project_description?.trim(),
                vintage: formData.vintage ? Number(formData.vintage) : undefined,
                vintage_registry_url: formData.vintage_registry_url
                    ? formData.vintage_registry_url.trim()
                    : '',
                price_paid_per_credit: formData.price_paid_per_credit
                    ? String(convertUsdToCents(Number(formData.price_paid_per_credit)))
                    : null,
                expected_amount: formData.amount,
                status: status as ExpectedBlockStatus,
                invoice_status: formData.invoice_status,
                invoice_reference: formData.invoice_reference?.trim(),
                sdgs: formData.sdgs,
                location:
                    formData.location?.trim() === 'Choose' ? undefined : formData.location?.trim(),
                registry_url: formData.registry_url?.trim(),
                beneficiary_tenant_id:
                    formData.beneficiary_tenant_id === 'Choose'
                        ? undefined
                        : formData.beneficiary_tenant_id,
                tenant_customer_id:
                    formData?.tenant_customer_id === 'Choose'
                        ? null
                        : formData?.tenant_customer_id || null,
            })
            navigate(Page.EXPECTED_BLOCKS)
        } else {
            setFormError({})

            if (!formData.project_name) {
                setFormError({ project_name: 'Project name is required' })
                return
            }
            if (!formData.registry_id || formData.registry_id === 'Choose') {
                setFormError({ registry_id: 'Registry is required' })
                return
            }
            if (!formData.type || formData.type === 'Choose') {
                setFormError({ project_type: 'Project type is required' })
                return
            }
            if (!formData.location || formData.location === 'Choose') {
                setFormError({ location: 'Location is required' })
                return
            }
            if (!formData.project_description) {
                setFormError({ project_description: 'Project description is required' })
                return
            }
            if (!formData.registry_url) {
                setFormError({ registry_url: 'Registry URL is required' })
                return
            }
            if (!formData.registry_project_id) {
                setFormError({ registry_project_id: 'Registry project ID is required' })
                return
            }

            if (!formData.vintage || formData.vintage === 'Choose') {
                setFormError({ vintage: 'Vintage is required' })
                return
            }
            if (!formData.amount) {
                setFormError({ amount: 'Expected amount is required' })
                return
            }
            if (Number(formData.amount) <= 0) {
                setFormError({ amount: 'Expected amount must be greater than 0' })
                return
            }
            if (!formData.beneficiary_tenant_id) {
                setFormError({ beneficiary_tenant_id: 'Beneficiary tenant ID is required' })
                return
            }
            if (tenantCustomers.length > 0 && !formData.tenant_customer_id) {
                setFormError({
                    tenant_customer_id:
                        'Tenant customer ID is required when beneficiary tenant has customers',
                })
                return
            }
            setFormError({})
            await ExpectedBlockApi.update(params.id, {
                registry_id: formData.registry_id,
                registry_project_id: formData.registry_project_id?.trim(),
                project_name: formData?.project_name?.trim(),
                project_type: formData?.type,
                project_description: formData?.project_description?.trim(),
                vintage: formData.vintage ? Number(formData.vintage) : undefined,
                vintage_registry_url: formData.vintage_registry_url
                    ? formData.vintage_registry_url.trim()
                    : '',
                price_paid_per_credit: formData.price_paid_per_credit
                    ? String(convertUsdToCents(Number(formData.price_paid_per_credit)))
                    : null,
                expected_amount: formData.amount,
                status: ExpectedBlockStatus.READY_FOR_BRIDGING,
                invoice_status: formData.invoice_status,
                invoice_reference: formData.invoice_reference?.trim(),
                sdgs: formData.sdgs,
                location: formData.location?.trim(),
                registry_url: formData.registry_url?.trim(),
                beneficiary_tenant_id: formData.beneficiary_tenant_id,
                tenant_customer_id: formData.tenant_customer_id || null,
            })
            navigate(Page.EXPECTED_BLOCKS)
        }
    }

    const renderAvailableVintages = (): JSX.Element[] => {
        const vintages = []
        for (let i = 2010; i <= new Date().getFullYear() + 1; i++) {
            vintages.push(String(i))
        }
        return vintages.map((vintage: string) => {
            return (
                <option key={vintage} value={vintage}>
                    {vintage}
                </option>
            )
        })
    }

    return (
        <Layout>
            <form>
                <div>
                    <h3 className="bg-gray-300 text-left mb-5">Project</h3>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Project Name
                        </label>
                        <input
                            placeholder="Name"
                            value={formData.project_name ?? ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFormData({
                                    ...formData,
                                    project_name: e.target.value,
                                })
                            }
                            className="block p-4 my-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-5">
                            {formError?.project_name && (
                                <ErrorLabel error={formError.project_name} />
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/3 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Registry
                            </label>
                            <select
                                value={formData.registry_id ?? ''}
                                className="block p-2 my-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setFormData({
                                        ...formData,
                                        registry_id: e.target.value,
                                    })
                                }}
                            >
                                <option>Choose</option>
                                {registries.map((registry: Registry) => (
                                    <option key={registry.id} value={registry.id}>
                                        {registry.name}
                                    </option>
                                ))}
                            </select>
                            <div className="h-5">
                                {formError?.registry_id && (
                                    <ErrorLabel error={formError.registry_id} />
                                )}
                            </div>
                        </div>
                        <div className="w-1/3 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Project type
                            </label>
                            <select
                                className="block p-2 my-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                value={formData.type ?? ''}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setFormData({
                                        ...formData,
                                        type: e.target.value,
                                    })
                                }}
                            >
                                <option>Choose</option>
                                {projectTypes.map((projectType) => (
                                    <option
                                        key={projectType.project_type}
                                        value={projectType.project_type}
                                    >
                                        {projectType.project_type}
                                    </option>
                                ))}
                            </select>
                            <div className="h-5">
                                {formError?.project_type && (
                                    <ErrorLabel error={formError.project_type} />
                                )}
                            </div>
                        </div>
                        <div className="w-1/3 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium">Location</label>
                            <select
                                className="block p-2 mb-6 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                value={formData.location ?? ''}
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setFormData({
                                        ...formData,
                                        location: e.target.value as ProjectLocation,
                                    })
                                }}
                            >
                                <option>Choose</option>
                                {(
                                    Object.keys(ProjectLocation) as Array<
                                        keyof typeof ProjectLocation
                                    >
                                ).map((key) => (
                                    <option key={key} value={ProjectLocation[key]}>
                                        {getCountryNameFromCountryCode(ProjectLocation[key])}
                                    </option>
                                ))}
                            </select>
                            {formError?.location && <ErrorLabel error={formError.location} />}
                        </div>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Project Description
                        </label>
                        <textarea
                            rows={5}
                            placeholder="Desription"
                            className="block p-4 my-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                            value={formData.project_description ?? ''}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                setFormData({
                                    ...formData,
                                    project_description: e.target.value,
                                })
                            }}
                        />
                        <div className="h-5">
                            {formError?.project_description && (
                                <ErrorLabel error={formError.project_description} />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Registry Url
                            </label>
                            <input
                                type="text"
                                className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Registry Url"
                                value={formData.registry_url ?? ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFormData({
                                        ...formData,
                                        registry_url: e.target.value,
                                    })
                                }}
                            />
                            <div className="h-5">
                                {formError?.registry_url && (
                                    <ErrorLabel error={formError.registry_url} />
                                )}
                            </div>
                        </div>
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Registry Project Id
                            </label>
                            <input
                                type="text"
                                className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Registry Project Id"
                                value={formData.registry_project_id ?? ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFormData({
                                        ...formData,
                                        registry_project_id: e.target.value,
                                    })
                                }}
                            />
                            <div className="h-5">
                                {formError?.registry_project_id && (
                                    <ErrorLabel error={formError.registry_project_id} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">SDGs</label>
                        <div>
                            <div className="flex flex-wrap mt-4 mb-2">
                                {(Object.keys(SDG) as Array<keyof typeof SDG>).map((key) => {
                                    return (
                                        <div className="w-1/4" key={key}>
                                            <CheckBoxWrapper
                                                key={key}
                                                checked={
                                                    formData.sdgs &&
                                                    formData.sdgs.includes(SDG[key])
                                                }
                                                label={
                                                    <div className="flex">
                                                        <SdgLogo sdg={SDG[key]} />
                                                        {SDG[key]}
                                                    </div>
                                                }
                                                onChange={(checked: boolean) => {
                                                    if (checked) {
                                                        setFormData({
                                                            ...formData,
                                                            sdgs: [
                                                                ...(formData.sdgs ?? []),
                                                                SDG[key],
                                                            ],
                                                        })
                                                    } else {
                                                        setFormData({
                                                            ...formData,
                                                            sdgs: formData.sdgs?.filter(
                                                                (sdg) => sdg !== SDG[key],
                                                            ),
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="h-5">
                                {formError?.sdgs && <ErrorLabel error={formError.sdgs} />}
                            </div>
                        </div>
                    </div>
                </div>
                <div>
                    <h3 className="bg-gray-300 text-left mb-5">Vintage</h3>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Vintage Registry Url
                            </label>
                            <input
                                type="text"
                                className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Vintage Registry Url"
                                value={formData.vintage_registry_url ?? ''}
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFormData({
                                        ...formData,
                                        vintage_registry_url: e.target.value,
                                    })
                                }}
                            />
                            <div className="h-5">
                                {formError?.vintage_registry_url && (
                                    <ErrorLabel error={formError.vintage_registry_url} />
                                )}
                            </div>
                        </div>
                        <div className="mb-6 text-left w-1/2">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Vintage
                            </label>
                            <select
                                value={formData.vintage ?? ''}
                                className="block h-[42px] p-2 my-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setFormData({
                                        ...formData,
                                        vintage: e.target.value,
                                    })
                                }}
                            >
                                <option>Choose</option>
                                {renderAvailableVintages()}
                            </select>
                            <div className="h-5">
                                {formError?.vintage && <ErrorLabel error={formError.vintage} />}
                            </div>
                        </div>
                    </div>
                </div>
                <h3 className="bg-gray-300 text-left mb-5">
                    Beneficiary Tenant and Tenant Customer
                </h3>
                <div className="mb-6">
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-[45%] mb-2 text-left">
                            <label className="mb-2 text-sm font-medium">Beneficiary Tenant</label>
                            <select
                                className="block p-2 mb-6 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setFormData({
                                        ...formData,
                                        beneficiary_tenant_id: e.target.value as string,
                                    })
                                }}
                                value={formData.beneficiary_tenant_id || ''}
                            >
                                <option>Choose</option>
                                {beneficiaryTenants.map((tenant) => (
                                    <option key={tenant.id} value={tenant.id}>
                                        {tenant.entity_name}
                                    </option>
                                ))}
                            </select>
                            {formError?.beneficiary_tenant_id && (
                                <ErrorLabel error={formError.beneficiary_tenant_id} />
                            )}
                        </div>
                        <div className="w-[45%] mb-2 text-left">
                            <label className="mb-2 text-sm font-medium">Tenant Customer</label>
                            <select
                                className="block p-2 mb-6 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setFormData({
                                        ...formData,
                                        tenant_customer_id: e.target.value,
                                    })
                                }}
                                value={formData.tenant_customer_id || ''}
                            >
                                <option value={''}>Choose</option>
                                {tenantCustomers.length > 0 ? (
                                    tenantCustomers.map((customer) => (
                                        <option key={customer.id} value={customer.id ?? null}>
                                            {customer.name}
                                        </option>
                                    ))
                                ) : (
                                    <></>
                                )}
                            </select>
                            {formError?.tenant_customer_id && (
                                <ErrorLabel error={formError.tenant_customer_id} />
                            )}
                        </div>
                    </div>
                    <div className="h-5 text-left">
                        {formError?.tenant_customer_id && (
                            <ErrorLabel error={formError?.tenant_customer_id} />
                        )}
                    </div>
                </div>
                <h3 className="pl-5 bg-gray-300 text-left mb-5">Invoice Details</h3>
                <div className="flex flex-row gap-4 justify-between">
                    <div className="w-1/2 mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Price Paid Per Credit
                        </label>
                        <div className="py-2 pl-3 items-center flex flex-row h-[38px] my-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500">
                            <p className="border-1.5 mr-1.5 border-gray-300 rounded text-sm font-normal">
                                $
                            </p>
                            <NumberInput
                                initialValue={formData.price_paid_per_credit ?? ''}
                                onChange={(value) => {
                                    setFormData({
                                        ...formData,
                                        price_paid_per_credit: value as string,
                                    })
                                }}
                                min={0}
                            />
                        </div>
                        <div className="h-5">
                            {formError?.price_paid_per_credit && (
                                <ErrorLabel error={formError?.price_paid_per_credit} />
                            )}
                        </div>
                    </div>
                    <div className="w-1/2 mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Expected Amount
                        </label>
                        <div
                            className="items-center h-[38px] block my-2 p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                            style={{
                                padding: '8px 0 8px 12px',
                            }}
                        >
                            <NumberInput
                                initialValue={formData.amount ?? ''}
                                scale={0}
                                placeholder="0"
                                onChange={(value: any) => {
                                    setFormData({
                                        ...formData,
                                        amount: value as string,
                                    })
                                }}
                                min={0}
                            />
                        </div>
                        {formError.amount && <ErrorLabel error={formError.amount} />}
                    </div>
                </div>
                <div className="flex flex-row gap-4 justify-between">
                    <div className="w-1/2 mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Invoice Status
                        </label>
                        <select
                            className="block p-2 my-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            value={formData.invoice_status ?? ''}
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                setFormData({
                                    ...formData,
                                    invoice_status: e.target.value
                                        ? (e.target.value as InvoiceStatus)
                                        : undefined,
                                })
                            }}
                        >
                            <option value={''}>Choose</option>
                            {(Object.keys(InvoiceStatus) as Array<keyof typeof InvoiceStatus>).map(
                                (key) => (
                                    <option key={key} value={InvoiceStatus[key]}>
                                        {InvoiceStatus[key]}
                                    </option>
                                ),
                            )}
                        </select>
                        <div className="h-5">
                            {formError?.invoice_status && (
                                <ErrorLabel error={formError?.invoice_status} />
                            )}
                        </div>
                    </div>
                    <div className="w-1/2 mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Invoice Reference
                        </label>
                        <input
                            type="text"
                            placeholder="Invoice Reference..."
                            value={formData.invoice_reference ?? ''}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                setFormData({
                                    ...formData,
                                    invoice_reference: e.target.value,
                                })
                            }}
                            className="h-[38px] block my-2 p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-5">
                            {formError?.invoice_reference && (
                                <ErrorLabel error={formError?.invoice_reference} />
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-between">
                    <div className="flex flex-row">
                        <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                            <button
                                type="button"
                                onClick={() => onSubmit('save')}
                                disabled={Object.keys(formData).length === 0}
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Save
                            </button>
                        </div>
                        <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                            <button
                                onClick={() => onSubmit('saveAndValidate')}
                                type="button"
                                className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                            >
                                Save and mark as ready
                            </button>
                        </div>
                    </div>
                    <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                        <button
                            onClick={() => navigate(Page.EXPECTED_BLOCKS)}
                            type="button"
                            className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                        >
                            Cancel Editing
                        </button>
                    </div>
                </div>
            </form>
        </Layout>
    )
}

export default EditExpectedBLock
