/* eslint-disable indent */
import { RetirementRequestApi } from '../api/retirementRequest'
import { Page } from '../enums/Page'
import { RetirementRequest } from '../types/RetirementRequest'
import { RetirementRequestStatuses } from '../types/RetirementRequestStatuses'
import ConfirmationModal from './ConfirmationModal'
import { CopyButton } from './CopyButton'
import { BigNumber } from 'ethers'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface RetirementEventDetailsProps {
    retirementRequest: RetirementRequest | null
    haveBlocksBeenRetired: boolean
}

const RetirementEventDetails = ({
    retirementRequest,
    haveBlocksBeenRetired,
}: RetirementEventDetailsProps): JSX.Element => {
    const navigate = useNavigate()
    const [showFailRetirementConfirmationModal, setShowFailRetirementConfirmationModal] =
        useState<boolean>(false)

    const handleFailRetirement = async (): Promise<void> => {
        if (retirementRequest?.async_item_id) {
            await RetirementRequestApi.failRetirement(retirementRequest?.async_item_id)
        }
        navigate(Page.ASYNC_ITEMS)
    }

    const isRetirementInProgress = ![
        RetirementRequestStatuses.COMPLETE,
        RetirementRequestStatuses.FAILED,
        RetirementRequestStatuses.RETURNED,
    ].includes(retirementRequest?.status || RetirementRequestStatuses.PENDING)

    return (
        <div className="">
            <table className="overflow-x-scroll md:overflow-auto block md:table md:table-auto w-full">
                <thead className="">
                    <tr className="h-11 items-center bg-[#3e3d3d] text-white divide-x-[1px] divide-x-stone-300">
                        <th className="font-semibold text-xs text-center px-4">id</th>
                        <th className="font-semibold text-xs text-center px-4">
                            Project Id On Registry
                        </th>
                        <th className="font-semibold text-xs text-center px-4">
                            On Chain Request ID
                        </th>
                        <th className="font-semibold text-xs text-center px-4">Async item ID</th>
                        <th className="font-semibold text-xs text-center px-4">Project/Vintage</th>
                        <th className="font-semibold text-xs text-center px-4">Amount</th>
                        <th className="font-semibold text-xs text-center px-4">
                            On Chain Proof NFT ID
                        </th>
                        <th className="font-semibold text-xs text-center px-4">Retiree Tenant</th>
                        <th className="font-semibold text-xs text-center px-4">Retiree Tax ID</th>
                        <th className="font-semibold text-xs text-center px-4">Status</th>
                        <th className="font-semibold text-xs text-center px-4">Retired At</th>
                        <th className="font-semibold text-xs text-center px-4">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="h-18 items-center">
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {retirementRequest?.id && (
                                <div className="flex gap-2 justify-center">
                                    <p>{retirementRequest?.id}</p>
                                    <CopyButton text={retirementRequest?.id} />
                                </div>
                            )}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {retirementRequest?.vintage.project.registry_project_id && (
                                <div className="flex gap-2 justify-center">
                                    <p>{retirementRequest?.vintage.project.registry_project_id}</p>
                                    <CopyButton
                                        text={
                                            retirementRequest?.vintage.project.registry_project_id
                                        }
                                    />
                                </div>
                            )}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {retirementRequest?.on_chain_request_id && (
                                <div className="flex gap-2 justify-center">
                                    <p>{retirementRequest?.on_chain_request_id}</p>
                                    <CopyButton text={retirementRequest.on_chain_request_id} />
                                </div>
                            )}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {retirementRequest?.async_item_id && (
                                <div className="flex gap-2 justify-center">
                                    <p>{retirementRequest?.async_item_id}</p>
                                    <CopyButton text={retirementRequest.async_item_id} />
                                </div>
                            )}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {retirementRequest?.vintage?.project?.name} -{' '}
                            {retirementRequest?.vintage?.vintage}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {retirementRequest?.quantity
                                ? BigNumber.from(retirementRequest?.quantity).toString()
                                : ''}
                        </td>
                        <td className="text-sm text-center font-normal align-top justify-center px-[2px]">
                            {retirementRequest?.proof_of_retirement_nft_id}
                        </td>
                        <td className="text-sm text-center font-normal align-top justify-center px-[2px]">
                            {retirementRequest?.tenant.entity_name}
                        </td>
                        <td className="text-sm text-center font-normal align-top justify-center px-[2px]">
                            {(retirementRequest?.tax_id_override ||
                                retirementRequest?.tenant.tax_id) && (
                                <div className="flex gap-2 justify-center">
                                    <p>
                                        {retirementRequest?.tax_id_override ??
                                            retirementRequest?.tenant.tax_id}
                                    </p>
                                    <CopyButton
                                        text={
                                            retirementRequest?.tax_id_override ??
                                            (retirementRequest?.tenant.tax_id || '')
                                        }
                                    />
                                </div>
                            )}
                        </td>
                        <td className="text-sm text-center font-normal align-top justify-center px-[2px]">
                            {retirementRequest?.status}
                        </td>
                        <td className="text-sm text-center font-normal align-top justify-center px-[6px]">
                            <div className="flex px-1 items-center justify-between">
                                <div
                                    className={`${
                                        retirementRequest?.retired_at ? 'w-[80%] pr-2' : 'w-[100%]'
                                    }`}
                                >
                                    <p>{retirementRequest?.retired_at ?? '-'}</p>
                                </div>
                                {retirementRequest?.retired_at && (
                                    <div className="w-[20%]">
                                        <CopyButton text={retirementRequest?.retired_at} />
                                    </div>
                                )}
                            </div>
                        </td>
                        <td className="text-sm text-center font-normal align-top justify-center px-[2px]">
                            {!haveBlocksBeenRetired && isRetirementInProgress && (
                                <button
                                    className="px-4 py-2.5 rounded-lg text-white bg-[#3e3d3d]"
                                    onClick={() => setShowFailRetirementConfirmationModal(true)}
                                    disabled={showFailRetirementConfirmationModal}
                                >
                                    Fail Retirement
                                </button>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <ConfirmationModal
                show={showFailRetirementConfirmationModal}
                onClose={() => setShowFailRetirementConfirmationModal(false)}
                onConfirm={handleFailRetirement}
                confirmButtonText="OK"
                cancelButtonText="Cancel"
            />
        </div>
    )
}

export default RetirementEventDetails
