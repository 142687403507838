import ApiURL from './ApiURL'
import Header from './Header'

interface LayoutProps {
    className?: string
    children: JSX.Element | JSX.Element[]
}
const Layout = ({ className = '', children }: LayoutProps): JSX.Element => {
    return (
        <div className={className}>
            <Header />
            <ApiURL />
            <div className="pt-4 w-11/12 mx-auto">{children}</div>
        </div>
    )
}

export default Layout
