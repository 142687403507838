import { TenantApi } from '../../api/tenant'
import { WebhookApi } from '../../api/webhook'
import { Page } from '../../enums/Page'
import { TenantType } from '../../enums/TenantType'
import { WebhookEventName } from '../../enums/WebhookEventName'
import { ErrorResponse } from '../../types/ErrorResponse'
import { Tenant } from '../../types/Tenant'
import ErrorLabel from '../ErrorLabel'
import { AxiosError } from 'axios'
import { ToggleSwitch } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'

interface FormData {
    tenant_id: string
    event_name: WebhookEventName
    url: string
    passphrase: string
    is_active: boolean
}

const CreateWebhookForm = (): JSX.Element => {
    const [error, setError] = useState<string | null>(null)
    const [tenants, setTenants] = useState<Tenant[]>([])
    const [formData, setFormData] = useState<FormData>({
        tenant_id: '',
        event_name: WebhookEventName.NEW_CREDITS_BRIDGED,
        url: '',
        passphrase: '',
        is_active: true,
    })
    const navigate = useNavigate()

    const fetchTenants = async () => {
        const response = await TenantApi.search({
            type: TenantType.ENTITY,
        })
        setTenants(response.data)
    }

    useEffect(() => {
        fetchTenants()
    }, [])

    const loadOptions = (inputValue: any) => {
        return TenantApi.search({
            entity_name: inputValue,
        }).then((response) => {
            setTenants(response.data)
            return response.data.map((tenant: Tenant) => {
                return {
                    value: tenant.id,
                    label: tenant.entity_name,
                }
            })
        })
    }

    const mapEventNameToLabel = (eventName: string): string => {
        switch (eventName) {
            case WebhookEventName.NEW_CREDITS_BRIDGED:
                return 'New Credits Bridged'
            case WebhookEventName.RETIREMENT_FINISHED:
                return 'Retirement Finished'
            case WebhookEventName.UNBRIDGE_FINISHED:
                return 'Unbridge Finished'
            default:
                return eventName
        }
    }

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        try {
            await WebhookApi.save({
                tenant_id: formData.tenant_id,
                event: formData.event_name,
                url: formData.url,
                passphrase: formData.passphrase,
                is_active: formData.is_active,
            })
            navigate(Page.WEBHOOKS)
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            } else {
                setError('Something went wrong')
            }
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <div className="flex items-center">
                    <button
                        className="px-2 h-12 flex justify-center items-center border border-black rounded-lg "
                        onClick={() => navigate(Page.WEBHOOKS)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            width="20"
                            height="20"
                        >
                            <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                        </svg>
                    </button>
                    <h1 className="w-full text-center">Create Webhook</h1>
                </div>
                <div className="flex flex-col mt-8">
                    <label className="mb-2 text-sm font-medium text-gray-900 text-left">
                        Select Tenant
                    </label>
                    <AsyncSelect
                        loadOptions={loadOptions}
                        defaultOptions={tenants.map((tenant) => {
                            return {
                                value: tenant.id,
                                label: `${tenant.entity_name}`,
                            }
                        })}
                        isLoading={false}
                        isSearchable={true}
                        onChange={(value: SingleValue<{ value: string; label: string }>) => {
                            const tenant = tenants.find((tenant) => tenant.id === value?.value)
                            if (tenant) {
                                setFormData({
                                    ...formData,
                                    tenant_id: tenant.id,
                                })
                            }
                        }}
                        styles={{
                            valueContainer: (provided) => ({
                                ...provided,
                                textAlign: 'left',
                            }),
                            option: (provided) => ({
                                ...provided,
                                textAlign: 'left',
                            }),
                        }}
                    />
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm mb-2 text-left font-semibold text-gray-700">
                        Event
                    </label>
                    <select
                        id="small"
                        className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        value={formData.event_name}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                event_name: e.target.value as WebhookEventName,
                            })
                        }}
                    >
                        {Object.keys(WebhookEventName).map((key: string) => {
                            return (
                                <option key={key} value={key}>
                                    {mapEventNameToLabel(key)}
                                </option>
                            )
                        })}
                    </select>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm mb-2 text-left font-semibold text-gray-700">
                        Url
                    </label>
                    <input
                        value={formData.url}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                url: e.target.value,
                            })
                        }}
                        placeholder="Url"
                        required
                        className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm mb-2 text-left font-semibold text-gray-700">
                        Passphrase
                    </label>
                    <input
                        value={formData.passphrase}
                        onChange={(e) => {
                            setFormData({
                                ...formData,
                                passphrase: e.target.value,
                            })
                        }}
                        placeholder="Passphrase"
                        required
                        className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                </div>
                <div className="flex flex-col mt-6">
                    <ToggleSwitch
                        label="Is Active"
                        checked={formData.is_active}
                        onChange={() => {
                            setFormData({
                                ...formData,
                                is_active: !formData.is_active,
                            })
                        }}
                    />
                </div>
                {error && <ErrorLabel error={error} />}
                <div className="flex items-center pt-6 mt-20 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                        data-modal-toggle="defaultModal"
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreateWebhookForm
