import { BridgeCreditsApi } from '../../api/bridgeCreditsApi'
import { ExpectedBlockApi } from '../../api/expectedBlock'
import { ProjectApi } from '../../api/project'
import { RegistryApi } from '../../api/registry'
import { TenantApi } from '../../api/tenant'
import { TenantCustomerApi } from '../../api/tenantCustomer'
import { Page } from '../../enums/Page'
import { ProjectLocation } from '../../enums/ProjectLocation'
import { SDG } from '../../enums/SDG'
import { ErrorResponse } from '../../types/ErrorResponse'
import { ExpectedBlockData } from '../../types/ExpectedBlock'
import { ProjectType } from '../../types/ProjectType'
import { Registry } from '../../types/Registry'
import { Tenant } from '../../types/Tenant'
import { TenantCustomer } from '../../types/TenantCustomer'
import { generateUniqueId } from '../../utils/generateUniqueId'
import { getCountryNameFromCountryCode } from '../../utils/getCountryNameFromCountryCode'
import CheckBoxWrapper from '../CheckBoxWrapper'
import ErrorLabel from '../ErrorLabel'
import SdgLogo from '../SdgLogo'
import SelectedExistingProjectAndVintage, {
    SelectExistingProjectError,
} from '../SelectedExistingProjectAndVintage'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate, useSearchParams } from 'react-router-dom'

export interface CreateProjectForm {
    project_name: string
    description: string
    image_url: string
    type: string
    location: ProjectLocation | 'Choose'
    registry_id: string
    registry_project_url: string
    registry_project_id: string
    sdgs: SDG[]
    vintage: string
    vintage_registry_url: string
    block_serial_number: string
    amount: string
    beneficiary_tenant_id: string
    tenant_customer_id: string | null
    tenant_customers: TenantCustomer[]
}

const CreateProjectForm = () => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        watch,
    } = useForm<CreateProjectForm>({})

    const [selectedSDGs, setSelectedSDGs] = useState<SDG[]>([])
    const [registries, setRegistries] = useState<Registry[]>([])
    const [selectedRegistryId, setSelectedRegistryId] = useState<string | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [tenants, setTenants] = useState<Tenant[]>([])
    const [projectTypes, setProjectTypes] = useState<ProjectType[]>([])
    const [externalRequestId] = useState<string>(generateUniqueId())
    const [selectedButton, setSelectedButton] = useState<'create' | 'select'>('create')
    const [selectExistingProjectError, setSelectExistingProjectError] =
        useState<SelectExistingProjectError | null>(null)
    const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null)
    const [selectedVintage, setSelectedVintage] = useState<string | null>(null)
    const beneficiaryTenant = watch('beneficiary_tenant_id') ?? ''
    const tenantCustomers = watch('tenant_customers') ?? []
    const [expectedBlockData, setExpectedBlockData] = useState<ExpectedBlockData>()
    const navigate = useNavigate()
    const [searchParam] = useSearchParams()
    const expectedBlockId = searchParam.get('expected_block')

    const fetchExpectedBlock = async (expectedBlockId: string) => {
        if (expectedBlockId) {
            const expectedBlockRes = await ExpectedBlockApi.getByIdForBridging(expectedBlockId)
            setSelectedRegistryId(expectedBlockRes.data.expected_block.registry_id)
            setExpectedBlockData(expectedBlockRes.data)
        }
    }

    const fetchTenantCustomers = async () => {
        if (beneficiaryTenant) {
            const response = await TenantCustomerApi.getTenantCustomersByTenantId(beneficiaryTenant)
            setValue('tenant_customers', response.data.tenant_customers)
        }
    }

    const fetchProjectForExpectedBlock = async (
        registry_id: string,
        registry_project_id: string,
    ) => {
        const searchProjectsResponse = await ProjectApi.searchProjects({
            registry_id,
            registry_project_id,
        })
        const currentProject = searchProjectsResponse.data.results[0]
        if (!currentProject) {
            return
        }
        setSelectedButton('select')
        setSelectedRegistryId(currentProject.registry_id)
        setSelectedProject(currentProject)
    }

    const fillDataFromExpectedBlock = async () => {
        const predefinedData = {
            amount: expectedBlockData?.expected_block.expected_amount ?? '',
            beneficiary_tenant_id: expectedBlockData?.expected_block.beneficiary_tenant_id ?? '',
            tenant_customer_id: expectedBlockData?.expected_block.tenant_customer_id ?? null,
            project_name:
                expectedBlockData?.project_exists && selectedProject?.name
                    ? selectedProject.name
                    : expectedBlockData?.expected_block.project_name,
            description:
                expectedBlockData?.project_exists && selectedProject?.name
                    ? selectedProject.description
                    : expectedBlockData?.expected_block.project_description,
            image_url:
                expectedBlockData?.project_exists && selectedProject?.image_url
                    ? selectedProject?.image_url
                    : '',
            location: expectedBlockData?.expected_block.location
                ? expectedBlockData?.expected_block.location
                : undefined,
            registry_id:
                expectedBlockData?.project_exists && selectedProject?.registry_id
                    ? selectedProject?.registry_id
                    : expectedBlockData?.expected_block.registry_id ?? undefined,
            registry_project_id:
                expectedBlockData?.project_exists && selectedProject?.registry_project_id
                    ? selectedProject?.registry_project_id
                    : expectedBlockData?.expected_block.registry_project_id ?? '',
            registry_url: expectedBlockData?.expected_block.registry_url
                ? expectedBlockData?.expected_block.registry_url
                : '',
            vintage:
                (expectedBlockData?.vintage_exists &&
                    selectedProject?.vintages &&
                    selectedProject?.vintages
                        .filter((v) => v.vintage === expectedBlockData.expected_block.vintage)[0]
                        .vintage.toString()) ||
                expectedBlockData?.expected_block.vintage.toString(),
            vintage_registry_url:
                (expectedBlockData?.vintage_exists &&
                    selectedProject?.vintages.filter(
                        (v) =>
                            v.registry_url ===
                            expectedBlockData.expected_block.vintage_registry_url,
                    )[0]?.registry_url) ||
                expectedBlockData?.expected_block?.vintage_registry_url,
            sdgs: expectedBlockData?.expected_block.sdgs ?? [],
        }

        if (selectedButton === 'create') {
            setValue('project_name', predefinedData.project_name ?? '')
            setValue('description', predefinedData.description ?? '')
            setValue('image_url', predefinedData.image_url ?? '')
            setValue('location', (predefinedData.location as ProjectLocation) ?? '')
            setValue('registry_id', predefinedData.registry_id ?? 'Choose')
            setValue('registry_project_id', predefinedData.registry_project_id ?? '')
            setValue('registry_project_url', predefinedData.registry_url ?? '')
            setValue('vintage', predefinedData.vintage ?? '')
            setValue('vintage_registry_url', predefinedData.vintage_registry_url ?? '')
            setSelectedSDGs(predefinedData.sdgs ?? [])
        }
        if (predefinedData.beneficiary_tenant_id) {
            const tenantCustRes = await TenantCustomerApi.getTenantCustomersByTenantId(
                predefinedData.beneficiary_tenant_id,
            )
            setValue('tenant_customers', tenantCustRes.data.tenant_customers)
            setValue('beneficiary_tenant_id', predefinedData.beneficiary_tenant_id)
        }
        if (predefinedData.amount) {
            setValue('amount', predefinedData.amount)
        }
    }

    const fetchRegistries = async () => {
        const response = await RegistryApi.getAll()
        setRegistries(response.data)
    }

    const fetchDevelopers = async () => {
        const response = await TenantApi.getDevelopers()
        setTenants(response.data)
    }

    const fetchProjectTypes = async () => {
        if (selectedRegistryId) {
            const response = await ProjectApi.getProjectTypesByRegistryId(selectedRegistryId)
            setProjectTypes(response.data)
        }
    }

    const renderAvailableVintages = (): JSX.Element[] => {
        const vintages = []
        for (let i = 2010; i <= new Date().getFullYear() + 1; i++) {
            vintages.push(String(i))
        }
        return vintages.map((vintage: string) => {
            return (
                <option key={vintage} value={vintage}>
                    {vintage}
                </option>
            )
        })
    }

    useEffect(() => {
        if (expectedBlockId) {
            void fetchExpectedBlock(expectedBlockId)
        }
    }, [expectedBlockId])

    useEffect(() => {
        if (expectedBlockData?.expected_block && expectedBlockData?.project_exists) {
            void fetchProjectForExpectedBlock(
                expectedBlockData.expected_block.registry_id,
                expectedBlockData.expected_block.registry_project_id,
            )
        }
    }, [expectedBlockData])

    useEffect(() => {
        if (expectedBlockData) {
            fillDataFromExpectedBlock()
        }
    }, [expectedBlockData, selectedProject, selectedButton])

    useEffect(() => {
        if (projectTypes.length > 0 && expectedBlockData) {
            const currentProjectType = projectTypes.find(
                (i) => i.project_type === expectedBlockData.expected_block.project_type,
            )
            setValue('type', currentProjectType?.project_type ?? 'Choose')
        }
    }, [projectTypes])

    useEffect(() => {
        void fetchProjectTypes()
    }, [selectedRegistryId])

    useEffect(() => {
        void fetchRegistries()
        void fetchDevelopers()
    }, [])

    useEffect(() => {
        void fetchTenantCustomers()
    }, [beneficiaryTenant])

    useEffect(() => {
        if (expectedBlockData && tenantCustomers.length > 0) {
            setValue('tenant_customer_id', expectedBlockData.expected_block.tenant_customer_id)
        } else if (tenantCustomers.length > 0) {
            setValue('tenant_customer_id', tenantCustomers[0].id)
        }
    }, [tenantCustomers])

    const onSubmit = async (data: CreateProjectForm): Promise<void> => {
        try {
            if (selectedButton === 'create') {
                await BridgeCreditsApi.save({
                    project: {
                        name: data.project_name.trim(),
                        type: data.type,
                        location: data.location,
                        description: data.description.trim(),
                        image_url: data.image_url,
                        registry_project_id: data.registry_project_id,
                        registry_project_url: !!data.registry_project_url
                            ? data.registry_project_url.trim()
                            : null,
                        sdgs: selectedSDGs,
                    },
                    vintage_year: data.vintage,
                    registry_vintage_url: !!data.vintage_registry_url.trim()
                        ? data.vintage_registry_url.trim()
                        : null,
                    credit_sender_thallo_company_id: data.beneficiary_tenant_id,
                    quantity: data.amount.trim(),
                    block_serial_number: data.block_serial_number.trim(),
                    external_request_id: externalRequestId,
                    registry_id: data.registry_id,
                    ...(expectedBlockData?.expected_block && {
                        expected_block_id: expectedBlockData.expected_block.id,
                    }),
                    tenant_customer_id: data.tenant_customer_id || null,
                })
            } else {
                if (!selectedProject) {
                    setSelectExistingProjectError(SelectExistingProjectError.NO_PROJECT_SELECTED)
                    return
                } else if (!selectedVintage) {
                    setSelectExistingProjectError(SelectExistingProjectError.NO_VINTAGE_SELECTED)
                    return
                } else {
                    await BridgeCreditsApi.saveForExisingProjectAndVintageId({
                        project_id: selectedProject.id,
                        vintage: selectedVintage,
                        credit_sender_thallo_company_id: data.beneficiary_tenant_id,
                        quantity: data.amount.trim(),
                        block_serial_number: data.block_serial_number.trim(),
                        external_request_id: externalRequestId,
                        registry_vintage_url: !!data.vintage_registry_url
                            ? data.vintage_registry_url.trim()
                            : null,
                        ...(expectedBlockData?.expected_block && {
                            expected_block_id: expectedBlockData.expected_block.id,
                        }),
                        tenant_customer_id: data.tenant_customer_id || null,
                    })
                }
            }
            setError(null)
            navigate(Page.ASYNC_ITEMS)
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            }
        }
    }

    const getExistingVintage = (vintageYear: number) => {
        if (selectedProject) {
            return selectedProject.vintages.find((v) => v.vintage === vintageYear)
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div>
                <div className="flex gap-5 justify-center my-2">
                    <button
                        type="button"
                        className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                            selectedButton === 'create' ? 'bg-primary-600' : 'bg-primary-100'
                        }`}
                        onClick={() => setSelectedButton('create')}
                    >
                        Create Project
                    </button>
                    <button
                        type="button"
                        className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                            selectedButton === 'select' ? 'bg-primary-600' : 'bg-primary-100'
                        }`}
                        onClick={() => setSelectedButton('select')}
                    >
                        Select Project
                    </button>
                </div>
            </div>

            {selectedButton === 'create' && (
                <div>
                    <h3 className="bg-gray-300 text-left mb-5">Project</h3>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Project Name
                        </label>
                        <input
                            placeholder="Name"
                            {...register('project_name', {
                                required: false,
                                onBlur: (e) => setValue('project_name', e.target.value.trim()),
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-5">
                            {errors?.project_name?.type === 'required' && (
                                <ErrorLabel error="Project Name is required" />
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Project image URL
                            </label>
                            <input
                                placeholder="Project image url"
                                className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                {...register('image_url', {
                                    required: false,
                                    onBlur: (e) => setValue('image_url', e.target.value.trim()),
                                })}
                            ></input>
                        </div>
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Registry
                            </label>
                            <select
                                className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                {...register('registry_id', {
                                    required: true,
                                    validate: (type: string) => {
                                        return type !== 'Choose'
                                    },
                                })}
                                onChange={(e) => {
                                    setSelectedRegistryId(e.target.value)
                                }}
                            >
                                <option>Choose</option>
                                {registries.map((registry: Registry) => (
                                    <option key={registry.id} value={registry.id}>
                                        {registry.name}
                                    </option>
                                ))}
                            </select>
                            {errors?.registry_id?.type === 'validate' && (
                                <ErrorLabel error="Registry is required" />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Project type
                            </label>
                            <select
                                className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                {...register('type', {
                                    required: true,
                                    validate: (type: string) => {
                                        return type !== 'Choose'
                                    },
                                })}
                            >
                                <option>Choose</option>
                                {projectTypes.map((projectType) => (
                                    <option key={projectType.id} value={projectType.project_type}>
                                        {projectType.project_type}
                                    </option>
                                ))}
                            </select>
                            {errors?.type?.type === 'validate' && (
                                <ErrorLabel error="Type is required" />
                            )}
                        </div>
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium">Location</label>
                            <select
                                className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                {...register('location', {
                                    required: true,
                                    validate: (type: ProjectLocation | 'Choose') => {
                                        return type !== 'Choose'
                                    },
                                })}
                            >
                                <option>Choose</option>
                                {(
                                    Object.keys(ProjectLocation) as Array<
                                        keyof typeof ProjectLocation
                                    >
                                ).map((key) => (
                                    <option key={key} value={ProjectLocation[key]}>
                                        {getCountryNameFromCountryCode(ProjectLocation[key])}
                                    </option>
                                ))}
                            </select>
                            {errors?.location?.type === 'validate' && (
                                <ErrorLabel error="Location is required" />
                            )}
                        </div>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Description
                        </label>
                        <textarea
                            rows={5}
                            id="large-input"
                            placeholder="Desription"
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                            {...register('description', {
                                required: false,
                                onBlur: (e) => setValue('description', e.target.value.trim()),
                            })}
                        />
                    </div>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Registry project url
                            </label>
                            <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Registry url"
                                {...register('registry_project_url', {
                                    required: false,
                                    onBlur: (e) =>
                                        setValue('registry_project_url', e.target.value.trim()),
                                })}
                            />
                            {errors?.registry_project_url?.type === 'required' && (
                                <ErrorLabel error="Registry URL is required" />
                            )}
                        </div>
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Registry project id
                            </label>
                            <input
                                type="text"
                                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Registry Project id"
                                {...register('registry_project_id', {
                                    required: true,
                                    onBlur: (e) =>
                                        setValue('registry_project_id', e.target.value.trim()),
                                })}
                            />
                            {errors?.registry_project_id?.type === 'required' && (
                                <ErrorLabel error="Registry Project id is required" />
                            )}
                        </div>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">SDGs</label>
                        <div>
                            <div className="flex flex-wrap mt-4">
                                {(Object.keys(SDG) as Array<keyof typeof SDG>).map((key) => {
                                    return (
                                        <div className="w-1/4" key={key}>
                                            <CheckBoxWrapper
                                                key={key}
                                                checked={selectedSDGs.includes(SDG[key])}
                                                label={
                                                    <div className="flex">
                                                        <SdgLogo sdg={SDG[key]} />
                                                        {SDG[key]}
                                                    </div>
                                                }
                                                onChange={(checked: boolean) => {
                                                    if (checked) {
                                                        setSelectedSDGs([...selectedSDGs, SDG[key]])
                                                    } else {
                                                        setSelectedSDGs(
                                                            selectedSDGs.filter(
                                                                (sdg) => sdg !== SDG[key],
                                                            ),
                                                        )
                                                    }
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {selectedButton === 'select' && (
                <SelectedExistingProjectAndVintage
                    selectExistingProjectError={selectExistingProjectError}
                    onProjectSelected={(project) => setSelectedProject(project)}
                    onSelectedVintage={(vintage) => {
                        setSelectedVintage(vintage)
                        setValue('vintage', vintage)
                        const existingVintage = getExistingVintage(Number(vintage))
                        if (existingVintage?.registry_url) {
                            setValue('vintage_registry_url', existingVintage.registry_url)
                        } else {
                            setValue('vintage_registry_url', '')
                        }
                    }}
                    onChangeVintageRegistryUrl={(vintageRegistryUrl) => {
                        setValue('vintage_registry_url', vintageRegistryUrl)
                    }}
                    expectedBlock={expectedBlockData?.expected_block ?? undefined}
                />
            )}
            {selectedButton === 'create' && (
                <div>
                    <h3 className="bg-gray-300 text-left mb-5">Vintage</h3>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="mb-6 text-left w-1/2">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Vintage Registry url
                            </label>
                            <input
                                placeholder="Vintage registry url"
                                className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                {...register('vintage_registry_url', {
                                    required: false,
                                    onBlur: (e) =>
                                        setValue('vintage_registry_url', e.target.value.trim()),
                                })}
                            ></input>
                        </div>
                        <div className="mb-6 text-left w-1/2">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Vintage
                            </label>
                            <select
                                className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                {...register('vintage', {
                                    required: true,
                                    validate: (type: string) => {
                                        return type !== 'Choose'
                                    },
                                })}
                            >
                                <option>Choose</option>
                                {renderAvailableVintages()}
                            </select>
                            <div className="h-4">
                                {errors?.vintage?.type === 'validate' && (
                                    <ErrorLabel error="Vintage is required" />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Block Serial Number
                    </label>
                    <input
                        type="text"
                        placeholder="Block Serial Number..."
                        {...register('block_serial_number', {
                            required: true,
                            onBlur: (e) => setValue('block_serial_number', e.target.value.trim()),
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                    />
                    {errors?.block_serial_number?.type === 'required' && (
                        <ErrorLabel error="Block Serial Number is required" />
                    )}
                </div>
                <div className="w-1/2 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">Amount</label>
                    <input
                        type="text"
                        placeholder="Amount..."
                        {...register('amount', {
                            required: true,
                            onBlur: (e) => setValue('amount', e.target.value.trim()),
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                    />
                    {errors?.amount?.type === 'required' && (
                        <ErrorLabel error="Amount is required" />
                    )}
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Beneficiary Tenant
                    </label>
                    <select
                        className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        {...register('beneficiary_tenant_id', {
                            required: true,
                            validate: (tenantId: string) => {
                                return tenantId !== 'Beneficiary Tenant'
                            },
                        })}
                    >
                        <option>Beneficiary Tenant</option>
                        {tenants.map((tenant) => (
                            <option key={tenant.id} value={tenant.id}>
                                {tenant.id} - {tenant.entity_name}
                            </option>
                        ))}
                    </select>
                    {errors?.beneficiary_tenant_id?.type === 'validate' && (
                        <ErrorLabel error="Beneficiary Tenant is required" />
                    )}
                </div>
                <div className="w-1/2 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium">Tenant Customer</label>
                    <select
                        className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        {...register('tenant_customer_id', {
                            required: tenantCustomers.length > 0 ? true : false,
                        })}
                    >
                        {tenantCustomers.length > 0 ? (
                            tenantCustomers.map((customer) => (
                                <option key={customer.id} value={customer.id}>
                                    {customer.id} - {customer.name}
                                </option>
                            ))
                        ) : (
                            <></>
                        )}
                    </select>
                    {errors?.tenant_customer_id?.type === 'required' && (
                        <ErrorLabel error="Tenant customer is required if beneficiary has listed customers" />
                    )}
                </div>
                <div className="mb-6 text-left w-1/2">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        External ID (read only)
                    </label>
                    <input
                        disabled={true}
                        value={externalRequestId}
                        className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    ></input>
                </div>
            </div>
            {error && <ErrorLabel error={error} />}
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                <button
                    data-modal-toggle="defaultModal"
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save
                </button>
            </div>
        </form>
    )
}

export default CreateProjectForm
