import Layout from '../components/Layout'
import UnbridgingEvent from '../components/UnbridgeRequest/UnbridgingEvent'

const Unbridging = (): JSX.Element => {
    return (
        <Layout>
            <UnbridgingEvent />
        </Layout>
    )
}

export default Unbridging
