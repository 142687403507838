import { UnbridgeRequestApi } from '../../api/unbridgeRequest'
import { Page } from '../../enums/Page'
import { CopyButton } from '../CopyButton'
import { BigNumber } from 'ethers'
import { useEffect, useRef, useState } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'

const UnbrigingRequestsTable = (): JSX.Element => {
    const [unbridginRequests, setUnbridgingRequests] = useState<any>([])
    const navigate = useNavigate()
    const intervalRef = useRef<any>(null)

    const fetchUnbridgingRequests = async () => {
        const response = await UnbridgeRequestApi.getAll()
        setUnbridgingRequests(response.data)
    }

    useEffect(() => {
        intervalRef.current = setTimeout(() => {
            void fetchUnbridgingRequests()
        }, 0)
        return () => clearTimeout(intervalRef.current)
    }, [])
    // id, tenant name, project name, vintage, registry, quantity, external id, status, actions
    return (
        <table className="overflow-x-scroll md:overflow-auto block md:table md:table-auto w-full">
            <thead className="w-full">
                <tr className="h-11 items-center w-full bg-gray-50">
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">ID</th>
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                        TENANT NAME
                    </th>
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                        PROJECT NAME
                    </th>
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">VINTAGE</th>
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">REGISTRY</th>
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">QUANTITY</th>
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                        EXTERNAL ID
                    </th>
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                        DESTINATION REGISTRY CUSTOMER ID
                    </th>
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">STATUS</th>
                    <th className="text-gray-500 font-semibold text-xs text-left pl-6">ACTIONS</th>
                </tr>
            </thead>
            <tbody>
                {unbridginRequests.map((request: any, index: number) => {
                    return (
                        <tr className="h-18 items-center w-full" key={index}>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                {request.id}
                            </td>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                <div className="flex justify-between items-center">
                                    <span>{request?.tenant?.entity_name}</span>
                                    <CopyButton text={request?.tenant?.entity_name} />
                                </div>
                            </td>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                {request?.vintage?.project?.name}
                            </td>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                {request?.vintage?.vintage}
                            </td>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                {request?.vintage?.project?.registry?.name}
                            </td>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                {BigNumber.from(request?.quantity).toString()}
                            </td>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                {request?.external_id}
                            </td>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                {request?.destination_registry_customer_id}
                            </td>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                {request?.status}
                            </td>
                            <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                {request.async_item_id && (
                                    <button
                                        className="bg-[#3e3d3d] p-2.5 rounded-md text-white"
                                        onClick={() =>
                                            navigate(
                                                generatePath(Page.UNBRIDGING_EVENT, {
                                                    id: request.async_item_id,
                                                }),
                                            )
                                        }
                                    >
                                        View Unbridge
                                    </button>
                                )}
                            </td>
                        </tr>
                    )
                })}
            </tbody>
        </table>
    )
}

export default UnbrigingRequestsTable
