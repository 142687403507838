export enum UnbridgeCreditsSubStatuses {
    IN_PROGRESS_REQUEST_UNBRIDGE_BC = 'IN_PROGRESS_REQUEST_UNBRIDGE_BC',

    PENDING_UNBRIDGE_ON_REGISTRY = 'PENDING_UNBRIDGE_ON_REGISTRY',
    IN_PROGRESS_UNBRIDGE_ON_REGISTRY = 'IN_PROGRESS_UNBRIDGE_ON_REGISTRY',

    PENDING_UNBRIDGE_BLOCKS_DB = 'PENDING_UNBRIDGE_BLOCKS_DB',
    IN_PROGRESS_UNBRIDGE_BLOCKS_DB = 'IN_PROGRESS_UNBRIDGE_BLOCKS_DB',

    PENDING_RETURN_UNBRIDGE_BC = 'PENDING_RETURN_UNBRIDGE_BC',
    IN_PROGRESS_RETURN_UNBRIDGE_BC = 'IN_PROGRESS_RETURN_UNBRIDGE_BC',

    PENDING_FINALISE_UNBRIDGE_BC = 'PENDING_FINALISE_UNBRIDGE_BC',
    IN_PROGRESS_FINALISE_UNBRIDGE_BC = 'IN_PROGRESS_FINALISE_UNBRIDGE_BC',

    UNBRIDGE_COMPLETE = 'UNBRIDGE_COMPLETE',
    UNBRIDGE_RETURNED = 'UNBRIDGE_RETURNED',
}
