import { TenantApi } from '../../api/tenant'
import { TenantCustomerApi } from '../../api/tenantCustomer'
import { FileType } from '../../enums/FileType'
import { Page } from '../../enums/Page'
import { ErrorResponse } from '../../types/ErrorResponse'
import { Tenant } from '../../types/Tenant'
import { TenantCustomer } from '../../types/TenantCustomer'
import ErrorLabel from '../ErrorLabel'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

export interface EditCustomerForm {
    id: string
    tenant_id: string
    external_customer_id: string
    name: string
    created_at: Date | string
    updated_at: Date | string
    nft_branding_enabled: boolean
    branding_colour: string | null
    branding_text_colour: string
    branding_file: string | null
    branding_file_extension: FileType | null
}

const EditCustomerForm = (): JSX.Element => {
    const { id: tenantCustomerId } = useParams<{ id: string }>()
    const [tenantCustomer, setTenantCustomer] = useState<TenantCustomer | null>(null)
    const [tenant, setTenant] = useState<Tenant | null>(null)
    const [error, setError] = useState<string | null>(null)
    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<EditCustomerForm>({})

    const fetchTenantCustomer = async () => {
        const response = await TenantCustomerApi.getById(tenantCustomerId ?? '')
        setTenantCustomer(response.data)
        setValue('id', response.data.id)
        setValue('tenant_id', response.data.tenant_id)
        setValue('external_customer_id', response.data.external_customer_id)
        setValue('name', response.data.name ?? '')
        setValue('created_at', response.data.created_at)
        setValue('updated_at', response.data.updated_at)
        setValue('nft_branding_enabled', response.data.nft_branding_enabled)
        setValue('branding_colour', response.data.branding_colour)
        setValue('branding_text_colour', response.data.branding_text_colour)
        setValue('branding_file', response.data.branding_file)
        setValue('branding_file_extension', response.data.branding_file_extension)
    }

    const fetchTenant = async () => {
        if (tenantCustomer) {
            const res = await TenantApi.getTenantById(tenantCustomer.tenant_id)
            setTenant(res.data)
        }
    }

    useEffect(() => {
        void fetchTenantCustomer()
    }, [tenantCustomerId])

    useEffect(() => {
        void fetchTenant()
    }, [tenantCustomer])

    const onSubmit = async (data: EditCustomerForm) => {
        try {
            await TenantCustomerApi.update(
                {
                    tenant_id: data.tenant_id,
                    external_customer_id: data.external_customer_id,
                    name: data.name,
                    nft_branding_enabled: data.nft_branding_enabled,
                    branding_colour: data.branding_colour || null,
                    branding_text_colour: data.branding_text_colour,
                    branding_file: data.branding_file || null,
                    branding_file_extension: data.branding_file_extension || null,
                },
                data.id,
            )
            navigate(
                generatePath(Page.MANAGE_TENANT_CUSTOMERS, {
                    id: tenant?.id ?? '',
                }),
            )
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            }
        }
    }

    return (
        <div>
            <h1>
                Editing Tenant Customer for {tenant?.entity_name} ({tenant?.id})
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <h3 className="bg-gray-300 text-left mb-5">Tenant Customer</h3>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Tenant Customer Id (read-only)
                        </label>
                        <input
                            placeholder="Tenant Customer Id"
                            {...register('id', {
                                required: true,
                            })}
                            readOnly={true}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-5">
                            {errors?.id?.type === 'required' && (
                                <ErrorLabel error="Tenant Customer id is required" />
                            )}
                        </div>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Tenant Id (read-only)
                        </label>
                        <input
                            placeholder="Tenant Id"
                            {...register('tenant_id', {
                                required: true,
                            })}
                            readOnly={true}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-5">
                            {errors?.tenant_id?.type === 'required' && (
                                <ErrorLabel error="Tenant id is required" />
                            )}
                        </div>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            External Customer Id
                        </label>
                        <input
                            placeholder="External Customer Id"
                            {...register('external_customer_id', {
                                required: true,
                                onBlur: (e) => {
                                    setValue('external_customer_id', e.target.value.trim())
                                },
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-5">
                            {errors?.external_customer_id?.type === 'required' && (
                                <ErrorLabel error="External customer id is required" />
                            )}
                        </div>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">Name</label>
                        <input
                            placeholder="Customer Name"
                            {...register('name', {
                                required: false,
                                onBlur: (e) => {
                                    setValue('name', e.target.value.trim())
                                },
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            NFT Branding Enabled
                        </label>
                        <input
                            placeholder="NFT Branding Enabled"
                            {...register('nft_branding_enabled', {
                                required: false,
                            })}
                            type="checkbox"
                            className="block p-4 text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Branding Colour (Hex code)
                        </label>
                        <input
                            placeholder="Branding Colour"
                            {...register('branding_colour', {
                                required: false,
                                onBlur: (e) => {
                                    setValue('branding_colour', e.target.value.trim() || null)
                                },
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Branding Text Colour (Hex code)
                        </label>
                        <input
                            placeholder="Branding Text Colour"
                            {...register('branding_text_colour', {
                                required: false,
                                onBlur: (e) => {
                                    setValue('branding_text_colour', e.target.value.trim() || null)
                                },
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Branding Logo File Name
                        </label>
                        <input
                            placeholder="Branding File"
                            {...register('branding_file', {
                                required: false,
                                onBlur: (e) => {
                                    setValue('branding_file', e.target.value.trim() || null)
                                },
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Branding File Extension
                        </label>
                        <select
                            {...register('branding_file_extension', {
                                required: false,
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        >
                            <option value={''}></option>
                            {Object.values(FileType).map((fileType) => (
                                <option value={fileType} key={fileType}>
                                    {fileType}
                                </option>
                            ))}
                        </select>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Created At (read-only)
                        </label>
                        <input
                            placeholder="Created At"
                            {...register('created_at', {
                                required: false,
                            })}
                            readOnly={true}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Last Updated At (read-only)
                        </label>
                        <input
                            placeholder="Updated At"
                            {...register('updated_at', {
                                required: false,
                            })}
                            readOnly={true}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                    </div>
                </div>
                {error && <ErrorLabel error={error} />}
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                        data-modal-toggle="defaultModal"
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Update Tenant Customer
                    </button>
                </div>
            </form>
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                <button
                    data-modal-toggle="defaultModal"
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    onClick={() => {
                        navigate(
                            generatePath(Page.ADD_TENANT_CUSTOMER_REGISTRY_ID, {
                                id: tenantCustomerId ?? '',
                            }),
                        )
                    }}
                >
                    Add Tenant Customer Registry Id
                </button>
            </div>
        </div>
    )
}

export default EditCustomerForm
