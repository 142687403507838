import { ExpectedBlockApi } from '../api/expectedBlock'
import { UserApi } from '../api/user'
import ConfirmationModal from '../components/ConfirmationModal'
import { CopyButton } from '../components/CopyButton'
import { EditButton } from '../components/EditButton'
import Layout from '../components/Layout'
import { ExpectedBlockStatus } from '../enums/ExpectedBLockStatus'
import { InvoiceStatus } from '../enums/InvoiceStatus'
import { Page } from '../enums/Page'
import { RoleName } from '../enums/RoleName'
import { ErrorResponse } from '../types/ErrorResponse'
import { ExpectedBlock } from '../types/ExpectedBlock'
import { SearchExpectedBlocksResponse } from '../types/SearchExpectedBlocksResponse'
import { User } from '../types/User'
import { convertCentsToLocaleUsd } from '../utils/convertCentsToLocaleUsd'
import { AxiosError } from 'axios'
import { BigNumber } from 'ethers'
import { Pagination, Spinner } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

interface ExpectedBlockFilters {
    page: number | null
}

const defaultFilters: ExpectedBlockFilters = {
    page: null,
}

const ExpectedBlocks = (): JSX.Element => {
    const navigate = useNavigate()
    const [searchExpectedBlocksResponse, setSearchExpectedBlocksResponse] =
        useState<SearchExpectedBlocksResponse | null>(null)
    const [authenticatedUser, setAuthenticatedUser] = useState<User>()
    const [showSpinner, setShowSpinner] = useState<boolean>(true)
    const [filters, setFilters] = useState<ExpectedBlockFilters>(defaultFilters)
    const [searchParams, setSearchParams] = useSearchParams()
    const [
        showReadyForBridgingConfirmationModalForExpectedBlock,
        setShowReadyForBridgingConfirmationModalForExpectedBlock,
    ] = useState<ExpectedBlock | null>(null)
    const [showCancelConfirmationModal, setShowCancelConfirmationModal] =
        useState<ExpectedBlock | null>(null)
    const [showReadyForBridgingError, setShowReadyForBridgingError] = useState<string | null>(null)
    const ITEMS_PER_PAGE = 8

    const fetchExpectedBlocks = async (): Promise<void> => {
        try {
            setShowSpinner(true)
            const response = await ExpectedBlockApi.search(
                filters?.page ?? 1,
                ITEMS_PER_PAGE,
                '',
                '',
            )
            setSearchExpectedBlocksResponse(response.data)
        } finally {
            setShowSpinner(false)
        }
    }

    const fetchAuthenticatedUser = async (): Promise<void> => {
        try {
            const currentUserResponse = await UserApi.getAuthenticatedUser()
            setAuthenticatedUser(currentUserResponse.data)
        } catch {
            setAuthenticatedUser(undefined)
        }
    }

    useEffect(() => {
        setFilters({
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        })
    }, [])

    const updateExpectedBlocks = async () => {
        await fetchAuthenticatedUser()
        await fetchExpectedBlocks()
        if (filters.page) {
            // await fetchExpectedBlocks()
            setSearchParams({ page: filters!.page.toString() })
        }
    }

    useEffect(() => {
        void updateExpectedBlocks()
    }, [filters])

    const isUserAdmin = (): boolean => {
        if (authenticatedUser) {
            return authenticatedUser.roles.some((role) => role.name === RoleName.ADMIN)
        }
        return false
    }

    const onConfirmHandler = async (): Promise<void> => {
        if (showReadyForBridgingConfirmationModalForExpectedBlock && showReadyForBridgingError) {
            navigate(
                generatePath(Page.EDIT_EXPECTED_BLOCKS, {
                    id: showReadyForBridgingConfirmationModalForExpectedBlock.id,
                }),
            )
            return
        }
        if (showReadyForBridgingConfirmationModalForExpectedBlock) {
            try {
                await ExpectedBlockApi.onMarkAsExpectedBlockReadyForBridging(
                    showReadyForBridgingConfirmationModalForExpectedBlock.id,
                )
                await fetchExpectedBlocks()
                setShowReadyForBridgingConfirmationModalForExpectedBlock(null)
            } catch (error) {
                const e = error as AxiosError<ErrorResponse>
                setShowReadyForBridgingError(
                    e.response?.data?.message
                        ? `${
                              e.response.data.message as string
                              // eslint-disable-next-line indent
                          }. Do you want to edit expected block?`
                        : // eslint-disable-next-line indent
                          'Something went wrong',
                )
            }
        }
    }

    const mapStatusName = (status: ExpectedBlockStatus): string => {
        switch (status) {
            case ExpectedBlockStatus.NOT_READY:
                return 'Not ready'
            case ExpectedBlockStatus.READY_FOR_BRIDGING:
                return 'Ready'
            case ExpectedBlockStatus.BRIDGING_IN_PROGRESS:
                return 'Bridging In Progress'
            case ExpectedBlockStatus.BRIDGING_COMPLETE:
                return 'Completed'
            case ExpectedBlockStatus.CANCELLED:
                return 'Cancelled'
            default:
                return ''
        }
    }

    const mapInvoiceStatusName = (status: InvoiceStatus): string => {
        switch (status) {
            case InvoiceStatus.NOT_RECEIVED:
                return 'Not received'
            case InvoiceStatus.RECEVIED:
                return 'Received'
            case InvoiceStatus.SETTLED:
                return 'Settled'
            default:
                return ''
        }
    }

    return (
        <Layout>
            <div className="flex items-center w-full h-4 justify-center">
                {showSpinner && <Spinner size="xl" />}
            </div>
            <div className="overflow-x-auto">
                <button
                    className="px-4 py-2 flex justify-center items-center border border-black rounded-lg"
                    onClick={() => navigate(Page.CREATE_EXPECTED_BLOCKS)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4"
                        viewBox="0 0 448 512"
                    >
                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                    <p className="inline-block ml-2">Add Expected Block</p>
                </button>
                <table className="overflow-x-scroll md:overflow-auto block md:table md:table-auto w-full mt-6">
                    <thead className="w-full">
                        <tr className="h-11 items-center w-full bg-gray-50">
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                ID
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                REGISTRY
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                BENEFICIARY TENANT
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                REGISTRY PROJECT ID
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                PROJECT NAME
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                VINTAGE
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                PRICE PAID PER CREDIT
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                EXPECTED AMOUNT
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                STATUS
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                INVOICE STATUS
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                INVOICE REFERENCE
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                                ACTIONS
                            </th>
                            <th className="text-gray-500 font-semibold text-xs text-left px-6">
                                EDIT
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {searchExpectedBlocksResponse?.results.map(
                            (expectedBlock: ExpectedBlock) => {
                                return (
                                    <tr
                                        className="items-center w-full border-b-2 border-gray-200"
                                        key={expectedBlock.id}
                                    >
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {expectedBlock?.show_id}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {expectedBlock?.registry?.name ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {expectedBlock?.beneficiary_tenant_id ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {expectedBlock?.registry_project_id ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {expectedBlock?.project_name ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {expectedBlock.vintage ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {/* eslint-disable indent */}
                                            {expectedBlock.price_paid_per_credit
                                                ? convertCentsToLocaleUsd(
                                                      BigNumber.from(
                                                          expectedBlock.price_paid_per_credit,
                                                      ),
                                                  )
                                                : 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {expectedBlock.expected_amount ?? 'N/A'}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {mapStatusName(expectedBlock.status)}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                            {mapInvoiceStatusName(expectedBlock.invoice_status)}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left px-6 font-normal">
                                            {expectedBlock.invoice_reference && (
                                                <div className="flex justify-between items-center">
                                                    <span className="mr-2">
                                                        {expectedBlock.invoice_reference}
                                                    </span>
                                                    <CopyButton
                                                        text={expectedBlock.invoice_reference}
                                                    />
                                                </div>
                                            )}
                                        </td>
                                        <td className="min-h-18 text-gray-500 text-sm text-left pl-6 font-normal flex flex-col justify-center">
                                            {expectedBlock.status ===
                                                ExpectedBlockStatus.NOT_READY && (
                                                <button
                                                    className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] my-[1px]"
                                                    onClick={() =>
                                                        setShowReadyForBridgingConfirmationModalForExpectedBlock(
                                                            expectedBlock,
                                                        )
                                                    }
                                                >
                                                    Ready For Bridging
                                                </button>
                                            )}
                                            {expectedBlock.status ===
                                                ExpectedBlockStatus.READY_FOR_BRIDGING &&
                                                isUserAdmin() && (
                                                    <button
                                                        className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] my-[1px]"
                                                        onClick={() =>
                                                            navigate(
                                                                `${Page.BRIDGE}?expected_block=${expectedBlock.id}`,
                                                            )
                                                        }
                                                    >
                                                        Start Bridging
                                                    </button>
                                                )}
                                            {
                                                <button
                                                    className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] my-[1px]"
                                                    onClick={async () => {
                                                        await ExpectedBlockApi.duplicate(
                                                            expectedBlock.id,
                                                        )
                                                        await updateExpectedBlocks()
                                                    }}
                                                >
                                                    Duplicate
                                                </button>
                                            }
                                            {![
                                                ExpectedBlockStatus.BRIDGING_COMPLETE,
                                                ExpectedBlockStatus.BRIDGING_IN_PROGRESS,
                                                ExpectedBlockStatus.CANCELLED,
                                            ].includes(expectedBlock.status) && (
                                                <button
                                                    className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] my-[1px]"
                                                    onClick={() => {
                                                        setShowCancelConfirmationModal(
                                                            expectedBlock,
                                                        )
                                                    }}
                                                >
                                                    Cancel
                                                </button>
                                            )}
                                        </td>
                                        <td className="text-gray-500 text-sm text-left px-6 font-normal">
                                            {expectedBlock.status ===
                                                ExpectedBlockStatus.NOT_READY && (
                                                <EditButton
                                                    onClick={() =>
                                                        navigate(
                                                            generatePath(
                                                                Page.EDIT_EXPECTED_BLOCKS,
                                                                {
                                                                    id: expectedBlock.id,
                                                                },
                                                            ),
                                                        )
                                                    }
                                                />
                                            )}
                                        </td>
                                    </tr>
                                )
                            },
                        )}
                    </tbody>
                </table>
                <ConfirmationModal
                    question={
                        <div>
                            <p>
                                {showReadyForBridgingError ??
                                    'Are you sure you want to mark this as ready for bridging?'}
                            </p>
                        </div>
                    }
                    confirmButtonText="Confirm"
                    cancelButtonText="Cancel"
                    show={!!showReadyForBridgingConfirmationModalForExpectedBlock}
                    onClose={() => {
                        setShowReadyForBridgingConfirmationModalForExpectedBlock(null)
                        setShowReadyForBridgingError(null)
                    }}
                    onConfirm={onConfirmHandler}
                />
                <ConfirmationModal
                    question={
                        <div>
                            <p>Are you sure you want to mark this as cancelled?</p>
                        </div>
                    }
                    confirmButtonText="Confirm"
                    cancelButtonText="Cancel"
                    show={!!showCancelConfirmationModal}
                    onClose={() => {
                        setShowCancelConfirmationModal(null)
                    }}
                    onConfirm={async () => {
                        await ExpectedBlockApi.cancel(showCancelConfirmationModal!.id)
                        await updateExpectedBlocks()
                        setShowCancelConfirmationModal(null)
                    }}
                />
                {/* eslint-disable indent */}
                {searchExpectedBlocksResponse &&
                    searchExpectedBlocksResponse.pagination.total_items > ITEMS_PER_PAGE && (
                        <Pagination
                            className="mt-1"
                            currentPage={
                                searchExpectedBlocksResponse?.pagination?.current_page ?? 1
                            }
                            totalPages={searchExpectedBlocksResponse?.pagination?.total_pages ?? 1}
                            onPageChange={(page: number) => {
                                setFilters((prevFilters) => ({
                                    ...prevFilters,
                                    page,
                                }))
                            }}
                        />
                    )}
            </div>
        </Layout>
    )
}

export default ExpectedBlocks
