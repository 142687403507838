/* eslint-disable indent */
import { TenantApi } from '../api/tenant'
import { TenantType } from '../enums/TenantType'
import { Tenant } from '../types/Tenant'
import XMarkIcon from './XMarkIcon'
import { useEffect, useState } from 'react'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'

interface SelectTenantProps {
    onSelect: (tenantId: string) => void
    showClearButton?: boolean
    onClearSearch: () => void
    value?: string
    valueContainerStyles?: any
}

export const SelectTenant = ({
    onSelect,
    showClearButton = false,
    onClearSearch,
    value,
    valueContainerStyles,
}: SelectTenantProps): JSX.Element | null => {
    const [tenants, setTenants] = useState<Tenant[]>([])

    const fetchTenants = async () => {
        const response = await TenantApi.search({
            type: TenantType.ENTITY,
        })
        setTenants(response.data)
    }

    useEffect(() => {
        fetchTenants()
    }, [])

    const loadOptions = (inputValue: any) => {
        return TenantApi.search({
            entity_name: inputValue,
        }).then((response) => {
            setTenants(response.data)
            return response.data.map((tenant: Tenant) => {
                return {
                    value: tenant.id,
                    label: tenant.entity_name,
                }
            })
        })
    }

    return (
        <div className="flex flex-row gap-2">
            <AsyncSelect
                loadOptions={loadOptions}
                value={
                    value
                        ? ({
                              value: value,
                              label: tenants.find((tenant) => tenant.id === value)?.entity_name,
                          } as SingleValue<{ value: string; label: string }>)
                        : null
                }
                defaultOptions={tenants.map((tenant) => {
                    return {
                        value: tenant.id,
                        label: `${tenant.entity_name}`,
                    }
                })}
                isLoading={false}
                isSearchable={true}
                onChange={(value: SingleValue<{ value: string; label: string }>) => {
                    const tenant = tenants.find((tenant) => tenant.id === value?.value)
                    if (tenant) {
                        onSelect(tenant.id)
                    }
                }}
                styles={{
                    valueContainer: (provided) => ({
                        ...provided,
                        textAlign: 'left',
                        ...valueContainerStyles,
                    }),
                    option: (provided) => ({
                        ...provided,
                        textAlign: 'left',
                    }),
                }}
            />
            {showClearButton && value && (
                <button
                    onClick={async () => {
                        await fetchTenants()
                        onClearSearch()
                    }}
                >
                    <XMarkIcon />
                </button>
            )}
        </div>
    )
}
