import { EmptyResponse } from '../types/EmptyResponse'
import { SaveWebhookRequest } from '../types/SaveWebhookRequest'
import { SearchWebhooksQueryParams } from '../types/SearchWebhooksQueryParams'
import { SearchWebhooksResponse } from '../types/SearchWebhooksResponse'
import { UpdateWebhookRequest } from '../types/UpdateWebhookRequest'
import { Webhook } from '../types/Webhook'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface WebhookApiInterface {
    search(data: SearchWebhooksQueryParams): Promise<AxiosResponse<SearchWebhooksResponse>>
    save(data: SaveWebhookRequest): Promise<AxiosResponse<SearchWebhooksResponse>>
    setIsActive(id: string, isActive: boolean): Promise<AxiosResponse<EmptyResponse>>
    delete(id: string): Promise<AxiosResponse<EmptyResponse>>
    getById(id: string): Promise<AxiosResponse<Webhook>>
    update(id: string, data: UpdateWebhookRequest): Promise<AxiosResponse<EmptyResponse>>
}

export const WebhookApi: WebhookApiInterface = Object.freeze({
    search(data: SearchWebhooksQueryParams): Promise<AxiosResponse<SearchWebhooksResponse>> {
        return ApiService.get('/admin/webhooks', null, data)
    },
    save(data: SaveWebhookRequest): Promise<AxiosResponse<SearchWebhooksResponse>> {
        return ApiService.post('/admin/webhooks', data)
    },
    setIsActive(id: string, isActive: boolean): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post(`/admin/webhooks/${id}/is-active`, { is_active: isActive })
    },
    delete(id: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.delete(`/admin/webhooks/${id}`)
    },
    getById(id: string): Promise<AxiosResponse<Webhook>> {
        return ApiService.get('/admin/webhooks', id)
    },
    update(id: string, data: UpdateWebhookRequest): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.patch(`/admin/webhooks/${id}`, data)
    },
})
