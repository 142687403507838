import { RetirementRequestApi } from '../api/retirementRequest'
import { CopyButton } from '../components/CopyButton'
import Layout from '../components/Layout'
import { Page } from '../enums/Page'
import { RetirementRequest } from '../types/RetirementRequest'
import { BigNumber } from 'ethers'
import { useEffect, useState } from 'react'
import { useNavigate, generatePath } from 'react-router-dom'

const RetirementRequests = (): JSX.Element => {
    const [retirementRequests, setRetirementRequests] = useState<RetirementRequest[]>([])
    const navigate = useNavigate()

    const fetchRetirementRequests = async () => {
        const response = await RetirementRequestApi.getAll()
        setRetirementRequests(response.data)
    }

    useEffect(() => {
        fetchRetirementRequests()
    }, [])

    return (
        <Layout>
            <table className="overflow-x-scroll md:overflow-auto block md:table md:table-auto w-full">
                <thead className="w-full">
                    <tr className="h-11 items-center w-full bg-gray-50">
                        <th className="text-gray-500 font-semibold text-xs text-left pl-6">ID</th>
                        <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                            RETIREE NAME
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                            PROJECT NAME
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                            VINTAGE
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                            REGISTRY
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                            QUANTITY
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                            EXTERNAL ID
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                            STATUS
                        </th>
                        <th className="text-gray-500 font-semibold text-xs text-left pl-6">
                            ACTIONS
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {retirementRequests.map((request: RetirementRequest, index: number) => {
                        return (
                            <tr className="h-18 items-center w-full" key={index}>
                                <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                    {request.id}
                                </td>
                                <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                    <div className="flex justify-between items-center">
                                        <span>
                                            {request?.retiree_override ??
                                                request?.tenant?.entity_name}
                                        </span>
                                        <CopyButton
                                            text={
                                                request?.retiree_override ??
                                                request?.tenant?.entity_name
                                            }
                                        />
                                    </div>
                                </td>
                                <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                    {request?.vintage?.project?.name}
                                </td>
                                <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                    {request?.vintage?.vintage}
                                </td>
                                <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                    {request?.vintage?.project?.registry?.name}
                                </td>
                                <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                    {BigNumber.from(request?.quantity).toString()}
                                </td>
                                <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                    {request?.external_id}
                                </td>
                                <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                    {request?.status}
                                </td>
                                <td className="text-gray-500 text-sm text-left pl-6 font-normal">
                                    {request.async_item_id && (
                                        <button
                                            className="bg-[#3e3d3d] p-2.5 rounded-md text-white"
                                            onClick={() =>
                                                navigate(
                                                    generatePath(Page.RETIREMENT, {
                                                        id: request.async_item_id,
                                                    }),
                                                )
                                            }
                                        >
                                            View Retirement
                                        </button>
                                    )}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </Layout>
    )
}

export default RetirementRequests
