import { Page } from '../enums/Page'
import { CookieKey } from '../storage/enums/CookieKey'
import { setCookie } from '../storage/useCookie'
import { ChangeEvent, SyntheticEvent, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const SignIn = (): JSX.Element => {
    const navigate = useNavigate()
    const [apiUrl, setApiUrl] = useState('')
    const [apiKey, setApiKey] = useState('')

    const onSubmit = (e: SyntheticEvent): void => {
        e.preventDefault()
        e.stopPropagation()
        setCookie(CookieKey.API_KEY, apiKey, {
            expires: 'Session',
        })
        setCookie(CookieKey.API_URL, apiUrl, {
            expires: 'Session',
        })
        navigate(Page.ASYNC_ITEMS)
    }

    return (
        <div className="h-screen">
            <div className="h-32 bg-[#3e3d3d] flex flex-row align-center items-center">
                <img src="/thallo_logo_stacked_white_text.png" className="h-32" />
                <h2 className="text-xl ml-10 text-white">Bridge Manager</h2>
            </div>
            <div className="mt-[300px] flex justify-center">
                <form className="flex flex-col items-end w-80" onSubmit={onSubmit}>
                    <input
                        placeholder="API URL..."
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setApiUrl(e.target.value)}
                        required={true}
                        className="w-80 h-10 pl-2.5 mb-2.5 border border-black"
                    />
                    <input
                        placeholder="API KEY..."
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setApiKey(e.target.value)}
                        required={true}
                        className="w-80 h-10 pl-2.5 border border-black"
                    />
                    <button
                        type="submit"
                        className="w-[110px] py-2.5 px-5 mt-2.5 bg-[#e46d22] rounded-md text-white"
                    >
                        Login
                    </button>
                </form>
            </div>
            <div className="absolute bottom-0 w-full h-[30px] bg-[#3e3d3d]" />
        </div>
    )
}

export default SignIn
