import { AdminSearchUsersParams } from '../types/AdminSearchUsersParams'
import { CreateUserAndTenantPayload } from '../types/CreateUserAndTenantPayload'
import { CreateUserForExistingTenantPayload } from '../types/CreateUserForExistingTenantPayload'
import { EmptyResponse } from '../types/EmptyResponse'
import { SearchUsersResponse } from '../types/SearchUsersResponse'
import { UpdateUserPayload } from '../types/UpdateUserPayload'
import { User } from '../types/User'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface UserApiInterface {
    getAllUsers(params: AdminSearchUsersParams): Promise<AxiosResponse<SearchUsersResponse>>
    getUserById(id: string): Promise<AxiosResponse<User>>
    getAuthenticatedUser(): Promise<AxiosResponse<User>>
    setIsUserActive(id: string, isActive: boolean): Promise<AxiosResponse<EmptyResponse>>
    createUserAndTenant(payload: CreateUserAndTenantPayload): Promise<AxiosResponse<EmptyResponse>>
    createUserForExistingTenant(
        payload: CreateUserForExistingTenantPayload,
    ): Promise<AxiosResponse<EmptyResponse>>
    updateUser(id: string, payload: UpdateUserPayload): Promise<AxiosResponse<EmptyResponse>>
    hasExchangeUser(tenantId: string): Promise<AxiosResponse<boolean>>
}

export const UserApi: UserApiInterface = Object.freeze({
    getAllUsers(params: AdminSearchUsersParams): Promise<AxiosResponse<SearchUsersResponse>> {
        return ApiService.get('/admin/users/', null, params)
    },
    getUserById(id: string): Promise<AxiosResponse<User>> {
        return ApiService.get('/admin/users/all', id)
    },
    getAuthenticatedUser(): Promise<AxiosResponse<User>> {
        return ApiService.get('/admin/users/authenticated-user')
    },
    setIsUserActive(id: string, isActive: boolean): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.patch(`/admin/users/${id}/is-active`, { is_active: isActive })
    },
    createUserAndTenant(
        payload: CreateUserAndTenantPayload,
    ): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/users/create-user', payload)
    },
    createUserForExistingTenant(
        payload: CreateUserForExistingTenantPayload,
    ): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/users/create-for-tenant', payload)
    },
    updateUser(id: string, payload: UpdateUserPayload): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.patch(`/admin/users/${id}`, payload)
    },
    hasExchangeUser(tenantId: string): Promise<AxiosResponse<boolean>> {
        return ApiService.get(`/admin/users/tenant-has-exchange-user/${tenantId}`)
    },
})
