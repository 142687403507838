import { AsyncItemApi } from '../api/asyncItem'
import { BridgeCreditsApi } from '../api/bridgeCreditsApi'
import { ExpectedBlockApi } from '../api/expectedBlock'
import { TenantCustomerApi } from '../api/tenantCustomer'
import ConfirmationModal from '../components/ConfirmationModal'
import { CopyButton } from '../components/CopyButton'
import { EditButton } from '../components/EditButton'
import { JsonEditorModal } from '../components/JsonEditorModal'
import Layout from '../components/Layout'
import { AsyncItemStatuses } from '../enums/AsyncItemStatuses'
import { AsyncItemSubStatuses } from '../enums/AsyncItemSubStatuses'
import { AsyncItemTypes } from '../enums/AsyncItemTypes'
import { Blockchain } from '../enums/Blockchain'
import { ExpectedBlockStatus } from '../enums/ExpectedBLockStatus'
import { Page } from '../enums/Page'
import { RegistryIds } from '../enums/RegistryIds'
import { SearchAsyncItemsResponse } from '../enums/SearchAsyncItemsResponse'
import { AsyncItem } from '../types/AsyncItem'
import { CreditsReceivedJsonData } from '../types/CreditsReceivedJsonData'
import { CreditsReceivedRequestJsonData } from '../types/CreditsReceivedRequestJsonData'
import { ExpectedBlock } from '../types/ExpectedBlock'
import { OrderedExpectedBlocks } from '../types/OrderedExpectedBlocks'
import { Pagination as ApiPagination } from '../types/Pagination'
import { OrderedTenantCustomer } from '../types/TenantCustomer'
import { Spinner, ToggleSwitch, Pagination } from 'flowbite-react'
import { useEffect, useState } from 'react'
import JSONPretty from 'react-json-pretty'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

interface AsyncItemFilters {
    showJsonFields: boolean
    type?: AsyncItemTypes
    status?: AsyncItemStatuses
    subStatus?: AsyncItemSubStatuses
    page?: number
    perPage?: number
}

const defaultFilters: AsyncItemFilters = {
    showJsonFields: true,
    perPage: 10,
}

const defaultPagination: ApiPagination = {
    per_page: 10,
    total_pages: 0,
    current_page: 0,
    total_items: 0,
    previous_page: null,
    next_page: null,
}

const AsyncItems = (): JSX.Element => {
    const [searchAsyncItemsResponse, setSearchAsyncItemsResponse] =
        useState<SearchAsyncItemsResponse | null>(null)
    const [showApproveConfirmationModalForAsyncItem, setShowApproveConfirmationModalForAsyncItem] =
        useState<AsyncItem | null>(null)
    const [showCreditsReturnedModalForAsyncItem, setShowCreditsReturnedModalForAsyncItem] =
        useState<AsyncItem | null>(null)
    const [
        showSelectBlockchainConfirmationModalForAsyncItem,
        setShowSelectBlockchainConfirmationModalForAsyncItem,
    ] = useState<AsyncItem | null>(null)
    const [showAreYouSureModalForAsyncItem, setShowAreYouSureModalForAsyncItem] =
        useState<AsyncItem | null>(null)
    const [showCancelBridgingModal, setShowCancelBridgingModal] = useState<AsyncItem | null>(null)
    const [selectedBlockchain, setSelectedBlockchain] = useState<Blockchain>(Blockchain.POLYGON)
    const navigate = useNavigate()
    const [selectedBeneficiaryTenantId, setSelectedBeneficiaryTenantId] = useState<
        string | undefined
    >(undefined)
    const [showEditModalForAsyncItem, setShowEditModalForAsyncItem] = useState<AsyncItem | null>(
        null,
    )
    const [showSpinner, setShowSpinner] = useState<boolean>(true)
    const [filters, setFilters] = useState<AsyncItemFilters>(defaultFilters)
    const [selectBlockchains, setSelectBlockchains] = useState<JSX.Element | JSX.Element[]>(<></>)
    const [showSelectExpectedBlockModal, setShowSelectExpectedBlockModal] =
        useState<AsyncItem | null>(null)
    const [expectedBlocks, setExpectedBlocks] = useState<{
        pagination: ApiPagination
        expectedBlocks: OrderedExpectedBlocks[]
    }>({
        pagination: defaultPagination,
        expectedBlocks: [],
    })
    const [selectedExpectedBlock, setSelectedExpectedBlock] = useState<string | null>(null)
    const [showSetTenantCustomerModal, setShowSetTenantCustomerModal] = useState<AsyncItem | null>(
        null,
    )
    const [tenantCutomsers, setTenantCutomsers] = useState<{
        pagination: ApiPagination
        tenantCustomers: OrderedTenantCustomer
    }>({
        pagination: defaultPagination,
        tenantCustomers: {},
    })
    const [selectedTenantCustomer, setSelectedTenantCustomer] = useState<string | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        if (showSelectBlockchainConfirmationModalForAsyncItem?.json_data) {
            setSelectedBeneficiaryTenantId(
                JSON.parse(showSelectBlockchainConfirmationModalForAsyncItem?.json_data)
                    ?.creditSenderThalloCompanyId,
            )
        } else {
            setSelectedBeneficiaryTenantId(undefined)
        }
    }, [showSelectBlockchainConfirmationModalForAsyncItem])

    const fetchAsyncItems = async (): Promise<void> => {
        setShowSpinner(true)
        try {
            const response = await AsyncItemApi.search({
                status: filters.status,
                type: filters.type,
                sub_status: filters.subStatus,
                page: filters?.page ?? 1,
                per_page: filters?.perPage ?? 10,
            })
            setSearchAsyncItemsResponse(response.data)
        } catch {
            setSearchAsyncItemsResponse(null)
        } finally {
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        const loadData = async () => {
            await fetchAsyncItems()
            setFilters({
                page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                perPage: searchParams.get('perPage') ? Number(searchParams.get('perPage')) : 10,
                showJsonFields: searchParams.get('showJsonFields') === 'true',
                type: searchParams.get('type')
                    ? (searchParams.get('type') as AsyncItemTypes)
                    : undefined,
                status: searchParams.get('status')
                    ? (searchParams.get('status') as AsyncItemStatuses)
                    : undefined,
                subStatus: searchParams.get('subStatus')
                    ? (searchParams.get('subStatus') as AsyncItemSubStatuses)
                    : undefined,
            })
        }
        loadData()
    }, [])

    useEffect(() => {
        fetchAsyncItems()
        const searchParamsFilters = Object.keys(filters).reduce((acc, current) => {
            if (current === 'page' && filters.page) {
                return {
                    ...acc,
                    page: filters.page,
                }
            }
            if (current === 'perPage' && filters.perPage) {
                return {
                    ...acc,
                    perPage: filters.perPage,
                }
            }
            if (current === 'showJsonFields') {
                return {
                    ...acc,
                    showJsonFields: filters.showJsonFields,
                }
            }
            if (current === 'type' && filters.type) {
                return {
                    ...acc,
                    type: filters.type,
                }
            }
            if (current === 'status' && filters.status) {
                return {
                    ...acc,
                    status: filters.status,
                }
            }
            if (current === 'subStatus' && filters.subStatus) {
                return {
                    ...acc,
                    subStatus: filters.subStatus,
                }
            }
            return acc
        }, {})
        setSearchParams(searchParamsFilters)
    }, [filters])

    const onApprove = async (asyncItem: AsyncItem): Promise<void> => {
        if (asyncItem.type === AsyncItemTypes.REGISTRY_CREDITS_RECEIVED) {
            setShowSelectBlockchainConfirmationModalForAsyncItem(asyncItem)
        } else {
            setShowAreYouSureModalForAsyncItem(asyncItem)
        }
    }

    const showApproveButton = (asyncItem: AsyncItem): boolean => {
        if (asyncItem.status !== AsyncItemStatuses.UNAPPROVED) {
            return false
        }
        if (
            ![
                AsyncItemTypes.RETIRE_CREDITS,
                AsyncItemTypes.PURO_TRADER_ONBOARDING_REQUEST,
            ].includes(asyncItem.type)
        ) {
            return true
        }
        if (
            asyncItem.type === AsyncItemTypes.RETIRE_CREDITS &&
            asyncItem.sub_status !== AsyncItemSubStatuses.PENDING_RETIRE_BLOCKS_DB
        ) {
            return true
        }

        return false
    }

    useEffect(() => {
        const polygon = (
            <option value="polygon" key="polygon">
                Polygon
            </option>
        )
        const xrpl = (
            <option value="xrpl" key="xrpl">
                XRPL (Ripple)
            </option>
        )
        const noBlockchain = (
            <option value="noblockchain" key="noblockchain">
                No Blockchain
            </option>
        )

        const defaultOptions = [polygon, xrpl, noBlockchain]

        if (!showSelectBlockchainConfirmationModalForAsyncItem)
            return setSelectBlockchains(defaultOptions)

        const jsonData: CreditsReceivedJsonData = JSON.parse(
            showSelectBlockchainConfirmationModalForAsyncItem?.json_data || '{}',
        )
        const blockchain: Blockchain | null = jsonData.blockchain
        const registryId: RegistryIds = (jsonData.registryId || '') as RegistryIds
        if ([RegistryIds.VERRA, RegistryIds.GOLD_STANDARD].includes(registryId)) {
            setSelectedBlockchain(Blockchain.NO_BLOCKCHAIN)
            return setSelectBlockchains(noBlockchain)
        }

        if (!blockchain) {
            return setSelectBlockchains(defaultOptions)
        }

        if (blockchain === Blockchain.POLYGON) {
            setSelectedBlockchain(Blockchain.POLYGON)
            return setSelectBlockchains(polygon)
        }
        if (blockchain === Blockchain.XRPL) {
            setSelectedBlockchain(Blockchain.XRPL)
            return setSelectBlockchains(xrpl)
        }
        if (blockchain === Blockchain.NO_BLOCKCHAIN) {
            setSelectedBlockchain(Blockchain.NO_BLOCKCHAIN)
            return setSelectBlockchains(noBlockchain)
        }

        return setSelectBlockchains(defaultOptions)
    }, [showSelectBlockchainConfirmationModalForAsyncItem])

    const getExpectedBlocks = async (asyncItem: AsyncItem, page: number = 1) => {
        const jsonData: CreditsReceivedRequestJsonData = JSON.parse(asyncItem.json_data)
        const { data: expectedBlocks } = await ExpectedBlockApi.search(
            page,
            10,
            jsonData.registry_id,
            ExpectedBlockStatus.READY_FOR_BRIDGING,
        )
        let blocks = expectedBlocks.results.reduce(
            (acc: OrderedExpectedBlocks[], block: ExpectedBlock) => {
                let existingRegistrySection = acc.find(
                    (registrySection) => registrySection.registry === block.registry.name,
                )
                if (!existingRegistrySection) {
                    existingRegistrySection = { registry: block.registry.name, projects: [] }
                    acc.push(existingRegistrySection)
                }

                let project = existingRegistrySection!.projects.find(
                    (project) => project.name === block.project_name,
                )
                if (!project) {
                    project = {
                        name: block.project_name,
                        vintages: [],
                    }
                    existingRegistrySection!.projects.push(project)
                }
                if (!project!.vintages[block.vintage]) {
                    project!.vintages[block.vintage] = []
                }
                project!.vintages[block.vintage].push(block)
                return acc
            },
            [] as OrderedExpectedBlocks[],
        )
        return setExpectedBlocks({ pagination: expectedBlocks.pagination, expectedBlocks: blocks })
    }

    useEffect(() => {
        if (
            !showSelectExpectedBlockModal ||
            ![AsyncItemTypes.CREDITS_RECEIVED_REQUEST].includes(showSelectExpectedBlockModal.type)
        ) {
            return setExpectedBlocks({ pagination: defaultPagination, expectedBlocks: [] })
        }
        void getExpectedBlocks(showSelectExpectedBlockModal)
    }, [showSelectExpectedBlockModal])

    const getTenantCustomers = async (page: number = 1) => {
        const { data } = await TenantCustomerApi.getAll(10, page)
        const orderedTenantCustomers = data.tenant_customers.reduce(
            (acc: OrderedTenantCustomer, customer): OrderedTenantCustomer => {
                if (!acc[customer.tenant_id]) {
                    acc[customer.tenant_id] = []
                }
                acc[customer.tenant_id].push(customer)
                return acc
            },
            {},
        )
        setTenantCutomsers({ tenantCustomers: orderedTenantCustomers, pagination: data.pagination })
    }

    const approveOnboarding = async (asyncItem: AsyncItem) => {
        if (!selectedTenantCustomer) return
        await AsyncItemApi.approveAndSetTenantCustomer(asyncItem.id, selectedTenantCustomer)
    }

    const rejectOnboarding = async (asyncItem: AsyncItem) => {
        await AsyncItemApi.rejectOnboarding(asyncItem.id)
    }

    useEffect(() => {
        if (!showSetTenantCustomerModal) return
        void getTenantCustomers()
    }, [showSetTenantCustomerModal])

    return (
        <Layout>
            <div className="flex items-center w-full h-4 justify-center">
                {showSpinner && <Spinner size="xl" />}
            </div>

            <div
                style={{
                    height: 'calc(100vh - 180px)',
                }}
            >
                <button
                    className="px-4 py-2 flex flex-row border border-black mb-2 rounded-lg fixed bottom-[100px]"
                    onClick={async () => {
                        setSearchAsyncItemsResponse(null)
                        await fetchAsyncItems()
                    }}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                        />
                    </svg>
                    <p>Refresh</p>
                </button>
                <div className="bg-gray-300 flex gap-10 px-5">
                    <div className="flex flex-col justify-center align-center">
                        <p>Show JSON fields</p>
                        <div className="flex justify-center mt-2">
                            <ToggleSwitch
                                checked={filters.showJsonFields}
                                label=""
                                onChange={() =>
                                    setFilters({
                                        ...filters,
                                        showJsonFields: !filters.showJsonFields,
                                    })
                                }
                            />
                        </div>
                    </div>
                    <div>
                        <p className="text-left">Type</p>
                        <select
                            value={filters?.type ?? 'Choose'}
                            onChange={(event) => {
                                setFilters({
                                    ...filters,
                                    type:
                                        event.target.value === 'Choose'
                                            ? undefined
                                            : (event.target.value as AsyncItemTypes),
                                })
                            }}
                        >
                            <option>Choose</option>
                            {Object.keys(AsyncItemTypes).map((key) => {
                                return (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <p className="text-left">Status</p>
                        <select
                            value={filters.status ?? 'Choose'}
                            onChange={(event) => {
                                setFilters({
                                    ...filters,
                                    status:
                                        event.target.value === 'Choose'
                                            ? undefined
                                            : (event.target.value as AsyncItemStatuses),
                                })
                            }}
                        >
                            <option>Choose</option>
                            {Object.keys(AsyncItemStatuses).map((key) => {
                                return (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <p className="text-left">Sub Status</p>
                        <select
                            value={filters.subStatus ?? 'Choose'}
                            onChange={(event) => {
                                setFilters({
                                    ...filters,
                                    subStatus:
                                        event.target.value === 'Choose'
                                            ? undefined
                                            : (event.target.value as AsyncItemSubStatuses),
                                })
                            }}
                        >
                            <option>Choose</option>
                            {Object.keys(AsyncItemSubStatuses).map((key) => {
                                return (
                                    <option key={key} value={key}>
                                        {key}
                                    </option>
                                )
                            })}
                        </select>
                    </div>
                    <div>
                        <p className="text-left">Results Per Page</p>
                        <select
                            value={filters.perPage ?? 'Choose'}
                            onChange={(event) => {
                                setFilters({
                                    ...filters,
                                    perPage:
                                        event.target.value === 'Choose'
                                            ? undefined
                                            : Number(event.target.value),
                                })
                            }}
                        >
                            <option>Choose</option>
                            <option key={5} value={5}>
                                {5}
                            </option>
                            <option key={10} value={10}>
                                {10}
                            </option>
                            <option key={20} value={20}>
                                {20}
                            </option>
                            <option key={50} value={50}>
                                {50}
                            </option>
                        </select>
                    </div>
                    <div className="flex flex-col justify-center align-center">
                        <div className="flex justify-center mt-2">
                            <button
                                onClick={() => setFilters(defaultFilters)}
                                className="bg-gray-50 p-2"
                            >
                                Reset all filters
                            </button>
                        </div>
                    </div>
                </div>
                <table className="mt-12">
                    <thead>
                        <tr className="h-11 text-left bg-[#3e3d3d]">
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                ID
                            </th>
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                TYPE
                            </th>
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                STATUS
                            </th>
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                SUB STATUS
                            </th>
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                CREATOR
                            </th>
                            {filters.showJsonFields && (
                                <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                    JSON DATA
                                </th>
                            )}
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                ACTIONS
                            </th>
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                CREATED AT
                            </th>
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                UPDATED AT
                            </th>
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                STARTED AT
                            </th>
                            <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                ERROR CODE
                            </th>
                            {filters.showJsonFields && (
                                <th className="flex-1 text-red-500 min-w-20 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                    ERROR MESSAGE
                                </th>
                            )}
                        </tr>
                    </thead>
                    <tbody>
                        {searchAsyncItemsResponse?.results.map(
                            (asyncItem: AsyncItem, index: number) => {
                                return (
                                    <tr
                                        className={index % 2 === 0 ? 'bg-white' : 'bg-gray-100'}
                                        key={index}
                                    >
                                        <td className="flex flex-row gap-2 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            {asyncItem.id}
                                            <CopyButton text={asyncItem.id} />
                                        </td>
                                        <td className="flex-1 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            {asyncItem.type}
                                        </td>
                                        <td className="flex-1 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            {asyncItem.status}
                                        </td>
                                        <td className="flex-1 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            {asyncItem.sub_status}
                                        </td>
                                        <td className="flex-1 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            <p>Tenant Id: {asyncItem?.user.tenant_id}</p>
                                            <p>
                                                Tenant name: {asyncItem?.user?.tenant?.entity_name}
                                            </p>
                                            <p>Email: {asyncItem?.user?.email}</p>
                                        </td>
                                        {filters.showJsonFields && (
                                            <td className="flex flex-row gap-2 items-start text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                                <JSONPretty
                                                    data={{
                                                        ...asyncItem,
                                                        json_data: JSON.parse(asyncItem.json_data),
                                                        error_msg: '{}',
                                                    }}
                                                    space="4"
                                                    style={{ backgroundColor: '#eee' }}
                                                ></JSONPretty>
                                                <CopyButton
                                                    text={JSON.stringify({
                                                        ...asyncItem,
                                                        json_data: JSON.parse(asyncItem.json_data),
                                                        error_msg: '{}',
                                                    })}
                                                />
                                                {(asyncItem.status ===
                                                    AsyncItemStatuses.UNAPPROVED ||
                                                    asyncItem.status ===
                                                        AsyncItemStatuses.FAILED) && (
                                                    <EditButton
                                                        onClick={() =>
                                                            setShowEditModalForAsyncItem(asyncItem)
                                                        }
                                                    />
                                                )}
                                            </td>
                                        )}
                                        <td className="flex-1 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            {showApproveButton(asyncItem) && (
                                                <button
                                                    className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] mb-2.5"
                                                    onClick={() => onApprove(asyncItem)}
                                                >
                                                    Approve
                                                </button>
                                            )}
                                            {asyncItem.type ===
                                                AsyncItemTypes.PURO_TRADER_ONBOARDING_REQUEST &&
                                                asyncItem.status ===
                                                    AsyncItemStatuses.UNAPPROVED && (
                                                    <>
                                                        <button
                                                            className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] mb-2.5"
                                                            onClick={() =>
                                                                setShowSetTenantCustomerModal(
                                                                    asyncItem,
                                                                )
                                                            }
                                                        >
                                                            Approve
                                                        </button>
                                                        <button
                                                            className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] mb-2.5"
                                                            onClick={async () => {
                                                                await rejectOnboarding(asyncItem)
                                                                await fetchAsyncItems()
                                                                setShowSetTenantCustomerModal(null)
                                                            }}
                                                        >
                                                            Reject
                                                        </button>
                                                    </>
                                                )}
                                            {asyncItem.type ===
                                                AsyncItemTypes.CREDITS_RECEIVED_REQUEST &&
                                                asyncItem.status !== AsyncItemStatuses.FAILED &&
                                                asyncItem.sub_status ===
                                                    AsyncItemSubStatuses.MANUAL_MATCH_EXPECTED_BLOCK && (
                                                    <button
                                                        className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] mb-2.5"
                                                        onClick={() =>
                                                            setShowSelectExpectedBlockModal(
                                                                asyncItem,
                                                            )
                                                        }
                                                    >
                                                        Set Expected Block
                                                    </button>
                                                )}
                                            {asyncItem.type ===
                                                AsyncItemTypes.CREDITS_RECEIVED_REQUEST &&
                                                asyncItem.status !== AsyncItemStatuses.FAILED &&
                                                asyncItem.sub_status ===
                                                    AsyncItemSubStatuses.MANUAL_MATCH_EXPECTED_BLOCK && (
                                                    <button
                                                        className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] mb-2.5"
                                                        onClick={() =>
                                                            setShowCreditsReturnedModalForAsyncItem(
                                                                asyncItem,
                                                            )
                                                        }
                                                    >
                                                        Set Credits Returned on Registry
                                                    </button>
                                                )}
                                            {asyncItem.status === AsyncItemStatuses.FAILED && (
                                                <button
                                                    className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px] mb-2.5"
                                                    onClick={() =>
                                                        setShowApproveConfirmationModalForAsyncItem(
                                                            asyncItem,
                                                        )
                                                    }
                                                >
                                                    Retry
                                                </button>
                                            )}
                                            {asyncItem.type === AsyncItemTypes.RETIRE_CREDITS && (
                                                <button
                                                    className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px]"
                                                    onClick={() =>
                                                        navigate(
                                                            generatePath(Page.RETIREMENT, {
                                                                id: asyncItem.id,
                                                            }),
                                                        )
                                                    }
                                                >
                                                    View Retirement
                                                </button>
                                            )}
                                            {asyncItem.type === AsyncItemTypes.UNBRIDGE_CREDITS && (
                                                <button
                                                    className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px]"
                                                    onClick={() =>
                                                        navigate(
                                                            generatePath(Page.UNBRIDGING_EVENT, {
                                                                id: asyncItem.id,
                                                            }),
                                                        )
                                                    }
                                                >
                                                    View Unbridge
                                                </button>
                                            )}
                                            {asyncItem.type ===
                                                AsyncItemTypes.REGISTRY_CREDITS_RECEIVED &&
                                                ![
                                                    AsyncItemStatuses.COMPLETE,
                                                    AsyncItemStatuses.IN_PROGRESS,
                                                    AsyncItemStatuses.PENDING,
                                                ].includes(asyncItem.status) && (
                                                    <button
                                                        className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px]"
                                                        onClick={() => {
                                                            setShowCancelBridgingModal(asyncItem)
                                                        }}
                                                    >
                                                        Cancel Bridging
                                                    </button>
                                                )}
                                        </td>
                                        <td className="flex-1 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            {asyncItem.created_at}
                                        </td>
                                        <td className="flex-1 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            {asyncItem.updated_at}
                                        </td>
                                        <td className="flex-1 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            {asyncItem.started_at}
                                        </td>
                                        <td className="flex-1 text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                            {asyncItem.error_code}
                                        </td>
                                        {filters.showJsonFields && (
                                            <td className="flex flex-row gap-2 items-start text-gray-500 text-sm text-left font-normal pl-6 align-top">
                                                <JSONPretty
                                                    data={asyncItem.error_msg}
                                                    space="4"
                                                    style={{ backgroundColor: '#eee' }}
                                                ></JSONPretty>
                                                {asyncItem.error_msg && (
                                                    <CopyButton
                                                        text={JSON.parse(asyncItem.error_msg)}
                                                    />
                                                )}
                                            </td>
                                        )}
                                    </tr>
                                )
                            },
                        )}
                    </tbody>
                </table>
                {!showSpinner && searchAsyncItemsResponse?.results.length === 0 && (
                    <p className="text-center mt-10">No results found</p>
                )}
                {searchAsyncItemsResponse && searchAsyncItemsResponse.results.length > 0 && (
                    <Pagination
                        currentPage={searchAsyncItemsResponse?.pagination?.current_page ?? 1}
                        totalPages={searchAsyncItemsResponse?.pagination?.total_pages ?? 1}
                        onPageChange={(page: number) => {
                            setFilters((prevFilters) => ({
                                ...prevFilters,
                                page,
                            }))
                        }}
                    />
                )}
            </div>
            <ConfirmationModal
                show={!!showApproveConfirmationModalForAsyncItem}
                onClose={() => setShowApproveConfirmationModalForAsyncItem(null)}
                onConfirm={async () => {
                    if (showApproveConfirmationModalForAsyncItem) {
                        await AsyncItemApi.retry(showApproveConfirmationModalForAsyncItem.id)
                        await fetchAsyncItems()
                        setShowApproveConfirmationModalForAsyncItem(null)
                    }
                }}
                question={
                    <div>
                        <p>Are you sure?</p>
                    </div>
                }
                confirmButtonText="OK"
                cancelButtonText="Cancel"
            />
            <ConfirmationModal
                show={!!showCancelBridgingModal}
                onClose={() => setShowCancelBridgingModal(null)}
                onConfirm={async () => {
                    if (showCancelBridgingModal) {
                        await BridgeCreditsApi.cancel(showCancelBridgingModal.id)
                        await fetchAsyncItems()
                        setShowCancelBridgingModal(null)
                    }
                }}
                question={
                    <div>
                        <p>Are you sure you want to cancel bridging?</p>
                    </div>
                }
                confirmButtonText="OK"
                cancelButtonText="No"
            />
            <ConfirmationModal
                show={!!showCreditsReturnedModalForAsyncItem}
                onClose={() => setShowCreditsReturnedModalForAsyncItem(null)}
                onConfirm={async () => {
                    if (showCreditsReturnedModalForAsyncItem) {
                        await AsyncItemApi.setCreditsReturned(
                            showCreditsReturnedModalForAsyncItem.id,
                        )
                        await fetchAsyncItems()
                        setShowCreditsReturnedModalForAsyncItem(null)
                    }
                }}
                question={
                    <div>
                        <p>Are you sure the credits have been returned on the registry?</p>
                    </div>
                }
                confirmButtonText="OK"
                cancelButtonText="Cancel"
            />
            <ConfirmationModal
                show={!!showSelectBlockchainConfirmationModalForAsyncItem}
                onClose={() => setShowSelectBlockchainConfirmationModalForAsyncItem(null)}
                onConfirm={async () => {
                    if (showSelectBlockchainConfirmationModalForAsyncItem) {
                        await AsyncItemApi.approveAndChooseBlockchain(
                            showSelectBlockchainConfirmationModalForAsyncItem.id,
                            selectedBlockchain,
                            selectedBeneficiaryTenantId && selectedBeneficiaryTenantId != 'Choose'
                                ? selectedBeneficiaryTenantId
                                : undefined,
                        )
                        setSelectedBlockchain(Blockchain.POLYGON)
                        setShowSelectBlockchainConfirmationModalForAsyncItem(null)
                        await fetchAsyncItems()
                    }
                }}
                question={
                    <div>
                        <p className="text-left">Select Blockchain</p>
                        <select
                            name="blockchain"
                            value={selectedBlockchain}
                            className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            onChange={(e) => setSelectedBlockchain(e.target.value as Blockchain)}
                        >
                            {selectBlockchains}
                        </select>
                        <p className="text-left">Beneficiary Tenant Id:</p>
                        <p className="text-left font-bold">{selectedBeneficiaryTenantId}</p>
                    </div>
                }
                confirmButtonText="OK"
                cancelButtonText="Cancel"
            />
            <ConfirmationModal
                show={!!showSelectExpectedBlockModal}
                onClose={() => setShowSelectExpectedBlockModal(null)}
                onConfirm={async () => {
                    if (!showSelectExpectedBlockModal || !selectedExpectedBlock) return
                    if (showSelectExpectedBlockModal) {
                        await AsyncItemApi.setExpectedBlock(
                            showSelectExpectedBlockModal.id,
                            selectedExpectedBlock,
                        )
                        setSelectedExpectedBlock(null)
                        setShowSelectExpectedBlockModal(null)
                        await fetchAsyncItems()
                    }
                }}
                question={
                    <div>
                        <p className="text-left">Select Expected Block</p>
                        <select
                            name="expected block"
                            value={selectedExpectedBlock || ''}
                            className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            onChange={(e) => {
                                setSelectedExpectedBlock(e.target.value || null)
                            }}
                        >
                            <option
                                value={''}
                                key="null"
                                className="text-gray-700 font-sm bg-gray-100"
                            >
                                None
                            </option>
                            {expectedBlocks.expectedBlocks.map(
                                (registrySection: OrderedExpectedBlocks) => {
                                    return registrySection.projects.map((project) => {
                                        return Object.entries(project.vintages).map(
                                            ([vintage, blocks]) => {
                                                const blockOptions = blocks.map((block) => {
                                                    return (
                                                        <option
                                                            value={block.id}
                                                            key={block.id}
                                                            className="text-gray-900 font-xs bg-gray-100 font-light"
                                                        >
                                                            Show ID: {block.show_id} // Quantity:{' '}
                                                            {block.expected_amount} // Tenant:{' '}
                                                            {block.tenant.entity_name}
                                                            {block.tenant_customer?.name
                                                                ? ` - ${
                                                                      block.tenant_customer!.name
                                                                  }`
                                                                : ''}
                                                        </option>
                                                    )
                                                })
                                                return (
                                                    <optgroup
                                                        className="text-md"
                                                        label={`${registrySection.registry}; ${project.name}; ${vintage}`}
                                                        key={`${project.name} ${vintage}`}
                                                    >
                                                        {blockOptions}
                                                    </optgroup>
                                                )
                                            },
                                        )
                                    })
                                },
                            )}
                        </select>
                        <Pagination
                            currentPage={expectedBlocks.pagination.current_page || 1}
                            totalPages={expectedBlocks.pagination.total_pages || 1}
                            onPageChange={(page: number) => {
                                void getExpectedBlocks(showSelectExpectedBlockModal!, page)
                            }}
                        />
                    </div>
                }
                confirmButtonText="OK"
                cancelButtonText="Cancel"
            />
            <ConfirmationModal
                show={!!showAreYouSureModalForAsyncItem}
                onClose={() => setShowAreYouSureModalForAsyncItem(null)}
                onConfirm={async () => {
                    if (showAreYouSureModalForAsyncItem) {
                        await AsyncItemApi.approve(showAreYouSureModalForAsyncItem.id)
                        await fetchAsyncItems()
                    }
                    setShowAreYouSureModalForAsyncItem(null)
                }}
            />
            <ConfirmationModal
                show={!!showSetTenantCustomerModal}
                onClose={() => setShowSetTenantCustomerModal(null)}
                onConfirm={async () => {
                    if (!showSetTenantCustomerModal || !selectedTenantCustomer) return
                    await approveOnboarding(showSetTenantCustomerModal)
                    setSelectedTenantCustomer(null)
                    setShowSetTenantCustomerModal(null)
                    await fetchAsyncItems()
                }}
                question={
                    <div>
                        <p className="text-left">Select Tenant Customer</p>
                        <select
                            name="tenant customer"
                            value={selectedTenantCustomer || ''}
                            className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            onChange={(e) => {
                                setSelectedTenantCustomer(e.target.value || null)
                            }}
                        >
                            <option
                                value={''}
                                key="null"
                                className="text-gray-700 font-sm bg-gray-100"
                            >
                                None
                            </option>
                            {Object.entries(tenantCutomsers.tenantCustomers).map(
                                ([tenant_id, customers]) => {
                                    const customerOptions = customers.map((customer) => {
                                        return (
                                            <option
                                                value={customer.id}
                                                key={customer.id}
                                                className="text-gray-900 font-xs bg-gray-100 font-light"
                                            >
                                                Name: {customer.name}
                                            </option>
                                        )
                                    })

                                    return (
                                        <optgroup
                                            className="text-md"
                                            label={`Tenant: ${tenant_id}`}
                                            key={tenant_id}
                                        >
                                            {customerOptions}
                                        </optgroup>
                                    )
                                },
                            )}
                        </select>
                        <Pagination
                            currentPage={tenantCutomsers.pagination.current_page || 1}
                            totalPages={tenantCutomsers.pagination.total_pages || 1}
                            onPageChange={(page: number) => {
                                void getTenantCustomers(page)
                            }}
                        />
                    </div>
                }
                confirmButtonText="OK"
                cancelButtonText="Cancel"
            />
            <JsonEditorModal
                show={!!showEditModalForAsyncItem}
                onClose={() => setShowEditModalForAsyncItem(null)}
                json={JSON.parse(showEditModalForAsyncItem?.json_data || '{}')}
                onConfirm={async (data) => {
                    if (showEditModalForAsyncItem) {
                        await AsyncItemApi.patch(showEditModalForAsyncItem?.id, {
                            json_data: JSON.stringify(data),
                        })
                        await fetchAsyncItems()
                        setShowEditModalForAsyncItem(null)
                    }
                }}
            />
        </Layout>
    )
}

export default AsyncItems
