import { ProjectType } from '../types/ProjectType'
import { SearchProjectParamsPayload } from '../types/SearchProjectParamsPayload'
import { SearchProjectsResponse } from '../types/SearchProjectsResponse'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface ProjectApiInterface {
    getProjectTypesByRegistryId(registryId: string): Promise<AxiosResponse<ProjectType[]>>
    searchProjects(
        params: SearchProjectParamsPayload,
    ): Promise<AxiosResponse<SearchProjectsResponse>>
}

export const ProjectApi: ProjectApiInterface = Object.freeze({
    getProjectTypesByRegistryId(registryId: string): Promise<AxiosResponse<ProjectType[]>> {
        return ApiService.get('/admin/project-types', registryId)
    },

    searchProjects(
        params: SearchProjectParamsPayload,
    ): Promise<AxiosResponse<SearchProjectsResponse>> {
        return ApiService.get('/admin/projects', null, params)
    },
})
