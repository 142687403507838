import { CopyButton } from '../CopyButton'
import { RetirementEventSelectBlocksPresentationItemProps } from './index'
import React, { ChangeEvent } from 'react'
import Select, { ActionMeta, SingleValue } from 'react-select'

const RetirementEventSelectListItem = ({
    index,
    block,
    getSelectOptions,
    onChangeSelectSerialInputHandler,
    getSelectFilterOption,
    onRemoveBlockButtonClickedHandler,
    onChangeAmountToRetireInputHandler,
    onBlurBlockInput,
    validationData,
}: RetirementEventSelectBlocksPresentationItemProps): JSX.Element => {
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => e.target.select()
    const currentErrorMessage = (fieldName: string) =>
        validationData?.errors.find((i) => i.fieldName === fieldName)?.message
    const currentWarningMessage = (fieldName: string) =>
        validationData?.warnings.find((i) => i.fieldName === fieldName)?.message

    // @ts-ignore
    return (
        <li
            key={block.id}
            className="mt-6 first:mt-0 px-[120px] py-[30px] border-gray-400 border-opacity-50 border-2 rounded-lg"
        >
            <div className="flex justify-end">
                <button
                    className="px-4 py-2.5 rounded-lg text-white bg-[#3e3d3d]"
                    onClick={() => onRemoveBlockButtonClickedHandler(index)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-6 h-6"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M6 18L18 6M6 6l12 12"
                        />
                    </svg>
                </button>
            </div>
            <div className="flex flex-col">
                <span className="text-left ml-4 mb-2">Block Serial:</span>
                <div className="w-full flex">
                    <CopyButton text={block.serial ?? ''}></CopyButton>
                    <Select
                        defaultValue={
                            /* eslint-disable indent */
                            block.serial !== ''
                                ? { label: block.serial, value: index }
                                : {
                                      label: 'Select',
                                      value: index,
                                  }
                        }
                        className="md:block w-[100%] ml-2 mx-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        backspaceRemovesValue={true}
                        isClearable={true}
                        components={{
                            DropdownIndicator: () => null,
                            IndicatorSeparator: () => null,
                        }}
                        styles={{
                            option: (defaultOptions, state) => ({
                                ...defaultOptions,
                                backgroundColor: state.isFocused ? 'rgb(178, 212, 255)' : 'white',
                                color: 'black',
                            }),
                        }}
                        options={getSelectOptions()}
                        onChange={(
                            opt: SingleValue<{ value: number; label: string }>,
                            action: ActionMeta<{ value: number; label: string }>,
                        ) => onChangeSelectSerialInputHandler(index, opt, action)}
                        filterOption={getSelectFilterOption}
                    ></Select>
                </div>
            </div>
            <div className="mt-4 ml-4 flex flex-start">
                Id: <span className="font-bold ml-5">{block.id}</span>
            </div>
            <div className="mt-4 ml-4 flex flex-start">
                Blockchain: <span className="font-bold ml-5">{block.blockchain}</span>
            </div>
            <div className="mt-4 ml-4 flex flex-start">
                Block Registry: <span className="font-bold ml-5">{block.registry}</span>
            </div>
            <div className="mt-4 ml-4 flex flex-start">
                Block Amount: <span className="font-bold ml-5">{block.amount}</span>
            </div>
            <div className="flex flex-col mt-4">
                <span className="text-left ml-4 mb-2">Retirement Amount For This Block:</span>
                <div className="w-full flex">
                    <input
                        type="number"
                        onFocus={handleFocus}
                        step={1}
                        min={1}
                        max={block.amount && Number(block.amount)}
                        value={block.retirementAmountForThisBlock}
                        className="md:block w-[100%] ml-2 mx-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 h-11"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeAmountToRetireInputHandler(
                                'retirementAmountForThisBlock',
                                Number(e.target.value.replace(/\s/g, '')),
                                index,
                            )
                        }
                        onBlur={() => onBlurBlockInput(index, 'retirementAmountForThisBlock')}
                    />
                </div>
                <div className="flex flex-col mt-4">
                    <span className="text-left ml-4 mb-2">Retired Block Serial:</span>
                    <div className="w-full flex">
                        <input
                            type="text"
                            value={block.retirementBlockSerial}
                            className={`"md:block w-[100%] ml-2 mx-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 h-11 ${
                                !!currentErrorMessage('retirementBlockSerial')
                                    ? 'border-red-300'
                                    : 'border-gray-300'
                            }`}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                onChangeAmountToRetireInputHandler(
                                    'retirementBlockSerial',
                                    e.target.value,
                                    index,
                                )
                            }
                            onBlur={() => onBlurBlockInput(index, 'retirementBlockSerial')}
                        />
                    </div>
                    {currentErrorMessage('retirementBlockSerial') && (
                        <span className="text-red-600 w-3/4 mx-auto text-center">
                            {currentErrorMessage('retirementBlockSerial')}
                        </span>
                    )}
                </div>
                <div className="flex flex-col mt-4">
                    <span className="text-left ml-4 mb-2">Remaining Block Serial:</span>
                    <div className="w-full flex">
                        <input
                            type="text"
                            value={block.remainingBlockSerial}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                onChangeAmountToRetireInputHandler(
                                    'remainingBlockSerial',
                                    e.target.value,
                                    index,
                                )
                            }
                            onBlur={() => onBlurBlockInput(index, 'remainingBlockSerial')}
                            className={`md:block w-[100%] ml-2 mx-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 ${
                                !!currentErrorMessage('remainingBlockSerial') && 'border-red-300'
                            } ${
                                !!currentWarningMessage('remainingBlockSerial')
                                    ? 'border-orange-400'
                                    : 'border-gray-300'
                            }`}
                        />
                    </div>
                    {currentErrorMessage('remainingBlockSerial') && (
                        <span className="text-red-600 w-[200px] mx-auto text-center">
                            {currentErrorMessage('remainingBlockSerial')}
                        </span>
                    )}
                    {currentWarningMessage('remainingBlockSerial') && (
                        <span className="text-primary-600 w-[200px] mx-auto text-center">
                            {currentWarningMessage('remainingBlockSerial')}
                        </span>
                    )}
                </div>
            </div>
        </li>
    )
}

export default RetirementEventSelectListItem
