import { ProjectApi } from '../../api/project'
import { RetirementRequestApi } from '../../api/retirementRequest'
import { TenantApi } from '../../api/tenant'
import { Page } from '../../enums/Page'
import { ErrorResponse } from '../../types/ErrorResponse'
import { ProjectType } from '../../types/ProjectType'
import { Tenant } from '../../types/Tenant'
import { generateUniqueId } from '../../utils/generateUniqueId'
import ErrorLabel from '../ErrorLabel'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'

export interface RetireFormData {
    tenant_id: string
    vintage_id: string
    amount: string
    retiree: string
    taxIdOverride: string
    externalRequestId: string
    externalCustomerId: string | null
}

const RetireForm = (): JSX.Element => {
    const [tenants, setTenants] = useState<Tenant[]>([])
    const [projects, setProjects] = useState<ProjectType[]>([])
    const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [tenantId, setTenantId] = useState<string | undefined>(undefined)
    const [tenant, setTenant] = useState<Tenant | undefined>(undefined)
    const [selectedOption, setSelectedOption] = useState<
        'selectProjectAndVintage' | 'pasteVintageId'
    >('selectProjectAndVintage')

    const navigate = useNavigate()

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<RetireFormData>({
        defaultValues: {
            externalRequestId: generateUniqueId(),
        },
    })

    const onSubmit = async (data: RetireFormData): Promise<void> => {
        try {
            await RetirementRequestApi.save({
                tenant_id: data.tenant_id,
                vintage_id: data.vintage_id,
                external_id: data.externalRequestId,
                quantity: data.amount,
                retiree: data.retiree,
                tax_id_override: data.taxIdOverride,
                external_customer_id: data.externalCustomerId || null,
            })
            setError(null)
            navigate(Page.ASYNC_ITEMS)
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            }
        }
    }

    const fetchProjects = async (name = '') => {
        const response = await ProjectApi.searchProjects({
            project_name: name,
            tenant_id: tenantId,
        })
        setProjects(response.data.results)
    }
    const fetchDevelopers = async () => {
        const response = await TenantApi.getDevelopers()
        setTenants(response.data)
    }

    useEffect(() => {
        if (selectedProject) {
            const project = projects.find((project) => project.id === selectedProject.id) || null
            setSelectedProject(project)
        }
    }, [projects])

    useEffect(() => {
        fetchDevelopers()
    }, [])

    useEffect(() => {
        if (!tenantId) {
            setProjects([])
            setTenant(undefined)
            return
        }
        fetchProjects()
        const tenant = tenants.find((tenant) => tenant.id === tenantId)!
        setTenant(tenant)
    }, [tenantId])

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'tenant_id' && value.tenant_id) {
                setTenantId(value.tenant_id)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    const filterOptions = (name: string): { value: string; label: string }[] => {
        return projects
            ?.filter((project) => project.name.toLowerCase().includes(name.toLowerCase()))
            .map((project) => {
                return {
                    value: project.id,
                    label: project.name,
                }
            })
    }

    const loadOptions = (
        inputValue: string,
        callback: (options: { value: string; label: string }[]) => void,
    ) => {
        callback(filterOptions(inputValue))
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 text-left">
                    <label className="text-sm font-medium text-gray-900">Retiree Tenant</label>
                    <select
                        className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        {...register('tenant_id', {
                            required: true,
                            validate: (tenantId: string) => {
                                return tenantId !== 'Choose'
                            },
                        })}
                    >
                        <option>Choose</option>
                        {tenants.map((tenant) => (
                            <option key={tenant.id} value={tenant.id}>
                                {tenant.id} - {tenant.entity_name}
                            </option>
                        ))}
                    </select>
                    <div className="h-4">
                        {errors?.tenant_id?.type === 'validate' && (
                            <ErrorLabel error="Tenant is required" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex gap-5 my-4">
                <button
                    type="button"
                    className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                        selectedOption === 'selectProjectAndVintage'
                            ? 'bg-primary-600'
                            : 'bg-primary-100'
                    }`}
                    onClick={() => setSelectedOption('selectProjectAndVintage')}
                >
                    Select Project and Vintage
                </button>
                <button
                    type="button"
                    className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                        selectedOption === 'pasteVintageId' ? 'bg-primary-600' : 'bg-primary-100'
                    }`}
                    onClick={() => setSelectedOption('pasteVintageId')}
                >
                    Paste Vintage ID
                </button>
            </div>
            {selectedOption === 'selectProjectAndVintage' && (
                <>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 text-left mb-6">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Select Project
                            </label>
                            <AsyncSelect
                                loadOptions={loadOptions}
                                onInputChange={fetchProjects}
                                defaultOptions={projects.map((project) => {
                                    return {
                                        value: project.id,
                                        label: project.name,
                                    }
                                })}
                                isLoading={false}
                                isSearchable={true}
                                onChange={(
                                    value: SingleValue<{ value: string; label: string }>,
                                ) => {
                                    const project = projects.find(
                                        (project) => project.id === value?.value,
                                    )
                                    if (project) {
                                        setSelectedProject(project)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 text-left">
                            <label className="text-sm font-medium text-gray-900">
                                Select Vintage
                            </label>
                            <select
                                className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                {...register('vintage_id', {
                                    required: true,
                                    validate: (vintage: string) => {
                                        return vintage !== ''
                                    },
                                })}
                            >
                                <option value="">Choose</option>
                                {selectedProject?.vintages.map((vintage) => (
                                    <option key={vintage.id} value={vintage.id}>
                                        {vintage.vintage}
                                    </option>
                                ))}
                            </select>
                            <div className="h-4">
                                {errors?.vintage_id?.type === 'required' && (
                                    <ErrorLabel error="Vintage is required" />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {selectedOption === 'pasteVintageId' && (
                <div className="flex flex-row gap-4 justify-between">
                    <div className="w-1/2 mb-2 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">Vintage id</label>
                        <input
                            type="text"
                            placeholder="Vintage Id..."
                            {...register('vintage_id', {
                                required: true,
                                setValueAs: (v) => v.replace(/\s/g, ''),
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-4">
                            {errors?.vintage_id?.type === 'required' && (
                                <ErrorLabel error="Vintage is required" />
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-2 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">Amount</label>
                    <input
                        type="text"
                        placeholder="Amount..."
                        {...register('amount', {
                            required: true,
                            setValueAs: (v) => v.replace(/\s/g, ''),
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="h-4">
                        {errors?.amount?.type === 'required' && (
                            <ErrorLabel error="Amount is required" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-2 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Retiree (optional)
                    </label>
                    <input
                        type="text"
                        placeholder="Retiree..."
                        {...register('retiree', {
                            required: false,
                            maxLength: 50,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="h-4">
                        {errors?.retiree?.type === 'maxLength' && (
                            <ErrorLabel error="Retiree name is too long" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="mb-6 text-left w-1/2">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Tax ID (read only)
                    </label>
                    <input
                        disabled={true}
                        value={tenant?.tax_id || ''}
                        className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    ></input>
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-2 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Tax ID Override (optional)
                    </label>
                    <input
                        type="text"
                        placeholder="Tax ID Override..."
                        {...register('taxIdOverride', {
                            required: false,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="h-4">
                        {errors?.retiree?.type === 'maxLength' && (
                            <ErrorLabel error="Retiree name is too long" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-2 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        External Customer ID (optional)
                    </label>
                    <input
                        type="text"
                        placeholder="External Customer ID..."
                        {...register('externalCustomerId', {
                            required: false,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="h-4">
                        {errors?.externalCustomerId?.type === 'maxLength' && (
                            <ErrorLabel error="Customer ID is too long" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="mb-6 text-left w-1/2">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        External ID (Enter ID from exchange if retiring from exchange)
                    </label>
                    <input
                        type="text"
                        {...register('externalRequestId', {
                            required: true,
                        })}
                        className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="h-4">
                        {errors?.externalRequestId?.type === 'required' && (
                            <ErrorLabel error="External id is required" />
                        )}
                    </div>
                </div>
            </div>
            <div className="h-4 text-left">{error && <ErrorLabel error={error} />}</div>
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                <button
                    data-modal-toggle="defaultModal"
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save
                </button>
            </div>
        </form>
    )
}

export default RetireForm
