import { XRPLApi } from '../api/xrpl'
import { Tenant } from '../types/Tenant'
import { XRPLAccountSetParams } from '../types/xrplAccountSetParams'
import { generateUniqueId } from '../utils/generateUniqueId'
import ConfirmationModal from './ConfirmationModal'
import React from 'react'

interface PerformAccountSetModalProps {
    tenant: Tenant | null
    onUpdate: () => void
    onClose: () => void
}
const PerformAccountSetModal = ({
    tenant,
    onUpdate,
    onClose,
}: PerformAccountSetModalProps): JSX.Element => {
    const onConfirm = async (): Promise<void> => {
        if (tenant) {
            const payload: XRPLAccountSetParams = {
                external_id: generateUniqueId(),
                tenant_id: tenant.id,
            }
            await XRPLApi.performAccountSet(payload)
            onUpdate()
        }
    }

    return (
        <ConfirmationModal
            show={!!tenant}
            onClose={onClose}
            onConfirm={onConfirm}
            confirmButtonText="Confirm"
            question={
                <div className="p-6 flex flex-col">
                    <p className="mb-5 text-lg font-normal text-gray-500">
                        Perform XRPL account set for tenant: {tenant?.entity_name}
                    </p>
                </div>
            }
        />
    )
}

export default PerformAccountSetModal
