import Layout from '../components/Layout'
import EditWebhookForm from '../components/forms/EditWebhookForm'

const EditWebhook = (): JSX.Element => {
    return (
        <Layout>
            <EditWebhookForm />
        </Layout>
    )
}

export default EditWebhook
