import Layout from '../components/Layout'
import RetireForm from '../components/forms/RetireForm'

const Retire = (): JSX.Element => {
    return (
        <Layout>
            <RetireForm />
        </Layout>
    )
}

export default Retire
