export interface ErrorLabelProps {
    error: string
    className?: string
}

const ErrorLabel = ({ error, className = '' }: ErrorLabelProps) => {
    return <p className={`text-xs text-red-500 ${className}`}>{error}</p>
}

export default ErrorLabel
