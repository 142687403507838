import RetirementEventSelectTableRow from './RetirementEventSelectTableRow'
import { RetirementEventSelectBlocksPresentationProps } from './index'

const RetirementEventSelectTable = ({
    blocks,
    getSelectOptions,
    onChangeSelectSerialInputHandler,
    getSelectFilterOptions,
    onRemoveBlockButtonClickedHandler,
    onChangeAmountToRetireInputHandler,
    onBlurBlockInput,
    validationData,
}: RetirementEventSelectBlocksPresentationProps): JSX.Element => {
    const getValidationDataForRow = (index: number) => {
        return validationData.find((item) => item.index === index)
    }

    return (
        <table
            id="serial-table"
            className="overflow-x-scroll md:overflow-auto block md:table md:table-auto w-full"
        >
            <thead>
                <tr className="h-11 items-center bg-[#3e3d3d] text-white px-4 divide-x-[1px] divide-x-stone-300">
                    <th className="w-[250px] font-semibold text-xs text-center px-2">
                        Block Serial
                    </th>
                    <th className="min-w-[150px] font-semibold text-xs text-center px-2">Id</th>
                    <th className="min-w-[150px] font-semibold text-xs text-center px-2">
                        Block Registry
                    </th>
                    <th className="min-w-[150px] font-semibold text-xs text-center px-2">
                        Block Amount
                    </th>
                    <th className="min-w-[200px] font-semibold text-xs text-center px-2">
                        Retirement Amount For This Block
                    </th>
                    <th className="min-w-[240px] font-semibold text-xs text-center px-2">
                        Retired Block Serial
                    </th>
                    <th className="min-w-[240px] font-semibold text-xs text-center px-2">
                        Remaining Block Serial
                    </th>
                    <th className="font-semibold text-xs text-center px-2">Blockchain</th>
                    <th className="font-semibold text-xs text-center px-2">Actions</th>
                </tr>
            </thead>
            <tbody>
                {blocks.map((block, index: number) => (
                    <RetirementEventSelectTableRow
                        block={block}
                        index={index}
                        key={index}
                        getSelectOptions={getSelectOptions}
                        onChangeSelectSerialInputHandler={onChangeSelectSerialInputHandler}
                        getSelectFilterOption={getSelectFilterOptions}
                        onRemoveBlockButtonClickedHandler={onRemoveBlockButtonClickedHandler}
                        onChangeAmountToRetireInputHandler={onChangeAmountToRetireInputHandler}
                        onBlurBlockInput={onBlurBlockInput}
                        validationData={getValidationDataForRow(index) ?? null}
                    />
                ))}
            </tbody>
        </table>
    )
}

export default RetirementEventSelectTable
