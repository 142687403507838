/* eslint-disable indent */
import { UserApi } from '../api/user'
import ApiKeysModal from '../components/ApiKeysModal'
import Layout from '../components/Layout'
import ManageRolesModal from '../components/ManageRolesModal'
import PerformAccountSetModal from '../components/PerformAccountSetModal'
import Roles from '../components/Roles'
import { ToggleIsUserActive } from '../components/ToggleIsUserActive'
import { Page } from '../enums/Page'
import { AdminSearchUsersParams } from '../types/AdminSearchUsersParams'
import { SearchUsersResponse } from '../types/SearchUsersResponse'
import { Tenant } from '../types/Tenant'
import { User } from '../types/User'
import { Pagination } from 'flowbite-react'
import React, { useEffect, useRef, useState } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

const Users = (): JSX.Element => {
    const [searchResponse, setSearchResponse] = useState<SearchUsersResponse | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(false)
    const [showApiKeysManagementModal, setShowApiKeysManagementModal] = useState(false)
    const [selectedUserId, setSelectedUserId] = useState<null | string>(null)
    const [showManageRolesModalForUser, setShowManageRolesModalForUser] = useState<User | null>(
        null,
    )
    const [showPerformAccountSetModalForUser, setShowPerformAccountSetModalForUser] =
        useState<Tenant | null>(null)
    const intervalRef = useRef<any>(null)
    const navigate = useNavigate()

    const hasExchangeUser = async (tenantId: string): Promise<boolean> => {
        const res = await UserApi.hasExchangeUser(tenantId)
        return res.data
    }

    const fetchUsers = async (params: AdminSearchUsersParams) => {
        const response = await UserApi.getAllUsers(params)
        const tenantsWithExchangeProm = response.data?.results.map(async (tenant) => {
            const res = await hasExchangeUser(tenant.id)
            res ? (tenant.has_exchange_user = true) : null
            return tenant
        })
        await Promise.all(tenantsWithExchangeProm).then((tenantsWithExchange) =>
            setSearchResponse({
                results: tenantsWithExchange,
                pagination: response.data?.pagination,
            }),
        )
    }

    useEffect(() => {
        intervalRef.current = setTimeout(() => {
            void fetchUsers({
                page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
            })
        }, 0)
        return () => clearTimeout(intervalRef.current)
    }, [searchParams])

    const setIsUserActive = async (userId: string, isUserActive: boolean) => {
        setIsLoading(true)
        await UserApi.setIsUserActive(userId, isUserActive)
        await fetchUsers({
            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
        })
        setIsLoading(false)
    }

    const onManageApiKeysButtonClicked = (userId: string) => {
        setShowApiKeysManagementModal(true)
        setSelectedUserId(userId)
    }
    const closeApiKeysManagementModal = () => {
        setShowApiKeysManagementModal(false)
        setSelectedUserId(null)
    }

    return (
        <Layout>
            <div className="overflow-x-auto mt-2 mb-6">
                <button
                    className="px-4 py-2 flex justify-center items-center border border-black rounded-lg"
                    onClick={() => navigate(Page.CREATE_USER)}
                >
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="w-4 h-4"
                        viewBox="0 0 448 512"
                    >
                        <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                    </svg>
                    <p className="inline-block ml-2">Add User</p>
                </button>
                <table className="w-full mt-6">
                    <thead>
                        <tr className="h-12 flex items-center w-full bg-gray-100 rounded-md">
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                TENANT ID
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                ENTITY NAME
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                TENANT TYPE
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                REGISTRY ID
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                TAX ID
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                ACTIONS
                            </th>
                        </tr>
                    </thead>
                    {searchResponse?.results.map((tenant) => (
                        <tbody
                            key={tenant.id}
                            className="bg-gray-300 rounded-lg block mt-3 first-of-type:mt-1"
                        >
                            <tr className="h-14 flex items-center w-full" key={tenant.id}>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {tenant.id}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {tenant.entity_name ?? '-'}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {tenant.type}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {tenant.registry_id ?? '-'}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    {tenant.tax_id ?? '-'}
                                </td>
                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                    <div className="flex flex-row">
                                        <div className="flex-1 items-center">
                                            <img
                                                className="cursor-pointer"
                                                src="/assets/icon_edit.svg"
                                                alt="edit"
                                                onClick={() =>
                                                    navigate(
                                                        generatePath(Page.UPDATE_TENANT, {
                                                            id: tenant.id,
                                                        }),
                                                    )
                                                }
                                            />
                                        </div>

                                        {tenant.has_exchange_user ? (
                                            <button
                                                className="bg-[#CD7F32] text-sm ml-4 mr-2 p-2 rounded-md text-white min-w-[80px]"
                                                onClick={() =>
                                                    navigate(
                                                        generatePath(Page.MANAGE_TENANT_CUSTOMERS, {
                                                            id: tenant.id,
                                                        }),
                                                    )
                                                }
                                            >
                                                Manage Tenant Customers
                                            </button>
                                        ) : (
                                            <></>
                                        )}

                                        {!!tenant.xrpl_address &&
                                            !tenant.xrpl_account_set_applied && (
                                                <div className="flex-1 items-center my-auto">
                                                    <img
                                                        className="cursor-pointer"
                                                        src="/assets/xrpl_logo.png"
                                                        alt="XRPL account set"
                                                        width={24}
                                                        onClick={() =>
                                                            setShowPerformAccountSetModalForUser(
                                                                tenant,
                                                            )
                                                        }
                                                    />
                                                </div>
                                            )}
                                    </div>
                                </td>
                            </tr>
                            <tr className="flex items-center w-full p-3">
                                <td className="w-[100%]">
                                    <table className="bg-gray-200 rounded-lg w-full">
                                        <thead>
                                            <tr className="h-11 flex items-center w-full bg-gray-50">
                                                <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                    ID
                                                </th>
                                                <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                    NAME
                                                </th>
                                                <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                    IS ACTIVE
                                                </th>
                                                <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                    CREATED AT
                                                </th>
                                                <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                    UPDATED AT
                                                </th>
                                                <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                    API KEYS
                                                </th>
                                                <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                    ROLES
                                                </th>
                                                <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                                    ACTIONS
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {tenant.users?.map((user) => (
                                                <tr
                                                    className="h-18 flex items-center w-full bg-gray-100 rounded-md mt-1"
                                                    key={Math.random().toString()}
                                                >
                                                    <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                        {user.id}
                                                    </td>
                                                    <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                        {user.first_name} {user.last_name}
                                                    </td>
                                                    <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                        <ToggleIsUserActive
                                                            disable={
                                                                isLoading || !!user?.is_current_user
                                                            }
                                                            user={user}
                                                            onChange={setIsUserActive}
                                                        />
                                                    </td>
                                                    <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                        {user.created_at}
                                                    </td>
                                                    <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                        {user.updated_at}
                                                    </td>
                                                    <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                        <button
                                                            className="bg-[#3e3d3d] p-2.5 rounded-md text-white min-w-[100px]"
                                                            onClick={() =>
                                                                onManageApiKeysButtonClicked(
                                                                    user.id,
                                                                )
                                                            }
                                                        >
                                                            Manage
                                                        </button>
                                                    </td>
                                                    <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                        <div className="flex flex-row">
                                                            <Roles user={user} />
                                                            <button
                                                                onClick={() => {
                                                                    setShowManageRolesModalForUser(
                                                                        user,
                                                                    )
                                                                }}
                                                            >
                                                                <svg
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                    fill="none"
                                                                    viewBox="0 0 24 24"
                                                                    strokeWidth={1.5}
                                                                    stroke="currentColor"
                                                                    className="w-6 h-6"
                                                                >
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
                                                                    />
                                                                    <path
                                                                        strokeLinecap="round"
                                                                        strokeLinejoin="round"
                                                                        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                                                    />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="flex-1 flex pl-6 break-words text-gray-500 font-normal">
                                                        <img
                                                            className="cursor-pointer"
                                                            src="/assets/icon_edit.svg"
                                                            alt="edit"
                                                            onClick={() =>
                                                                navigate(
                                                                    generatePath(Page.UPDATE_USER, {
                                                                        id: user.id,
                                                                    }),
                                                                )
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    ))}
                </table>
                {searchResponse?.pagination && (
                    <div className="mt-4">
                        <Pagination
                            currentPage={searchResponse.pagination.current_page}
                            totalPages={searchResponse.pagination.total_pages}
                            onPageChange={(pageNumber) =>
                                setSearchParams({ page: String(pageNumber) })
                            }
                        />
                    </div>
                )}
                {selectedUserId && (
                    <ApiKeysModal
                        show={showApiKeysManagementModal && !!selectedUserId}
                        currentUserId={selectedUserId}
                        onClose={closeApiKeysManagementModal}
                    />
                )}
                <ManageRolesModal
                    user={showManageRolesModalForUser}
                    onUpdate={async () => {
                        await fetchUsers({
                            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                        })
                        setShowManageRolesModalForUser(null)
                    }}
                    onClose={() => setShowManageRolesModalForUser(null)}
                />
                <PerformAccountSetModal
                    tenant={showPerformAccountSetModalForUser}
                    onUpdate={async () => {
                        await fetchUsers({
                            page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                        })
                        setShowPerformAccountSetModalForUser(null)
                    }}
                    onClose={() => setShowPerformAccountSetModalForUser(null)}
                />
            </div>
        </Layout>
    )
}

export default Users
