import { getDecimalSeparator } from '../utils/getDecimalSeparator'
import { IMaskInput } from 'react-imask'

interface NumberInputProps {
    onChange: (value: number | string) => void
    min?: number
    max?: number
    initialValue?: string
    scale?: number
    placeholder?: string
}

const NumberInput = ({
    onChange,
    min,
    max,
    initialValue,
    scale = 2,
    placeholder = '0.00',
}: NumberInputProps): JSX.Element => {
    const configBlocks = {
        thisInput: {
            mask: Number,
            radix: getDecimalSeparator(),
            scale,
            signed: true,
            max,
            min,
            thousandsSeparator: '',
            padFractionalZeros: false,
            normalizeZeros: false,
            unmask: true,
        },
    }

    return (
        <div className="number-input w-full">
            {initialValue ? (
                <IMaskInput
                    blocks={configBlocks}
                    mask="thisInput"
                    onAccept={(value: any) => {
                        onChange(value as string)
                    }}
                    value={initialValue}
                    // @ts-ignore-next-line
                    placeholder={placeholder}
                />
            ) : (
                <IMaskInput
                    blocks={configBlocks}
                    mask="thisInput"
                    onAccept={(value) => {
                        onChange(value as string)
                    }}
                    // @ts-ignore-next-line
                    placeholder={placeholder}
                />
            )}
        </div>
    )
}

export default NumberInput
