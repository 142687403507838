import { Page } from '../enums/Page'
import { CookieKey } from '../storage/enums/CookieKey'
import { deleteCookie } from '../storage/useCookie'
import { Link, useNavigate } from 'react-router-dom'

const NavBar = (): JSX.Element => {
    const navigate = useNavigate()
    return (
        <nav className="w-full flex flex-row justify-between">
            <div className="flex flex-row divide-x-[1px] divide-x-white">
                <div className="px-2 md:px-5">
                    <Link
                        to={Page.ASYNC_ITEMS}
                        className={`pb-2 text-white text-xl cursor-pointer ${
                            window.location.pathname === '/' ? 'border-b border-b-[#91552f]' : null
                        }`}
                    >
                        Async Items
                    </Link>
                </div>
                <div className="px-2 md:px-5">
                    <Link
                        to={Page.BRIDGE}
                        className={`pb-2 text-white text-xl cursor-pointer ${
                            window.location.pathname === Page.BRIDGE
                                ? 'border-b border-b-[#91552f]'
                                : null
                        }`}
                    >
                        Bridge
                    </Link>
                </div>
                <div className="px-2 md:px-5">
                    <Link
                        to={Page.RETIREMENT_REQUESTS}
                        className={`pb-2 text-white text-xl cursor-pointer ${
                            window.location.pathname === Page.RETIREMENT_REQUESTS
                                ? 'border-b border-b-[#91552f]'
                                : null
                        }`}
                    >
                        Retirement Requests
                    </Link>
                </div>
                <div className="px-2 md:px-5">
                    <Link
                        to={Page.RETIRE}
                        className={`pb-2 text-white text-xl cursor-pointer ${
                            window.location.pathname === Page.RETIRE
                                ? 'border-b border-b-[#91552f]'
                                : null
                        }`}
                    >
                        Retire
                    </Link>
                </div>
                <div className="px-2 md:px-5">
                    <Link
                        to={Page.UNBRIDGE_REQUESTS}
                        className={`pb-2 text-white text-xl cursor-pointer ${
                            window.location.pathname === Page.UNBRIDGE_REQUESTS
                                ? 'border-b border-b-[#91552f]'
                                : null
                        }`}
                    >
                        Unbridge requests
                    </Link>
                </div>
                <div className="px-2 md:px-5">
                    <Link
                        to={Page.CREATE_UNBRIDGE_REQUEST}
                        className={`pb-2 text-white text-xl cursor-pointer ${
                            window.location.pathname === Page.CREATE_UNBRIDGE_REQUEST
                                ? 'border-b border-b-[#91552f]'
                                : null
                        }`}
                    >
                        Unbridge
                    </Link>
                </div>
                <div className="px-5 border-r border-r-white">
                    <Link
                        to={Page.USERS}
                        className={`pb-2 text-white text-xl cursor-pointer ${
                            window.location.pathname === Page.USERS
                                ? 'border-b border-b-[#91552f]'
                                : null
                        }`}
                    >
                        Users
                    </Link>
                </div>
                <div className="px-5">
                    <Link
                        to={Page.EXPECTED_BLOCKS}
                        className={`pb-2 text-white text-xl cursor-pointer ${
                            window.location.pathname === Page.EXPECTED_BLOCKS
                                ? 'border-b border-b-[#91552f]'
                                : null
                        }`}
                    >
                        Expected Blocks
                    </Link>
                </div>
                <div className="px-2 md:px-5">
                    <Link
                        to={Page.WEBHOOKS}
                        className={`pb-2 text-white text-xl cursor-pointer ${
                            window.location.pathname === Page.WEBHOOKS
                                ? 'border-b border-b-[#91552f]'
                                : null
                        }`}
                    >
                        Webhooks
                    </Link>
                </div>
            </div>
            <div className="px-2 md:px-5">
                <button
                    className="pb-2 text-white text-xl cursor-pointer"
                    onClick={() => {
                        deleteCookie(CookieKey.API_KEY)
                        deleteCookie(CookieKey.API_URL)
                        navigate(Page.SIGN_IN)
                    }}
                >
                    Log out
                </button>
            </div>
        </nav>
    )
}

export default NavBar
