import Layout from '../components/Layout'
import CreateProjectForm from '../components/forms/CreateProjectForm'

const Bridge = (): JSX.Element => {
    return (
        <Layout>
            <CreateProjectForm />
        </Layout>
    )
}

export default Bridge
