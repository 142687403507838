import { CookieKey } from '../storage/enums/CookieKey'
import { getCookie } from '../storage/useCookie'

const ApiURL = (): JSX.Element => {
    const appUrl = getCookie(CookieKey.API_URL)
    const productionApiUrl = 'https://bridge.api.thallo.io/'
    const text = appUrl === productionApiUrl ? `Prod (${appUrl})` : appUrl
    return (
        <div className={`h-7 ${appUrl === productionApiUrl ? 'bg-[#f70238]' : 'bg-gray-800'}`}>
            <p className="text-xl text-white text-left pl-4">{text}</p>
        </div>
    )
}

export default ApiURL
