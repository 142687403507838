import { ProjectApi } from '../../api/project'
import { TenantApi } from '../../api/tenant'
import { TenantCustomerApi } from '../../api/tenantCustomer'
import { TenantCustomerRegistryIdApi } from '../../api/tenantCustomerRegistryId'
import { UnbridgeRequestApi } from '../../api/unbridgeRequest'
import { VintageApi } from '../../api/vintage'
import { Page } from '../../enums/Page'
import { ErrorResponse } from '../../types/ErrorResponse'
import { ProjectType } from '../../types/ProjectType'
import { Tenant } from '../../types/Tenant'
import { TenantCustomer } from '../../types/TenantCustomer'
import { generateUniqueId } from '../../utils/generateUniqueId'
import ErrorLabel from '../ErrorLabel'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'

export interface CreateUnbridgeRequestFormData {
    quantity: string
    tenant_id: string
    vintage_id: string
    destination_registry_customer_id: string
    external_request_id: string
}

const CreateUnbridgeRequestForm = (): JSX.Element => {
    const [tenants, setTenants] = useState<Tenant[]>([])
    const [tenantCustomers, setTenantCustomers] = useState<TenantCustomer[]>([])
    const [projects, setProjects] = useState<ProjectType[]>([])
    const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null)
    const [error, setError] = useState<string | null>(null)
    const [tenantId, setTenantId] = useState<string | undefined>(undefined)
    const [tenantCustomerId, setTenantCustomerId] = useState<string>('')
    const [selectedOption, setSelectedOption] = useState<
        'selectProjectAndVintage' | 'pasteVintageId'
    >('selectProjectAndVintage')
    const navigate = useNavigate()
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
    } = useForm<CreateUnbridgeRequestFormData>({
        defaultValues: {
            external_request_id: generateUniqueId(),
        },
    })
    const watchVintageId = watch('vintage_id', '')

    const fetchProjects = async (name = '') => {
        const response = await ProjectApi.searchProjects({
            project_name: name,
            tenant_id: tenantId,
        })
        setProjects(response.data.results)
    }

    const fetchDevelopers = async () => {
        const response = await TenantApi.getDevelopers()
        setTenants(response.data)
    }
    const fetchTenantCustomers = async () => {
        if (tenantId) {
            const response = await TenantCustomerApi.getTenantCustomersByTenantId(tenantId)
            setTenantCustomers(response.data.tenant_customers)
            setTenantCustomerId('')
        }
    }
    const fetchTenantCustomerRegistryId = async () => {
        if (selectedProject?.registry_id && selectedOption === 'selectProjectAndVintage') {
            const response = await TenantCustomerRegistryIdApi.getByTenantCustomerIdAndRegistryId(
                tenantCustomerId,
                selectedProject.registry_id,
            )
            setValue('destination_registry_customer_id', response.data.registry_user_id)
        } else if (watchVintageId && selectedOption === 'pasteVintageId') {
            const vintageResponse = await VintageApi.getById(watchVintageId)
            if (!vintageResponse.data.id) {
                setValue('destination_registry_customer_id', '')
                return
            }
            const regId = vintageResponse.data.project.registry_id
            const response = await TenantCustomerRegistryIdApi.getByTenantCustomerIdAndRegistryId(
                tenantCustomerId,
                regId,
            )
            setValue('destination_registry_customer_id', response.data.registry_user_id)
        } else {
            setValue('destination_registry_customer_id', '')
        }
    }

    useEffect(() => {
        fetchDevelopers()
    }, [])

    useEffect(() => {
        if (selectedProject) {
            const project = projects.find((project) => project.id === selectedProject.id) || null
            setSelectedProject(project)
        }
    }, [projects])

    useEffect(() => {
        if (!tenantId) {
            setProjects([])
            return
        }
        void fetchProjects()
        void fetchTenantCustomers()
    }, [tenantId])

    useEffect(() => {
        const subscription = watch((value, { name }) => {
            if (name === 'tenant_id' && value.tenant_id) {
                setTenantId(value.tenant_id)
            }
        })
        return () => subscription.unsubscribe()
    }, [watch])

    useEffect(() => {
        if (tenantCustomerId) {
            void fetchTenantCustomerRegistryId()
        } else {
            setValue('destination_registry_customer_id', '')
        }
    }, [tenantCustomerId, selectedProject, watchVintageId])

    const filterOptions = (name: string): { value: string; label: string }[] => {
        return projects
            ?.filter((project) => project.name.toLowerCase().includes(name.toLowerCase()))
            .map((project) => {
                return {
                    value: project.id,
                    label: project.name,
                }
            })
    }

    const loadOptions = (
        inputValue: string,
        callback: (options: { value: string; label: string }[]) => void,
    ) => {
        callback(filterOptions(inputValue))
    }

    const onSubmit = async (data: CreateUnbridgeRequestFormData) => {
        try {
            await UnbridgeRequestApi.saveUnbridgeRequest({
                external_id: data.external_request_id,
                quantity: data.quantity,
                tenant_id: data.tenant_id,
                vintage_id: data.vintage_id,
                destination_registry_customer_id: data.destination_registry_customer_id,
            })
            setError(null)
            navigate(Page.ASYNC_ITEMS)
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            }
        }
    }

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex gap-5 my-4">
                <button
                    type="button"
                    className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                        selectedOption === 'selectProjectAndVintage'
                            ? 'bg-primary-600'
                            : 'bg-primary-100'
                    }`}
                    onClick={() => setSelectedOption('selectProjectAndVintage')}
                >
                    Select Project and Vintage
                </button>
                <button
                    type="button"
                    className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                        selectedOption === 'pasteVintageId' ? 'bg-primary-600' : 'bg-primary-100'
                    }`}
                    onClick={() => {
                        setSelectedOption('pasteVintageId')
                        setValue('vintage_id', '')
                    }}
                >
                    Paste Vintage ID
                </button>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 text-left">
                    <label className="text-sm font-medium text-gray-900">Unbridge Tenant</label>
                    <select
                        className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        {...register('tenant_id', {
                            required: true,
                            validate: (tenantId: string) => {
                                return tenantId !== 'Choose'
                            },
                        })}
                    >
                        <option>Choose</option>
                        {tenants.map((tenant) => (
                            <option key={tenant.id} value={tenant.id}>
                                {tenant.id} - {tenant.entity_name}
                            </option>
                        ))}
                    </select>
                    <div className="h-4">
                        {errors?.tenant_id?.type === 'validate' && (
                            <ErrorLabel error="Tenant is required" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 text-left">
                    <label className="text-sm font-medium text-gray-900">
                        Unbridge Tenant Customer
                    </label>
                    <select
                        className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        onChange={(e) => {
                            setTenantCustomerId(e.target.value)
                        }}
                    >
                        <option>Choose</option>
                        {tenantCustomers.map((tenantCustomer) => (
                            <option key={tenantCustomer.id} value={tenantCustomer.id}>
                                {tenantCustomer.id} - {tenantCustomer.name}
                            </option>
                        ))}
                    </select>
                </div>
            </div>
            {selectedOption === 'selectProjectAndVintage' && (
                <>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 text-left mb-6">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Select Project
                            </label>
                            <AsyncSelect
                                loadOptions={loadOptions}
                                onInputChange={fetchProjects}
                                defaultOptions={projects.map((project) => {
                                    return {
                                        value: project.id,
                                        label: project.name,
                                    }
                                })}
                                isLoading={false}
                                isSearchable={true}
                                onChange={(
                                    value: SingleValue<{ value: string; label: string }>,
                                ) => {
                                    const project = projects.find(
                                        (project) => project.id === value?.value,
                                    )
                                    if (project) {
                                        setSelectedProject(project)
                                    }
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 text-left">
                            <label className="text-sm font-medium text-gray-900">Vintage</label>
                            <select
                                className="block p-2 mb-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                {...register('vintage_id', {
                                    required: true,
                                    validate: (vintage: string) => {
                                        return vintage !== 'Choose'
                                    },
                                })}
                            >
                                <option>Choose</option>
                                {selectedProject?.vintages.map((vintage) => (
                                    <option key={vintage.id} value={vintage.id}>
                                        {vintage.vintage}
                                    </option>
                                ))}
                            </select>
                            <div className="h-4">
                                {errors?.vintage_id?.type === 'validate' && (
                                    <ErrorLabel error="Vintage is required" />
                                )}
                            </div>
                        </div>
                    </div>
                </>
            )}
            {selectedOption === 'pasteVintageId' && (
                <div className="flex flex-row gap-4 justify-between">
                    <div className="w-1/2 mb-2 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">Vintage id</label>
                        <input
                            type="text"
                            placeholder="Vintage Id..."
                            {...register('vintage_id', {
                                required: true,
                                setValueAs: (v) => v.replace(/\s/g, ''),
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-4">
                            {errors?.vintage_id?.type === 'required' && (
                                <ErrorLabel error="Vintage is required" />
                            )}
                        </div>
                    </div>
                </div>
            )}
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-2 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Amount to unbridge
                    </label>
                    <input
                        type="text"
                        placeholder="Amount..."
                        {...register('quantity', {
                            required: true,
                            setValueAs: (v) => v.replace(/\s/g, ''),
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="h-4">
                        {errors?.quantity?.type === 'required' && (
                            <ErrorLabel error="Amount is required" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-2 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Destination registry customer id (tenant registry customer id)
                    </label>
                    <input
                        type="text"
                        placeholder="Destination registry customer id..."
                        {...register('destination_registry_customer_id', {
                            required: tenantCustomers.length > 0 ? true : false,
                        })}
                        className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="h-4">
                        {errors?.destination_registry_customer_id?.type === 'required' && (
                            <ErrorLabel error="Destination registry customer id is required for this tenant" />
                        )}
                    </div>
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="mb-6 text-left w-1/2">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        External Request ID (Enter Id from exchange if unbridging from exchange)
                    </label>
                    <input
                        type="text"
                        {...register('external_request_id', {
                            required: true,
                        })}
                        className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                    ></input>
                </div>
            </div>
            <div className="h-4 text-left">{error && <ErrorLabel error={error} />}</div>
            <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                <button
                    data-modal-toggle="defaultModal"
                    type="submit"
                    className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Save
                </button>
            </div>
        </form>
    )
}

export default CreateUnbridgeRequestForm
