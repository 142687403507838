import { EmptyResponse } from '../types/EmptyResponse'
import { SearchTenantsQueryParams } from '../types/SearchTenantsQueryParams'
import { Tenant } from '../types/Tenant'
import { UpdateTenantPayload } from '../types/UpdateTenantPayload'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface TenantApiInterface {
    getDevelopers(): Promise<AxiosResponse<Tenant[]>>
    search(queryParams: SearchTenantsQueryParams): Promise<AxiosResponse<Tenant[]>>
    getEntityTenants(): Promise<AxiosResponse<Tenant[]>>
    getTenantById(id: string): Promise<AxiosResponse<Tenant>>
    updateTenant(id: string, payload: UpdateTenantPayload): Promise<AxiosResponse<EmptyResponse>>
}

export const TenantApi: TenantApiInterface = Object.freeze({
    getDevelopers(): Promise<AxiosResponse<Tenant[]>> {
        return ApiService.get('/admin/tenants/developers')
    },
    getEntityTenants(): Promise<AxiosResponse<Tenant[]>> {
        return ApiService.get('/admin/tenants/entity')
    },
    getTenantById(id: string): Promise<AxiosResponse<Tenant>> {
        return ApiService.get('/admin/tenants/all', id)
    },
    updateTenant(id: string, payload: UpdateTenantPayload): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.patch(`/admin/tenants/${id}`, payload)
    },
    search(queryParams: SearchTenantsQueryParams): Promise<AxiosResponse<Tenant[]>> {
        return ApiService.get('/admin/tenants', null, queryParams)
    },
})
