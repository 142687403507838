import { RoleName } from '../enums/RoleName'
import { Role } from '../types/Role'
import { User } from '../types/User'

interface RoleProps {
    user: User
}

export const RoleBadge = ({ role }: { role: Role }): JSX.Element => {
    let className: string
    switch (role.name) {
        case RoleName.ADMIN:
            className = 'bg-red-100 text-red-800'
            break
        case RoleName.CUSTOMER:
            className = 'bg-blue-100 text-blue-800'
            break
        case RoleName.REGISTRY:
            className = 'bg-yellow-100 text-yellow-800'
            break
        case RoleName.STATS:
            className = 'bg-green-100 text-green-800'
            break
        case RoleName.XRPL:
            className = 'bg-purple-100 text-purple-800'
            break
        case RoleName.EXCHANGE:
            className = 'bg-pink-100 text-pink-800'
    }
    return (
        <span
            className={`${className} text-xs font-medium mr-2 px-2.5 py-0.5 rounded-full my-auto`}
        >
            {role.display_name}
        </span>
    )
}

const Roles = ({ user }: RoleProps): JSX.Element => {
    return (
        <div className="flex flex-row">
            {user.roles.map((role: Role) => (
                <RoleBadge role={role} key={role.id} />
            ))}
        </div>
    )
}

export default Roles
