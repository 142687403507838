import { EmptyResponse } from '../types/EmptyResponse'
import { SaveUnbridgeRequestPayload } from '../types/unbridgeRequest/SaveUnbridgeRequestPayload'
import { UnbridgeRequest } from '../types/unbridgeRequest/UnbridgeRequest'
import { UnbridgeSourceBlock } from '../types/unbridgeRequest/UnbridgeSourceBlock'
import { UnridgeBlocksRequestPayload } from '../types/unbridgeRequest/UnridgeBlocksRequestPayload'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface UnbridgeRequestApiInterface {
    getAll(): Promise<AxiosResponse<UnbridgeRequest[]>>
    getByAsyncItemId(asyncItemId: string): Promise<AxiosResponse<UnbridgeRequest>>
    saveUnbridgeRequest(payload: SaveUnbridgeRequestPayload): Promise<AxiosResponse<EmptyResponse>>
    unbridgeBlocks(payload: UnridgeBlocksRequestPayload): Promise<AxiosResponse<EmptyResponse>>
    getSourceBlocks(id: string): Promise<AxiosResponse<UnbridgeSourceBlock[]>>
    failUnbridgingRequest(id: string): Promise<AxiosResponse<EmptyResponse>>
    getAssociatedBlocks(
        asyncItemId: string,
        unbridgeRequestId: string,
    ): Promise<AxiosResponse<UnbridgeSourceBlock[]>>
}

export const UnbridgeRequestApi: UnbridgeRequestApiInterface = Object.freeze({
    getAll(): Promise<AxiosResponse<UnbridgeRequest[]>> {
        return ApiService.get('/admin/unbridge-request')
    },
    getByAsyncItemId(asyncItemId: string): Promise<AxiosResponse<UnbridgeRequest>> {
        return ApiService.get('/admin/unbridge-request/async-item', asyncItemId)
    },
    saveUnbridgeRequest(
        payload: SaveUnbridgeRequestPayload,
    ): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/unbridge-request/create', payload)
    },
    unbridgeBlocks(payload: UnridgeBlocksRequestPayload): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/unbridge-request/unbridge/source-blocks', payload)
    },
    getSourceBlocks(id: string): Promise<AxiosResponse<UnbridgeSourceBlock[]>> {
        return ApiService.get('/admin/unbridge-request/source-blocks', id)
    },
    failUnbridgingRequest(id: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/unbridge-request/fail', {
            async_item_id: id,
        })
    },
    getAssociatedBlocks(
        asyncItemId: string,
        unbridgeRequestId: string,
    ): Promise<AxiosResponse<UnbridgeSourceBlock[]>> {
        return ApiService.get(
            '/admin/unbridge-request/source-blocks/' + asyncItemId + '/' + unbridgeRequestId,
        )
    },
})
