import { RegistryApi } from '../../api/registry'
import { TenantApi } from '../../api/tenant'
import { TenantType } from '../../enums/TenantType'
import { ErrorResponse } from '../../types/ErrorResponse'
import { Registry } from '../../types/Registry'
import { Tenant } from '../../types/Tenant'
import { UpdateTenantPayload } from '../../types/UpdateTenantPayload'
import ErrorLabel from '../ErrorLabel'
import { AxiosError } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

interface UpdateTenantFormProps {
    onSaved: () => void
    onReturn: () => void
    tenant: Tenant
}

interface FormData {
    entityName: string
    evmAddress: string
    xrplAddress: string
    xrplVaultId: string
    xrplDomain: string
    registryId: string
    taxId: string
}

const UpdateTenantForm = ({ onSaved, onReturn, tenant }: UpdateTenantFormProps): JSX.Element => {
    const [error, setError] = useState<string | null>(null)
    const [registries, setRegistries] = useState<Registry[]>([])
    const [selectedRegistry, setSelectedRegistry] = useState<string>(tenant.registry_id ?? 'Empty')
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
    } = useForm<FormData>()
    const intervalRef = useRef<any>(null)

    const entityNameField = register('entityName', {
        required: tenant.type === TenantType.ENTITY,
    })

    const evmAddressField = register('evmAddress', {
        required: false,
    })

    const xrplAddressField = register('xrplAddress', {
        required: false,
    })

    const xrplVaultIdField = register('xrplVaultId', {
        required: false,
    })

    const xrplDomainField = register('xrplDomain', {
        required: false,
    })

    const registryIdField = register('registryId', {
        required: false,
    })

    const taxIdField = register('taxId', {
        required: false,
    })

    const fetchRegistries = async () => {
        const response = await RegistryApi.getAll()
        setRegistries(response.data)
    }

    const onSubmit = async (updateTenantData: FormData) => {
        try {
            setError(null)
            const updateTenantPayload: UpdateTenantPayload = {
                ...(updateTenantData.entityName.trim() !== tenant.entity_name.trim() && {
                    entity_name: updateTenantData.entityName.trim(),
                }),
                ...(updateTenantData.evmAddress.trim() !== tenant.evm_address?.trim() && {
                    evm_address:
                        updateTenantData.evmAddress.trim() === ''
                            ? null
                            : updateTenantData.evmAddress.trim(),
                }),
                ...(updateTenantData.xrplAddress !== tenant.xrpl_address && {
                    xrpl_address:
                        updateTenantData.xrplAddress === ''
                            ? null
                            : updateTenantData.xrplAddress.trim(),
                }),
                ...(updateTenantData.xrplVaultId !== tenant.xrpl_vault_id && {
                    xrpl_vault_id:
                        updateTenantData.xrplVaultId === ''
                            ? null
                            : updateTenantData.xrplVaultId.trim(),
                }),
                ...(updateTenantData.xrplDomain !== tenant.xrpl_account_domain && {
                    xrpl_account_domain:
                        updateTenantData.xrplDomain === ''
                            ? null
                            : updateTenantData.xrplDomain.trim(),
                }),
                ...(selectedRegistry !== tenant.registry_id && {
                    registry_id: selectedRegistry === 'Empty' ? null : updateTenantData.registryId,
                }),
                ...(updateTenantData.taxId.trim() !== tenant.tax_id?.trim() && {
                    tax_id: !!updateTenantData.taxId.trim() ? updateTenantData.taxId.trim() : null,
                }),
            }
            await TenantApi.updateTenant(tenant.id, updateTenantPayload)
            onSaved()
        } catch (e: unknown) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            }
        }
    }

    useEffect(() => {
        intervalRef.current = setTimeout(() => {
            void fetchRegistries()
        }, 0)
        return () => clearTimeout(intervalRef.current)
    }, [tenant])

    useEffect(() => {
        setValue('entityName', tenant.entity_name)
        setValue('evmAddress', tenant.evm_address ?? '')
        setValue('xrplAddress', tenant.xrpl_address ?? '')
        setValue('xrplVaultId', tenant.xrpl_vault_id ?? '')
        setValue('xrplDomain', tenant.xrpl_account_domain ?? '')
        setValue('registryId', tenant.registry_id)
        setValue('taxId', tenant.tax_id ?? '')
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="w-[2%]">
                    <button
                        className="px-2 h-12 flex justify-center items-center border border-black rounded-lg "
                        onClick={onReturn}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            width="20"
                            height="20"
                        >
                            <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                        </svg>
                    </button>
                </div>
                {tenant.type === TenantType.ENTITY && (
                    <div className="flex flex-col mt-8">
                        <label className="text-sm text-left font-semibold text-gray-700">
                            Company Name*
                        </label>
                        <input
                            type="text"
                            // disabled={isFormDisabled}
                            {...entityNameField}
                            placeholder="Company Name"
                            className="h-11 rounded-lg border-gray-300 mt-1.5"
                        />
                        <div className="h-5">
                            {errors?.entityName?.type === 'required' && (
                                <ErrorLabel error="Company name is required" />
                            )}
                        </div>
                    </div>
                )}
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">
                        Evm Address
                    </label>
                    <input
                        type="text"
                        placeholder="Enter Evm Address"
                        {...evmAddressField}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.evmAddress?.type === 'required' && (
                            <ErrorLabel error="Evm address is required" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">
                        XRPL Address
                    </label>
                    <input
                        type="text"
                        placeholder="Enter XRPL Address"
                        {...xrplAddressField}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.xrplAddress?.type === 'required' && (
                            <ErrorLabel error="XRPL address is required" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">
                        XRPL Vault ID
                    </label>
                    <input
                        type="text"
                        placeholder="Enter XRPL Vault Id"
                        {...xrplVaultIdField}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.xrplVaultId?.type === 'required' && (
                            <ErrorLabel error="XRPL Vault ID is required" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">
                        XRPL Account Domain
                    </label>
                    <input
                        type="text"
                        placeholder="Enter XRPL Account Domain"
                        {...xrplDomainField}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.xrplDomain?.type === 'required' && (
                            <ErrorLabel error="XRPL Account Domain is required" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">Tax ID</label>
                    <input
                        type="text"
                        placeholder="Enter Tax ID"
                        {...taxIdField}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    <div className="h-5">
                        {errors?.taxId?.type === 'required' && (
                            <ErrorLabel error="Tax ID is required" />
                        )}
                    </div>
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">
                        Registry
                    </label>
                    <select
                        id="small"
                        className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        {...registryIdField}
                        value={selectedRegistry}
                        onChange={(e) => setSelectedRegistry(e.currentTarget.value)}
                    >
                        <option value="Empty">Empty</option>
                        {registries.map((registry: Registry) => (
                            <option key={registry.id} value={registry.id}>
                                {registry.name}
                            </option>
                        ))}
                    </select>
                    <div className="h-5">
                        {errors?.registryId?.type === 'required' && (
                            <ErrorLabel error="Registry is required" />
                        )}
                    </div>
                </div>
                {error && <ErrorLabel error={error} />}
                <div className="flex justify-end items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                        data-modal-toggle="defaultModal"
                        onClick={onReturn}
                        className="border-2 border-gray-100 hover:bg-gray-100 focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Cancel
                    </button>
                    <button
                        data-modal-toggle="defaultModal"
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Save
                    </button>
                </div>
            </form>
        </>
    )
}

export default UpdateTenantForm
