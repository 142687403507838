import { Registry } from '../types/Registry'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface RegistryApiInterface {
    getAll(): Promise<AxiosResponse<Registry[]>>
}

export const RegistryApi: RegistryApiInterface = Object.freeze({
    getAll(): Promise<AxiosResponse<Registry[]>> {
        return ApiService.get('/admin/registry')
    },
})
