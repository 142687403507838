import { RegistryApi } from '../../api/registry'
import { TenantApi } from '../../api/tenant'
import { TenantCustomerApi } from '../../api/tenantCustomer'
import { TenantCustomerRegistryIdApi } from '../../api/tenantCustomerRegistryId'
import { Page } from '../../enums/Page'
import { ErrorResponse } from '../../types/ErrorResponse'
import { Registry } from '../../types/Registry'
import { Tenant } from '../../types/Tenant'
import ErrorLabel from '../ErrorLabel'
import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

export interface EditTenantCustomerRegistrIdForm {
    tenant_customer_id: string
    registry_id: string
    registry_user_id: string
}

const EditTenantCustomerRegistryIdForm = (): JSX.Element => {
    const {
        register,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm<EditTenantCustomerRegistrIdForm>({})

    const { id: tenantCustomerId } = useParams<{ id: string }>()
    const [registries, setRegistries] = useState<Registry[]>([])
    const selectedRegistry = watch('registry_id') ?? '005h8koa2ioo0qgq3x'
    const navigate = useNavigate()
    const [error, setError] = useState<string | null>(null)
    const [tenant, setTenant] = useState<Tenant | null>(null)

    const fetchRegistries = async () => {
        const response = await RegistryApi.getAll()
        setRegistries(response.data)
    }

    const fetchExisting = async () => {
        const res = await TenantCustomerRegistryIdApi.getByTenantCustomerIdAndRegistryId(
            tenantCustomerId ?? '',
            selectedRegistry,
        )
        if (res.data.registry_user_id) {
            setValue('registry_user_id', res.data.registry_user_id)
        } else {
            setValue('registry_user_id', '')
        }
    }

    const fetchTenant = async () => {
        const res = await TenantCustomerApi.getById(tenantCustomerId ?? '')
        const resTenant = await TenantApi.getTenantById(res.data.tenant_id)
        setTenant(resTenant.data)
    }

    useEffect(() => {
        void fetchRegistries()
        setValue('tenant_customer_id', tenantCustomerId ?? '')
    }, [])

    useEffect(() => {
        void fetchExisting()
    }, [selectedRegistry])

    useEffect(() => {
        void fetchTenant()
    }, [tenantCustomerId])

    const onSubmit = async (data: EditTenantCustomerRegistrIdForm) => {
        try {
            await TenantCustomerRegistryIdApi.createOrUpdate({
                tenant_customer_id: data.tenant_customer_id,
                registry_id: data.registry_id,
                registry_user_id: data.registry_user_id,
            })
            navigate(
                generatePath(Page.MANAGE_TENANT_CUSTOMERS, {
                    id: tenant?.id ?? '',
                }),
            )
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            }
        }
    }

    return (
        <div>
            <h1>
                Editing Tenant Customer Registry Id for Tenant Customer Id: {tenantCustomerId} for
                Tenant: {tenant?.entity_name} - {tenant?.id}
            </h1>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div>
                    <h3 className="bg-gray-300 text-left mb-5">Tenant Customer</h3>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Tenant Customer Id (read-only)
                        </label>
                        <input
                            placeholder="Tenant Customer Id"
                            {...register('tenant_customer_id', {
                                required: true,
                            })}
                            readOnly={true}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-5">
                            {errors?.tenant_customer_id?.type === 'required' && (
                                <ErrorLabel error="Tenant Customer id is required" />
                            )}
                        </div>
                    </div>
                    <div className="w-1/2 mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">Registry</label>
                        <select
                            className="block p-2 mb-6 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            {...register('registry_id', {
                                required: true,
                                validate: (type: string) => {
                                    return type !== 'Choose'
                                },
                            })}
                            onChange={(e) => {
                                setValue('registry_id', e.target.value)
                            }}
                        >
                            <option>Choose</option>
                            {registries.map((registry: Registry) => (
                                <option key={registry.id} value={registry.id}>
                                    {registry.name}
                                </option>
                            ))}
                        </select>
                        {errors?.registry_id?.type === 'validate' && (
                            <ErrorLabel error="Registry is required" />
                        )}
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Registry User Id
                        </label>
                        <input
                            placeholder="Registry User Id"
                            {...register('registry_user_id', {
                                required: true,
                                onBlur: (e) => {
                                    setValue('registry_user_id', e.target.value.trim())
                                },
                            })}
                            className="block p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-5">
                            {errors?.registry_user_id?.type === 'required' && (
                                <ErrorLabel error="Registry user id is required" />
                            )}
                        </div>
                    </div>
                </div>
                {error && <ErrorLabel error={error} />}
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                        data-modal-toggle="defaultModal"
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Add or Update Tenant Customer Registry Id
                    </button>
                </div>
            </form>
        </div>
    )
}

export default EditTenantCustomerRegistryIdForm
