export enum AsyncItemSubStatuses {
    IN_PROGRESS_ADD_PROJECT_VINTAGE = 'IN_PROGRESS_ADD_PROJECT_VINTAGE',
    PENDING_ADD_PROJECT_BC = 'PENDING_ADD_PROJECT_BC',
    IN_PROGRESS_ADD_PROJECT_BC = 'IN_PROGRESS_ADD_PROJECT_BC',
    PENDING_ADD_VINTAGE_BC = 'PENDING_ADD_VINTAGE_BC',
    IN_PROGRESS_ADD_VINTAGE_BC = 'IN_PROGRESS_ADD_VINTAGE_BC',
    IN_PROGRESS_CANCEL_CREDITS = 'IN_PROGRESS_CANCEL_CREDITS',
    PENDING_CANCEL_CREDITS = 'PENDING_CANCEL_CREDITS',
    CREDITS_CANCELLED = 'CREDITS_CANCELLED',
    PENDING_MINT_TOKENS = 'PENDING_MINT_TOKENS',
    IN_PROGRESS_MINT_TOKENS = 'IN_PROGRESS_MINT_TOKENS',
    TOKENS_MINTED = 'TOKENS_MINTED',

    IN_PROGRESS_REQUEST_RETIREMENT_BC = 'IN_PROGRESS_REQUEST_RETIREMENT_BC',
    PENDING_RETIRE_ON_REGISTRY = 'PENDING_RETIRE_ON_REGISTRY',
    IN_PROGRESS_RETIRE_ON_REGISTRY = 'IN_PROGRESS_RETIRE_ON_REGISTRY',
    PENDING_RETIRE_BLOCKS_DB = 'PENDING_RETIRE_BLOCKS_DB',
    IN_PROGRESS_RETIRE_BLOCKS_DB = 'IN_PROGRESS_RETIRE_BLOCKS_DB',
    PENDING_GENERATE_NFT_ARTWORK = 'PENDING_GENERATE_NFT_ARTWORK',
    IN_PROGRESS_GENERATE_NFT_ARTWORK = 'IN_PROGRESS_GENERATE_NFT_ARTWORK',
    PENDING_RETURN_RETIREMENT_BC = 'PENDING_RETURN_RETIREMENT_BC',
    IN_PROGRESS_RETURN_RETIREMENT_BC = 'IN_PROGRESS_RETURN_RETIREMENT_BC',
    PENDING_FINALISE_RETIREMENT_BC = 'PENDING_FINALISE_RETIREMENT_BC',
    IN_PROGRESS_FINALISE_RETIREMENT_BC = 'IN_PROGRESS_FINALISE_RETIREMENT_BC',
    RETIREMENT_COMPLETE = 'RETIREMENT_COMPLETE',
    RETIREMENT_RETURNED = 'RETIREMENT_RETURNED',

    IN_PROGRESS_REQUEST_UNBRIDGE_BC = 'IN_PROGRESS_REQUEST_UNBRIDGE_BC',
    PENDING_UNBRIDGE_ON_REGISTRY = 'PENDING_UNBRIDGE_ON_REGISTRY',
    IN_PROGRESS_UNBRIDGE_ON_REGISTRY = 'IN_PROGRESS_UNBRIDGE_ON_REGISTRY',
    PENDING_UNBRIDGE_BLOCKS_DB = 'PENDING_UNBRIDGE_BLOCKS_DB',
    IN_PROGRESS_UNBRIDGE_BLOCKS_DB = 'IN_PROGRESS_UNBRIDGE_BLOCKS_DB',
    PENDING_RETURN_UNBRIDGE_BC = 'PENDING_RETURN_UNBRIDGE_BC',
    IN_PROGRESS_RETURN_UNBRIDGE_BC = 'IN_PROGRESS_RETURN_UNBRIDGE_BC',
    PENDING_FINALISE_UNBRIDGE_BC = 'PENDING_FINALISE_UNBRIDGE_BC',
    IN_PROGRESS_FINALISE_UNBRIDGE_BC = 'IN_PROGRESS_FINALISE_UNBRIDGE_BC',
    UNBRIDGE_COMPLETE = 'UNBRIDGE_COMPLETE',
    UNBRIDGE_RETURNED = 'UNBRIDGE_RETURNED',

    IN_PROGRESS_XRPL_ACCOUNT_SET = 'IN_PROGRESS_XRPL_ACCOUNT_SET',
    XRPL_ACCOUNT_SET_COMPLETE = 'XRPL_ACCOUNT_SET_COMPLETE',

    IN_PROGRESS_FETCH_BLOCK_DATA = 'IN_PROGRESS_FETCH_BLOCK_DATA',
    PENDING_MATCH_EXPECTED_BLOCK = 'PENDING_MATCH_EXPECTED_BLOCK',
    IN_PROGRESS_MATCH_EXPECTED_BLOCK = 'IN_PROGRESS_MATCH_EXPECTED_BLOCK',
    MANUAL_MATCH_EXPECTED_BLOCK = 'MANUAL_MATCH_EXPECTED_BLOCK',
    PENDING_GENERATE_BRIDGE_ASYNC_ITEM = 'PENDING_GENERATE_BRIDGE_ASYNC_ITEM',
    GENERATED_ASYNC_ITEM = 'GENERATED_ASYNC_ITEM',
    RETURNED_CREDITS_ON_REGISTRY = 'RETURNED_CREDITS_ON_REGISTRY',

    PENDING_ONBOARDING = 'PENDING_ONBOARDING',
    IN_PROGRESS_ONBOARDING = 'IN_PROGRESS_ONBOARDING',
    PENDING_REJECT_ONBOARDING = 'PENDING_REJECT_ONBOARDING',
    IN_PROGRESS_REJECT_ONBOARDING = 'IN_PROGRESS_REJECT_ONBOARDING',
    COMPLETE_ONBOARDING = 'COMPLETE_ONBOARDING',
    COMPLETE_REJECTION = 'COMPLETE_REJECTION',
}
