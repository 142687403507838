import { TenantApi } from '../api/tenant'
import Layout from '../components/Layout'
import UpdateTenantForm from '../components/forms/UpdateTenantForm'
import { Page } from '../enums/Page'
import { Tenant } from '../types/Tenant'
import { Spinner } from 'flowbite-react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const UpdateTenant = (): JSX.Element => {
    const [tenant, setTenant] = useState<Tenant>()
    const [showSpinner, setShowSpinner] = useState<boolean>()
    const navigate = useNavigate()
    const { id: tenantId } = useParams<{ id: string }>()
    const intervalRef = useRef<any>(null)

    const fetchTenantById = async (id: string) => {
        try {
            setShowSpinner(true)
            const response = await TenantApi.getTenantById(id)
            setTenant(response.data)
            setShowSpinner(false)
        } catch (e) {
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        intervalRef.current = setTimeout(() => {
            if (tenantId) {
                void fetchTenantById(tenantId)
            }
        }, 0)
        return () => clearTimeout(intervalRef.current)
    }, [tenantId])

    const handleOnReturn = () => {
        navigate(Page.USERS)
    }

    const handleOnSaved = () => {
        navigate(Page.USERS)
    }

    return (
        <Layout>
            <>
                {tenant && (
                    <UpdateTenantForm
                        tenant={tenant}
                        onSaved={handleOnSaved}
                        onReturn={handleOnReturn}
                    />
                )}
                {showSpinner && <Spinner size="xl" />}
            </>
        </Layout>
    )
}

export default UpdateTenant
