import RetirementEventSelectListItem from './RetirementEventSelectListItem'
import { RetirementEventSelectBlocksPresentationProps } from './index'

const RetirementEventSelectList = ({
    blocks,
    getSelectOptions,
    onChangeSelectSerialInputHandler,
    getSelectFilterOptions,
    onRemoveBlockButtonClickedHandler,
    onChangeAmountToRetireInputHandler,
    onBlurBlockInput,
    validationData,
}: RetirementEventSelectBlocksPresentationProps): JSX.Element => {
    const getValidationDataForItem = (index: number) => {
        return validationData.find((item) => item.index === index)
    }

    return (
        <ul className="px-[10px]">
            {blocks.map((block, index) => (
                <RetirementEventSelectListItem
                    block={block}
                    index={index}
                    key={index}
                    getSelectOptions={getSelectOptions}
                    onChangeSelectSerialInputHandler={onChangeSelectSerialInputHandler}
                    getSelectFilterOption={getSelectFilterOptions}
                    onRemoveBlockButtonClickedHandler={onRemoveBlockButtonClickedHandler}
                    onChangeAmountToRetireInputHandler={onChangeAmountToRetireInputHandler}
                    onBlurBlockInput={onBlurBlockInput}
                    validationData={getValidationDataForItem(index) ?? null}
                />
            ))}
        </ul>
    )
}

export default RetirementEventSelectList
