import { EmptyResponse } from '../types/EmptyResponse'
import { Role } from '../types/Role'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface IRoleAPI {
    updateUserRoles(roleIds: string[], userId: string): Promise<AxiosResponse<EmptyResponse>>
    getAllRoles(): Promise<AxiosResponse<Role[]>>
}

export const RoleApi: IRoleAPI = Object.freeze({
    updateUserRoles(roleIds: string[], userId: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.patch(`/admin/roles/users/${userId}`, {
            ids: roleIds,
        })
    },

    getAllRoles(): Promise<AxiosResponse<Role[]>> {
        return ApiService.get('/admin/roles/')
    },
})
