import Layout from '../components/Layout'
import EditTenantCustomerForm from '../components/forms/EditTenantCustomerForm'

const EditTenantCustomer = (): JSX.Element => {
    return (
        <Layout>
            <EditTenantCustomerForm />
        </Layout>
    )
}

export default EditTenantCustomer
