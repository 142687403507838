import { RegistryApi } from '../../api/registry'
import { TenantApi } from '../../api/tenant'
import { UserApi } from '../../api/user'
import { TenantType } from '../../enums/TenantType'
import { CreateUserAndTenantPayload } from '../../types/CreateUserAndTenantPayload'
import { CreateUserBase } from '../../types/CreateUserBase'
import { ErrorResponse } from '../../types/ErrorResponse'
import { Registry } from '../../types/Registry'
import { Tenant } from '../../types/Tenant'
import { getEmailRegex } from '../../utils/getEmailRegex'
import ErrorLabel from '../ErrorLabel'
import { AxiosError } from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { useForm } from 'react-hook-form'

interface CreateUserFormProps {
    onSaved: () => void
    onReturn: () => void
}

enum CreateUserTypesByTenant {
    INDIVIDUAL = 'INDIVIDUAL',
    NEW_ENTITY = 'NEW_ENTITY',
    ATTACH_TO_EXISTING_TENANT = 'ATTACH_TO_EXISTING_TENANT',
}

interface FormData {
    companyName?: string
    evmAddress?: string
    xrplAddress?: string
    xrplVaultId?: string
    xrplDomain?: string
    tenantId?: string
    registryId?: string
    email: string
    firstName: string
    lastName: string
}

const CreateUserForm = ({ onSaved, onReturn }: CreateUserFormProps) => {
    const [selectedUserTypeByTenant, setSelectedUserTypeByTenant] =
        useState<CreateUserTypesByTenant>(CreateUserTypesByTenant.NEW_ENTITY)
    const [registries, setRegistries] = useState<Registry[]>([])
    const [tenants, setTenants] = useState<Tenant[]>([])
    const [error, setError] = useState<string | null>(null)
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<FormData>()
    const intervalRef = useRef<any>(null)
    const tenantsIntervalRef = useRef<any>(null)

    const fetchRegistries = async () => {
        const response = await RegistryApi.getAll()
        setRegistries(response.data)
    }

    const fetchTenants = async () => {
        const tenantsResponse = await TenantApi.getEntityTenants()
        setTenants(tenantsResponse.data)
    }

    useEffect(() => {
        intervalRef.current = setTimeout(() => {
            void fetchRegistries()
        }, 0)
        return () => clearTimeout(intervalRef.current)
    }, [])

    useEffect(() => {
        tenantsIntervalRef.current = setTimeout(() => {
            if (selectedUserTypeByTenant === CreateUserTypesByTenant.ATTACH_TO_EXISTING_TENANT) {
                void fetchTenants()
            }
        }, 0)
        return () => clearTimeout(tenantsIntervalRef.current)
    }, [selectedUserTypeByTenant])

    const getAccountTypeBySelectedType = (
        accType: CreateUserTypesByTenant,
    ): TenantType | undefined => {
        if (accType === CreateUserTypesByTenant.INDIVIDUAL) {
            return TenantType.INDIVIDUAL
        } else if (accType === CreateUserTypesByTenant.NEW_ENTITY) {
            return TenantType.ENTITY
        }
    }

    const onSubmit = async (createData: FormData) => {
        try {
            setError(null)
            const creatUserData: CreateUserBase = {
                email: createData.email.trim(),
                first_name: createData.firstName.trim(),
                last_name: createData.lastName.trim(),
            }
            if (
                selectedUserTypeByTenant === CreateUserTypesByTenant.INDIVIDUAL ||
                selectedUserTypeByTenant === CreateUserTypesByTenant.NEW_ENTITY
            ) {
                const userAndTenantData: CreateUserAndTenantPayload = { ...creatUserData }
                if (createData.registryId && createData.registryId !== 'Choose') {
                    userAndTenantData['registry_id'] = createData.registryId
                }
                await UserApi.createUserAndTenant({
                    ...userAndTenantData,
                    account_type: getAccountTypeBySelectedType(selectedUserTypeByTenant),
                    ...(selectedUserTypeByTenant === CreateUserTypesByTenant.NEW_ENTITY &&
                        createData?.companyName && { company_name: createData.companyName.trim() }),
                    ...(createData?.evmAddress && { evm_address: createData.evmAddress.trim() }),
                    ...(createData?.xrplAddress && { xrpl_address: createData.xrplAddress.trim() }),
                    ...(createData?.xrplVaultId && {
                        xrpl_vault_id: createData.xrplVaultId.trim(),
                    }),
                    ...(createData?.xrplDomain && {
                        xrpl_account_domain: createData.xrplDomain.trim(),
                    }),
                })
            } else if (
                selectedUserTypeByTenant === CreateUserTypesByTenant.ATTACH_TO_EXISTING_TENANT
            ) {
                await UserApi.createUserForExistingTenant({
                    ...creatUserData,
                    tenant_id: createData.tenantId as string,
                })
            }
            onSaved()
        } catch (e: unknown) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            }
        }
    }

    return (
        <div>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="flex items-center">
                    <div className="w-[2%]">
                        <button
                            className="px-2 h-12 flex justify-center items-center border border-black rounded-lg "
                            onClick={onReturn}
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 384 512"
                                width="20"
                                height="20"
                            >
                                <path d="M41.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.3 256 278.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z" />
                            </svg>
                        </button>
                    </div>
                    <div className="w-[98%] flex gap-5 justify-center my-2">
                        <button
                            type="button"
                            className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                                selectedUserTypeByTenant === CreateUserTypesByTenant.NEW_ENTITY
                                    ? 'bg-primary-600'
                                    : 'bg-primary-100'
                            }`}
                            onClick={() =>
                                setSelectedUserTypeByTenant(CreateUserTypesByTenant.NEW_ENTITY)
                            }
                        >
                            Entity
                        </button>
                        <button
                            type="button"
                            className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                                selectedUserTypeByTenant ===
                                CreateUserTypesByTenant.ATTACH_TO_EXISTING_TENANT
                                    ? 'bg-primary-600'
                                    : 'bg-primary-100'
                            }`}
                            onClick={() =>
                                setSelectedUserTypeByTenant(
                                    CreateUserTypesByTenant.ATTACH_TO_EXISTING_TENANT,
                                )
                            }
                        >
                            Existing Entity
                        </button>
                        <button
                            type="button"
                            className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                                selectedUserTypeByTenant === CreateUserTypesByTenant.INDIVIDUAL
                                    ? 'bg-primary-600'
                                    : 'bg-primary-100'
                            }`}
                            onClick={() =>
                                setSelectedUserTypeByTenant(CreateUserTypesByTenant.INDIVIDUAL)
                            }
                        >
                            Individual
                        </button>
                    </div>
                </div>
                {selectedUserTypeByTenant === CreateUserTypesByTenant.NEW_ENTITY && (
                    <div className="flex flex-col mt-8">
                        <label className="text-sm text-left font-semibold text-gray-700">
                            Company Name*
                        </label>
                        <input
                            // disabled={isFormDisabled}
                            type="text"
                            placeholder="Company Name"
                            {...register('companyName', {
                                required: true,
                            })}
                            className="h-11 rounded-lg border-gray-300 mt-1.5"
                        />
                        {errors?.companyName?.type === 'required' && (
                            <div className="h-5">
                                <ErrorLabel error="Company Name is required" />
                            </div>
                        )}
                    </div>
                )}
                {selectedUserTypeByTenant === CreateUserTypesByTenant.ATTACH_TO_EXISTING_TENANT && (
                    <div className="flex flex-col mt-8">
                        <label className="text-sm text-left font-semibold text-gray-700">
                            Entity*
                        </label>
                        <select
                            id="small"
                            className="h-11 rounded-lg border-gray-300 mt-1.5 text-gray-700"
                            {...register('tenantId', {
                                required: true,
                                minLength: 1,
                            })}
                        >
                            <option value={''}>Choose</option>
                            {tenants.map((tenant) => (
                                <option key={tenant.id} value={tenant.id}>
                                    {tenant.entity_name} - {tenant.id}
                                </option>
                            ))}
                        </select>
                        {errors?.tenantId?.type === 'required' && (
                            <div className="h-5">
                                <ErrorLabel error="Existing Entity must be selected" />
                            </div>
                        )}
                    </div>
                )}
                <div
                    className={`flex flex-col ${
                        selectedUserTypeByTenant === CreateUserTypesByTenant.INDIVIDUAL
                            ? 'mt-8'
                            : 'mt-3'
                    }`}
                >
                    <label className="text-sm text-left font-semibold text-gray-700">Email*</label>
                    <input
                        type="email"
                        // disabled={isFormDisabled}
                        placeholder="Email"
                        {...register('email', {
                            required: true,
                            pattern: getEmailRegex(),
                        })}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    {errors?.email?.type === 'required' && (
                        <div className="h-5">
                            <ErrorLabel error="Email is required" />
                        </div>
                    )}
                    {errors?.email?.type === 'pattern' && (
                        <div className="h-5">
                            <ErrorLabel error="Invalid email" />
                        </div>
                    )}
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">
                        First Name*
                    </label>
                    <input
                        type="text"
                        // disabled={isFormDisabled}
                        placeholder="Enter First Name"
                        {...register('firstName', {
                            required: true,
                        })}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    {errors?.firstName?.type === 'required' && (
                        <div className="h-5">
                            <ErrorLabel error="First Name is required" />
                        </div>
                    )}
                </div>
                <div className="flex flex-col mt-3">
                    <label className="text-sm text-left font-semibold text-gray-700">
                        Last Name*
                    </label>
                    <input
                        // disabled={isFormDisabled}
                        type="text"
                        placeholder="Enter Last Name"
                        {...register('lastName', {
                            required: true,
                        })}
                        className="h-11 rounded-lg border-gray-300 mt-1.5"
                    />
                    {errors?.lastName?.type === 'required' && (
                        <div className="h-5">
                            <ErrorLabel error="Last Name is required" />
                        </div>
                    )}
                </div>
                {selectedUserTypeByTenant !== CreateUserTypesByTenant.ATTACH_TO_EXISTING_TENANT && (
                    <>
                        <div className="flex flex-col mt-3">
                            <label className="text-sm text-left font-semibold text-gray-700">
                                EVM Address
                            </label>
                            <input
                                type="text"
                                placeholder="Enter EVM Address"
                                {...register('evmAddress', {
                                    required: false,
                                })}
                                className="h-11 rounded-lg border-gray-300 mt-1.5"
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label className="text-sm text-left font-semibold text-gray-700">
                                XRPL Address
                            </label>
                            <input
                                type="text"
                                placeholder="Enter XRPL Address"
                                {...register('xrplAddress', {
                                    required: false,
                                })}
                                className="h-11 rounded-lg border-gray-300 mt-1.5"
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label className="text-sm text-left font-semibold text-gray-700">
                                XRPL Vault ID
                            </label>
                            <input
                                type="text"
                                placeholder="Enter XRPL Vault Id"
                                {...register('xrplVaultId', {
                                    required: false,
                                })}
                                className="h-11 rounded-lg border-gray-300 mt-1.5"
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label className="text-sm text-left font-semibold text-gray-700">
                                XRPL Account Domain
                            </label>
                            <input
                                type="text"
                                placeholder="Enter XRPL Account Domain"
                                {...register('xrplDomain', {
                                    required: false,
                                })}
                                className="h-11 rounded-lg border-gray-300 mt-1.5"
                            />
                        </div>
                        <div className="flex flex-col mt-3">
                            <label className="text-sm text-left font-semibold text-gray-700">
                                Registry
                            </label>
                            <select
                                id="small"
                                className="h-11 rounded-lg border-gray-300 mt-1.5 text-gray-500"
                                {...register('registryId', {
                                    required: true,
                                })}
                            >
                                <option>Choose</option>
                                {registries.map((registry: Registry) => (
                                    <option key={registry.id} value={registry.id}>
                                        {registry.name}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </>
                )}

                {error && <ErrorLabel error={error} />}
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                        data-modal-toggle="defaultModal"
                        type="submit"
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Save
                    </button>
                </div>
            </form>
        </div>
    )
}

export default CreateUserForm
