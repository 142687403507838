import { ApiKeyGenerateResponse } from '../types/ApiKeyGenerateResponse'
import { ApiKeysResponse } from '../types/ApiKeysResponse'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface ApiKeysManagementApiInterface {
    getAllApiKeysByUserId(userId: string): Promise<AxiosResponse<ApiKeysResponse>>
    generateApiKeyByUserId(
        userId: string,
        name?: string,
    ): Promise<AxiosResponse<ApiKeyGenerateResponse>>
    setApiKeyIsActive(apiKeyId: string, isActive: boolean): Promise<AxiosResponse<void>>
}

export const ApiKeysManagementApi: ApiKeysManagementApiInterface = Object.freeze({
    getAllApiKeysByUserId(userId: string): Promise<AxiosResponse<ApiKeysResponse>> {
        return ApiService.get(`/admin/api-keys-management/${userId}`)
    },
    generateApiKeyByUserId(
        userId: string,
        name?: string,
    ): Promise<AxiosResponse<ApiKeyGenerateResponse>> {
        return ApiService.post('/admin/api-keys-management/', {
            user_id: userId,
            ...(!!name && { name }),
        })
    },
    setApiKeyIsActive(apiKeyId: string, isActive: boolean): Promise<AxiosResponse<void>> {
        return ApiService.patch(`/admin/api-keys-management/${apiKeyId}`, {
            is_active: isActive,
        })
    },
})
