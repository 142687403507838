import { GetVintageByIdResponse } from '../types/GetVintageByIdResponse'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface VintageApiInterface {
    getById(id: string): Promise<AxiosResponse<GetVintageByIdResponse>>
}

export const VintageApi: VintageApiInterface = Object.freeze({
    getById(id: string): Promise<AxiosResponse<GetVintageByIdResponse>> {
        return ApiService.get('admin/vintage/' + id)
    },
})
