import { EmptyResponseDto } from '../../../src/api/common/dtos/EmptyResponseDto'
import { BridgedBlock } from '../types/BridgedBlock'
import { RetireBlocksRequestPayload } from '../types/RetireBlocksRequestPayload'
import { RetirementRequest } from '../types/RetirementRequest'
import { SaveRetirementRequestPayload } from '../types/SaveRetirementRequestPayload'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface RetirementRequestApiInterface {
    getAll(): Promise<AxiosResponse<RetirementRequest[]>>
    save(payload: SaveRetirementRequestPayload): Promise<AxiosResponse<EmptyResponseDto>>
    getByAsyncItemId(asyncItemId: string): Promise<AxiosResponse<RetirementRequest>>
    retireBlocks(payload: RetireBlocksRequestPayload): Promise<AxiosResponse<void>>
    getSourceBlocks(id: string): Promise<AxiosResponse<BridgedBlock[]>>
    failRetirement(id: string): Promise<AxiosResponse<EmptyResponseDto>>
    getAssociatedBlocks(
        asyncItemId: string,
        retirementRequestId: string,
    ): Promise<AxiosResponse<BridgedBlock[]>>
}

export const RetirementRequestApi: RetirementRequestApiInterface = Object.freeze({
    getAll(): Promise<AxiosResponse<RetirementRequest[]>> {
        return ApiService.get('/admin/retirement-request')
    },
    save(payload: SaveRetirementRequestPayload): Promise<AxiosResponse<EmptyResponseDto>> {
        return ApiService.post('/admin/retirement-request', payload)
    },
    getByAsyncItemId(asyncItemId: string): Promise<AxiosResponse<RetirementRequest>> {
        return ApiService.get('/admin/retirement-request/async-item', asyncItemId)
    },
    retireBlocks(payload: RetireBlocksRequestPayload): Promise<AxiosResponse<void>> {
        return ApiService.post('/admin/retirement-request/retire/source-blocks', payload)
    },
    getSourceBlocks(id: string): Promise<AxiosResponse<BridgedBlock[]>> {
        return ApiService.get('/admin/retirement-request/source-blocks', id)
    },
    failRetirement(id: string): Promise<AxiosResponse<EmptyResponseDto>> {
        return ApiService.post('/admin/retirement-request/fail', {
            async_item_id: id,
        })
    },
    getAssociatedBlocks(
        asyncItemId: string,
        retirementRequestId: string,
    ): Promise<AxiosResponse<BridgedBlock[]>> {
        return ApiService.get(
            '/admin/retirement-request/source-blocks/' + asyncItemId + '/' + retirementRequestId,
        )
    },
})
