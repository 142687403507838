import Layout from '../components/Layout'
import EditTenantCustomerRegistryIdForm from '../components/forms/EditTenantCustomerRegistryIdForm'

const AddEditTenantCustomerRegistryId = (): JSX.Element => {
    return (
        <Layout>
            <EditTenantCustomerRegistryIdForm />
        </Layout>
    )
}

export default AddEditTenantCustomerRegistryId
