import Layout from '../components/Layout'
import UnbrigingRequestsTable from '../components/UnbridgeRequest/UnbrigingRequestsTable'

const UnbridgingRequests = (): JSX.Element => {
    return (
        <Layout>
            <UnbrigingRequestsTable />
        </Layout>
    )
}

export default UnbridgingRequests
