import { ExpectedBlockStatus } from '../enums/ExpectedBLockStatus'
import { EmptyResponse } from '../types/EmptyResponse'
import { ExpectedBlock } from '../types/ExpectedBlock'
import { ExpectedBlockForBridging } from '../types/ExpectedBlockForBridging'
import { SaveExpectedBlockForExistingProjectRequest } from '../types/SaveExpectedBlockForExistingProjectRequest'
import { SaveExpectedBlockRequest } from '../types/SaveExpectedBlockRequest'
import { SearchExpectedBlocksResponse } from '../types/SearchExpectedBlocksResponse'
import { UpdateExpectedBlockRequest } from '../types/UpdateExpectedBlockRequest'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface ExpectedBlockInterface {
    search(
        page: number,
        perPage: number,
        registryId: string,
        status: ExpectedBlockStatus | string,
    ): Promise<AxiosResponse<SearchExpectedBlocksResponse>>
    save(payload: SaveExpectedBlockRequest): Promise<AxiosResponse<EmptyResponse>>
    saveAsDraft(payload: SaveExpectedBlockRequest): Promise<AxiosResponse<EmptyResponse>>
    saveForExistingProject(
        payload: SaveExpectedBlockForExistingProjectRequest,
    ): Promise<AxiosResponse<EmptyResponse>>
    onMarkAsExpectedBlockReadyForBridging(id: string): Promise<AxiosResponse<EmptyResponse>>
    getByid(id: string): Promise<AxiosResponse<ExpectedBlock>>
    getByIdForBridging(id: string): Promise<AxiosResponse<ExpectedBlockForBridging>>
    update(id: string, payload: UpdateExpectedBlockRequest): Promise<AxiosResponse<EmptyResponse>>
    duplicate(id: string): Promise<AxiosResponse<EmptyResponse>>
    cancel(id: string): Promise<AxiosResponse<EmptyResponse>>
}

export const ExpectedBlockApi: ExpectedBlockInterface = Object.freeze({
    search(
        page = 1,
        perPage = 10,
        registryId = '',
        status: ExpectedBlockStatus | string = '',
    ): Promise<AxiosResponse<SearchExpectedBlocksResponse>> {
        return ApiService.get(
            `/admin/expected-blocks?page=${page}&per_page=${perPage}&registry_id=${registryId}&status=${status}`,
        )
    },
    save(payload: SaveExpectedBlockRequest): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/expected-blocks', payload)
    },
    saveAsDraft(payload: SaveExpectedBlockRequest): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/expected-blocks/draft', payload)
    },
    saveForExistingProject(
        payload: SaveExpectedBlockForExistingProjectRequest,
    ): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('/admin/expected-blocks/existing-project', payload)
    },
    onMarkAsExpectedBlockReadyForBridging(id: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.get(`/admin/expected-blocks/${id}/mark-as-ready-for-bridging`)
    },
    getByid(id: string): Promise<AxiosResponse<ExpectedBlock>> {
        return ApiService.get(`/admin/expected-blocks/${id}`)
    },
    getByIdForBridging(id: string): Promise<AxiosResponse<ExpectedBlockForBridging>> {
        return ApiService.get(`/admin/expected-blocks/${id}/bridge`)
    },
    update(id: string, payload: SaveExpectedBlockRequest): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.patch(`/admin/expected-blocks/${id}`, payload)
    },
    duplicate(id: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post(`/admin/expected-blocks/duplicate/${id}`, {})
    },
    cancel(id: string): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post(`/admin/expected-blocks/cancel/${id}`, {})
    },
})
