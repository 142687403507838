import { WebhookApi } from '../api/webhook'
import ConfirmationModal from '../components/ConfirmationModal'
import { CopyButton } from '../components/CopyButton'
import { EditButton } from '../components/EditButton'
import Layout from '../components/Layout'
import { SelectTenant } from '../components/SelectTenant'
import XMarkIcon from '../components/XMarkIcon'
import { Page } from '../enums/Page'
import { SearchWebhooksResponse } from '../types/SearchWebhooksResponse'
import { Webhook } from '../types/Webhook'
import { Pagination, ToggleSwitch, Spinner } from 'flowbite-react'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useSearchParams } from 'react-router-dom'

const Webhooks = (): JSX.Element => {
    const [searchWebooksResponse, setSearchWebooksResponse] =
        useState<SearchWebhooksResponse | null>(null)
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(false)
    const [
        showChangeIsActiveConfirmationModalForWebhook,
        setShowChangeIsActiveConfirmationModalForWebhook,
    ] = useState<Webhook | null>(null)
    const [showDeleteConfirmationModalForWebhook, setShowDeleteConfirmationModalForWebhook] =
        useState<Webhook | null>(null)
    const navigate = useNavigate()

    const fetchWebhooks = async (): Promise<void> => {
        try {
            setIsLoading(true)
            const response = await WebhookApi.search({
                page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
                per_page: 6,
                tenant_id: searchParams.get('tenant_id') ?? undefined,
            })
            setSearchWebooksResponse(response.data)
        } finally {
            setIsLoading(false)
        }
        setIsLoading
    }

    useEffect(() => {
        fetchWebhooks()
    }, [searchParams])

    const groupedWebooks = searchWebooksResponse?.results.reduce(
        (acc: { [key: string]: Webhook[] }, current) => {
            const key = current.tenant.id
            const elements = acc[key] ?? []
            return {
                ...acc,
                [current.tenant.id]: [...elements, current],
            }
        },
        {},
    )

    return (
        <Layout className="h-screen">
            <div className="flex items-center w-full h-4 justify-center">
                {isLoading && <Spinner size="xl" />}
            </div>
            <div className="overflow-x-auto min-h-[500px]">
                <div className="flex flex-row gap-[100px]">
                    <button
                        className="px-4 h-10 py-2 flex justify-center items-center border border-black rounded-lg"
                        onClick={() => navigate(Page.CREATE_WEBHOOK)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="w-4 h-4"
                            viewBox="0 0 448 512"
                        >
                            <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                        </svg>
                        <p className="inline-block ml-2">Add Webhook</p>
                    </button>
                    <div className="flex flex-row items-center gap-5">
                        <span>Find by tenant name</span>
                        <SelectTenant
                            onSelect={(tenantId) => {
                                setSearchParams({ tenant_id: tenantId })
                            }}
                            value={searchParams.get('tenant_id') ?? undefined}
                            showClearButton={true}
                            onClearSearch={() => {
                                setSearchParams({
                                    page: searchParams.get('page') ?? '1',
                                })
                            }}
                            valueContainerStyles={{
                                minWidth: 200,
                            }}
                        />
                    </div>
                </div>
                <table className="w-full mt-6">
                    <thead>
                        <tr className="h-11 flex items-center w-full bg-gray-50">
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                ID
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                EVENT
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                IS ACTIVE
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                URL
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                CREATED_AT
                            </th>
                            <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                                ACTIONS
                            </th>
                        </tr>
                    </thead>
                    {Object.keys(groupedWebooks ?? []).map((key: string) => {
                        if (!!groupedWebooks && groupedWebooks[key].length) {
                            const webhooks = groupedWebooks[key]
                            return (
                                <tbody key={key}>
                                    <tr className="block mt-1">
                                        <td colSpan={6} className="flex bg-gray-200 pl-5 text-sm">
                                            <span className="w-[25%] text-left">
                                                <b>Tenant ID:</b>
                                                <span className="inline-block ml-2">
                                                    {webhooks[0].tenant.id}
                                                </span>
                                            </span>
                                            <span className="w-[25%] text-left">
                                                <b>Entity Name:</b>
                                                <span className="inline-block ml-2">
                                                    {webhooks[0].tenant.entity_name ?? '-'}
                                                </span>
                                            </span>
                                            <span className="w-[25%] text-left">
                                                <b>Tenant Type:</b>
                                                <span className="inline-block ml-2">
                                                    {webhooks[0].tenant.type}
                                                </span>
                                            </span>
                                        </td>
                                    </tr>
                                    {webhooks.map((webhook: Webhook) => {
                                        return (
                                            <tr
                                                className="h-18 flex items-center w-full border-b-2 border-gray-200"
                                                key={webhook.id}
                                            >
                                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                    <div className="flex gap-2 justify-between">
                                                        {webhook.id}
                                                        <CopyButton text={webhook.id} />
                                                    </div>
                                                </td>
                                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                    {webhook.event}
                                                </td>
                                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                    <ToggleSwitch
                                                        label="Is Active"
                                                        checked={webhook.is_active}
                                                        onChange={() => {
                                                            setShowChangeIsActiveConfirmationModalForWebhook(
                                                                webhook,
                                                            )
                                                        }}
                                                    />
                                                </td>
                                                <td
                                                    className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal"
                                                    title={webhook.url}
                                                >
                                                    <div className="flex gap-2 justify-between">
                                                        {webhook.url.length > 50
                                                            ? webhook.url.substring(0, 50) + '...'
                                                            : webhook.url}
                                                        <CopyButton text={webhook.url} />
                                                    </div>
                                                </td>
                                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                    {webhook.created_at}
                                                </td>
                                                <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                                    <div className="flex flex-row gap-5">
                                                        <button
                                                            title="Delete webhook"
                                                            onClick={() => {
                                                                setShowDeleteConfirmationModalForWebhook(
                                                                    webhook,
                                                                )
                                                            }}
                                                        >
                                                            <XMarkIcon />
                                                        </button>
                                                        <EditButton
                                                            onClick={() =>
                                                                navigate(
                                                                    generatePath(
                                                                        Page.EDIT_WEBHOOK,
                                                                        { id: webhook.id },
                                                                    ),
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            )
                        }
                    })}
                </table>
                {/* eslint-disable indent */}
                {searchWebooksResponse?.pagination?.total_pages &&
                    searchWebooksResponse?.pagination?.total_pages > 1 && (
                        <div className="mt-4">
                            <Pagination
                                currentPage={searchWebooksResponse.pagination.current_page}
                                totalPages={searchWebooksResponse.pagination.total_pages}
                                onPageChange={(pageNumber) =>
                                    setSearchParams({ page: String(pageNumber) })
                                }
                            />
                        </div>
                    )}
                <ConfirmationModal
                    question={
                        <div>
                            <p>
                                Are you sure you want to{' '}
                                {showChangeIsActiveConfirmationModalForWebhook?.is_active
                                    ? 'inactivate'
                                    : 'activate'}{' '}
                                webhook <b>{showChangeIsActiveConfirmationModalForWebhook?.id}</b>?
                            </p>
                        </div>
                    }
                    confirmButtonText="Confirm"
                    cancelButtonText="Cancel"
                    show={!!showChangeIsActiveConfirmationModalForWebhook}
                    onClose={() => setShowChangeIsActiveConfirmationModalForWebhook(null)}
                    onConfirm={async () => {
                        setIsLoading(true)
                        try {
                            if (showChangeIsActiveConfirmationModalForWebhook) {
                                await WebhookApi.setIsActive(
                                    showChangeIsActiveConfirmationModalForWebhook.id,
                                    !showChangeIsActiveConfirmationModalForWebhook.is_active,
                                )
                                await fetchWebhooks()
                                setShowChangeIsActiveConfirmationModalForWebhook(null)
                            }
                        } finally {
                            setIsLoading(false)
                        }
                    }}
                />

                <ConfirmationModal
                    question={
                        <div>
                            <p>
                                Are you sure you want to delete webhook{' '}
                                <b>{showChangeIsActiveConfirmationModalForWebhook?.id}</b>?
                            </p>
                        </div>
                    }
                    confirmButtonText="Confirm"
                    cancelButtonText="Cancel"
                    show={!!showDeleteConfirmationModalForWebhook}
                    onClose={() => setShowDeleteConfirmationModalForWebhook(null)}
                    onConfirm={async () => {
                        setIsLoading(true)
                        try {
                            if (showDeleteConfirmationModalForWebhook) {
                                await WebhookApi.delete(showDeleteConfirmationModalForWebhook.id)
                                // If there is only one result on the page and the user deletes the item, redirect them to the previous page.
                                if (
                                    searchWebooksResponse?.results.length === 1 &&
                                    searchParams.get('page') &&
                                    Number(searchParams.get('page')) > 1
                                ) {
                                    setSearchParams({
                                        page: searchParams.get('page')
                                            ? String(Number(searchParams.get('page')) - 1)
                                            : '1',
                                    })
                                }
                                await fetchWebhooks()
                                setShowDeleteConfirmationModalForWebhook(null)
                            }
                        } finally {
                            setIsLoading(false)
                        }
                    }}
                />
            </div>
        </Layout>
    )
}

export default Webhooks
