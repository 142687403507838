/* eslint-disable no-console */
import { RetirementRequestApi } from '../api/retirementRequest'
import { Block } from '../screens/Retirement'
import { BridgedBlock } from '../types/BridgedBlock'
import { BridgedBlockStatus } from '../types/BridgedBlockStatus'
import { RetirementRequest } from '../types/RetirementRequest'
import RetirementEventRetiredBlocks from './RetirementEventRetiredBlocks'
import RetirementEventSelectBlocks from './RetirementEventSelectBlocks'
import { useEffect, useState } from 'react'

interface RetirementEventSourceBlocksProps {
    blocks: Block[]
    onChange: (blocks: Block[]) => void
    asyncItemId?: string
    haveBlocksBeenRetired: boolean
    setHaveBlocksBeenRetired: (isRetired: boolean) => void
    canRetireBlocks: boolean
    retirementRequest: RetirementRequest | null
    setSerialsValidated: (serialsValidated: boolean) => void
}

const RetirementEventSourceBlocks = ({
    blocks,
    onChange,
    asyncItemId,
    haveBlocksBeenRetired,
    setHaveBlocksBeenRetired,
    canRetireBlocks,
    retirementRequest,
    setSerialsValidated,
}: RetirementEventSourceBlocksProps): JSX.Element => {
    const [bridgedBlocks, setBridgedBlocks] = useState<BridgedBlock[]>([])
    const [associatedBlocks, setAssociatedBlocks] = useState<BridgedBlock[]>([])

    const fetchBridgedBlocks = async (): Promise<void> => {
        if (asyncItemId && retirementRequest?.id !== void 0) {
            const response = await RetirementRequestApi.getSourceBlocks(asyncItemId)
            const retiredBlocks = response.data.filter(
                (block) =>
                    retirementRequest?.id && block.retirement_request_id === retirementRequest.id,
            )

            if (retiredBlocks.length === 0) {
                setBridgedBlocks(response.data)
                setHaveBlocksBeenRetired(false)
                return
            }
            if (retiredBlocks.length === 1) {
                setBridgedBlocks(retiredBlocks)
                setHaveBlocksBeenRetired(true)
                return
            }

            let parentBlockIds: string[] = []

            retiredBlocks.forEach((block) => {
                if (block.status !== BridgedBlockStatus.SUPERSEDED) return
                parentBlockIds.push(block.id)
            })!
            const childBlocks = response.data.filter(
                (block) => block.parent_id && parentBlockIds.includes(block.parent_id),
            )!
            setBridgedBlocks([...new Set([...retiredBlocks, ...childBlocks])])
            setHaveBlocksBeenRetired(true)
        }
    }

    const fetchAssociatedBlocks = async (): Promise<void> => {
        if (haveBlocksBeenRetired && asyncItemId && retirementRequest?.id) {
            const response = await RetirementRequestApi.getAssociatedBlocks(
                asyncItemId,
                retirementRequest.id,
            )
            setAssociatedBlocks(response.data)
        }
    }

    useEffect(() => {
        void fetchBridgedBlocks()
    }, [retirementRequest])

    useEffect(() => {
        void fetchAssociatedBlocks()
    }, [retirementRequest, haveBlocksBeenRetired])

    return haveBlocksBeenRetired ? (
        <RetirementEventRetiredBlocks
            bridgedBlocks={associatedBlocks}
            retirementRequest={retirementRequest}
        />
    ) : (
        <RetirementEventSelectBlocks
            blocks={blocks}
            onChange={onChange}
            bridgedBlocks={bridgedBlocks}
            canRetireBlocks={canRetireBlocks}
            retirementRequest={retirementRequest}
            setSerialsValidated={setSerialsValidated}
        />
    )
}

export default RetirementEventSourceBlocks
