import { UnbridgeRequestApi } from '../../api/unbridgeRequest'
import { Page } from '../../enums/Page'
import { UnbridgeRequest } from '../../types/unbridgeRequest/UnbridgeRequest'
import { UnbridgeRequestStatuses } from '../../types/unbridgeRequest/UnbridgeRequestStatuses'
import ConfirmationModal from '../ConfirmationModal'
import { CopyButton } from '../CopyButton'
import { BigNumber } from 'ethers'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

interface UnbridgingEventDetailsProps {
    unbridgingRequest: UnbridgeRequest
    hasBlockBeenUnbridged: boolean
}

const UnbridgingEventDetails = ({
    unbridgingRequest,
    hasBlockBeenUnbridged,
}: UnbridgingEventDetailsProps): JSX.Element => {
    const navigate = useNavigate()
    const [showFailUnbridgingConfirmationModal, setShowFailUnbridgingConfirmationModal] =
        useState<boolean>(false)

    const handleFailUnbridging = async (): Promise<void> => {
        if (unbridgingRequest.async_item_id) {
            await UnbridgeRequestApi.failUnbridgingRequest(unbridgingRequest.async_item_id)
        }
        navigate(Page.ASYNC_ITEMS)
    }

    const isUnbridgingInProgress = ![
        UnbridgeRequestStatuses.COMPLETE,
        UnbridgeRequestStatuses.FAILED,
        UnbridgeRequestStatuses.RETURNED,
    ].includes(unbridgingRequest.status || UnbridgeRequestStatuses.PENDING)

    return (
        <div>
            <table className="overflow-x-scroll md:overflow-auto block md:table md:table-auto w-full">
                <thead className="">
                    <tr className="h-11 items-center bg-[#3e3d3d] text-white divide-x-[1px] divide-x-stone-300">
                        <th className="font-semibold text-xs text-center px-4">id</th>
                        <th className="font-semibold text-xs text-center px-4">
                            On Chain Request ID
                        </th>
                        <th className="font-semibold text-xs text-center px-4">Async item ID</th>
                        <th className="font-semibold text-xs text-center px-4">Project/Vintage</th>
                        <th className="font-semibold text-xs text-center px-4">Amount</th>
                        <th className="font-semibold text-xs text-center px-4">
                            Unbridging Tenant
                        </th>
                        <th className="font-semibold text-xs text-center px-4">Status</th>
                        <th className="font-semibold text-xs text-center px-4">Unbridged At</th>
                        <th className="font-semibold text-xs text-center px-4">Actions</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className="h-18 items-center">
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {unbridgingRequest?.id && (
                                <div className="flex gap-2 justify-center">
                                    <p>{unbridgingRequest?.id}</p>
                                    <CopyButton text={unbridgingRequest?.id} />
                                </div>
                            )}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {unbridgingRequest?.on_chain_request_id && (
                                <div className="flex gap-2 justify-center">
                                    <p>{unbridgingRequest?.on_chain_request_id}</p>
                                    <CopyButton text={unbridgingRequest.on_chain_request_id} />
                                </div>
                            )}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {unbridgingRequest?.async_item_id && (
                                <div className="flex gap-2 justify-center">
                                    <p>{unbridgingRequest?.async_item_id}</p>
                                    <CopyButton text={unbridgingRequest.async_item_id} />
                                </div>
                            )}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {unbridgingRequest?.vintage?.project?.name} -{' '}
                            {unbridgingRequest?.vintage?.vintage}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {unbridgingRequest?.quantity
                                ? BigNumber.from(unbridgingRequest?.quantity).toString()
                                : ''}
                        </td>
                        <td className="text-sm text-center font-normal align-top justify-center px-[2px]">
                            {unbridgingRequest?.tenant.entity_name}
                        </td>
                        <td className="text-sm text-center font-normal align-top justify-center px-[2px]">
                            {unbridgingRequest?.status}
                        </td>
                        <td className="text-sm text-center font-normal align-top px-[2px]">
                            {unbridgingRequest?.unbridged_at && (
                                <div className="flex gap-2 justify-center">
                                    <p>{unbridgingRequest?.unbridged_at}</p>
                                    <CopyButton text={unbridgingRequest.unbridged_at} />
                                </div>
                            )}
                        </td>
                        <td className="text-sm text-center font-normal align-top justify-center px-[2px]">
                            {!hasBlockBeenUnbridged && isUnbridgingInProgress && (
                                <button
                                    className="px-4 py-2.5 rounded-lg text-white bg-[#3e3d3d]"
                                    onClick={() => setShowFailUnbridgingConfirmationModal(true)}
                                    disabled={showFailUnbridgingConfirmationModal}
                                >
                                    Fail Unbridge
                                </button>
                            )}
                        </td>
                    </tr>
                </tbody>
            </table>
            <ConfirmationModal
                show={showFailUnbridgingConfirmationModal}
                onClose={() => setShowFailUnbridgingConfirmationModal(false)}
                onConfirm={handleFailUnbridging}
                confirmButtonText="OK"
                cancelButtonText="Cancel"
            />
        </div>
    )
}

export default UnbridgingEventDetails
