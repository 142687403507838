import { CookieKey } from './enums/CookieKey'
import { CookieOptions } from './types/CookieOptions'
import moment from 'moment'

export function stringifyOptions(options: any): string {
    return Object.keys(options).reduce((acc: string, key: string) => {
        if (options[key] === false) {
            return acc
        } else if (options[key] === true) {
            return `${acc}; ${key}`
        } else {
            return `${acc}; ${key}=${options[key]}`
        }
    }, '')
}

export const getCookie = (name: CookieKey) => {
    return document.cookie.split('; ').reduce((r, v) => {
        const parts = v.split('=')
        return parts[0] === name ? decodeURIComponent(parts[1]) : r
    }, '')
}

export const setCookie = <T>(key: CookieKey, value: T, options: CookieOptions = {}): void => {
    const defaultExpiresIn = moment().add('30', 'minutes')

    document.cookie = `${key}=${value}${stringifyOptions({
        expires: defaultExpiresIn,
        path: '/',
        secure: true,
        sameSite: 'strict',
        ...options,
    })}`
}

export const deleteCookie = (name: CookieKey) => {
    document.cookie = `${name}=;${stringifyOptions({
        expires: 'Thu, 01 Jan 1970 00:00:01 GMT;`',
        path: '/',
        secure: true,
        sameSite: 'strict',
    })}`
}
