export enum RegistryIds {
    BIOCARBON = '005h8koa2ioo0qgq3x',
    PURO_EARTH = '0052eu3ppeig9bzhje',
    CENTIGRADE = '005xyz1wmeab8kjfos',
    SOCIAL_CARBON = '005c5sqk87b8fdjtmv',
    VERRA = '0050yd569dw0qbw5oy',
    GOLD_STANDARD = '005a86q13pgj9c3612',
}

export const registryNames = {
    [RegistryIds.BIOCARBON]: 'BioCarbon',
    [RegistryIds.PURO_EARTH]: 'Puro Earth',
    [RegistryIds.CENTIGRADE]: 'Centigrade',
    [RegistryIds.SOCIAL_CARBON]: 'Social Carbon',
    [RegistryIds.VERRA]: 'Verra',
    [RegistryIds.GOLD_STANDARD]: 'Gold Standard',
}
