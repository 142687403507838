import { Page } from '../enums/Page'
import AddEditTenantCustomerRegistryId from '../screens/AddEditTenantCustomerRegistryId'
import AddTenantCustomer from '../screens/AddTenantCustomer'
import AsyncItems from '../screens/AsyncItems'
import Bridge from '../screens/Bridge'
import CreateExpectedBlock from '../screens/CreateExpectedBlock'
import CreateUnbridgeRequest from '../screens/CreateUnbridgeRequest'
import CreateUser from '../screens/CreateUser'
import CreateWebhook from '../screens/CreateWebhook'
import EditExpectedBLock from '../screens/EditExpectedBlock'
import EditTenantCustomer from '../screens/EditTenantCustomer'
import EditWebhook from '../screens/EditWebhook'
import ExpectedBlocks from '../screens/ExpectedBlocks'
import ManageTenantCustomers from '../screens/ManageTenantCustomers'
import NotFound from '../screens/NotFound'
import Retire from '../screens/Retire'
import Retirement from '../screens/Retirement'
import RetirementRequests from '../screens/RetirementRequests'
import SignIn from '../screens/SignIn'
import Unbridging from '../screens/Unbridging'
import UnbridgingRequests from '../screens/UnbridgingRequests'
import UpdateTenant from '../screens/UpdateTenant'
import UpdateUser from '../screens/UpdateUser'
import Users from '../screens/Users'
import Webhooks from '../screens/Webhooks'
import RouterGuard from './RouteGuard'
import { Route, Routes } from 'react-router-dom'

interface Route {
    path: Page | '*'
    component: JSX.Element
    isAuthenticatedUserArea?: boolean
}

const RouterWrapper = (): JSX.Element | null => {
    const routes: Route[] = [
        {
            path: Page.SIGN_IN,
            component: <SignIn />,
            isAuthenticatedUserArea: false,
        },
        {
            path: Page.ASYNC_ITEMS,
            component: <AsyncItems />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.BRIDGE,
            component: <Bridge />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.RETIRE,
            component: <Retire />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.USERS,
            component: <Users />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.CREATE_USER,
            component: <CreateUser />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.UPDATE_USER,
            component: <UpdateUser />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.UPDATE_TENANT,
            component: <UpdateTenant />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.RETIREMENT,
            component: <Retirement />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.RETIREMENT_REQUESTS,
            component: <RetirementRequests />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.EXPECTED_BLOCKS,
            component: <ExpectedBlocks />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.CREATE_EXPECTED_BLOCKS,
            component: <CreateExpectedBlock />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.EDIT_EXPECTED_BLOCKS,
            component: <EditExpectedBLock />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.WEBHOOKS,
            component: <Webhooks />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.CREATE_WEBHOOK,
            component: <CreateWebhook />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.EDIT_WEBHOOK,
            component: <EditWebhook />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.CREATE_UNBRIDGE_REQUEST,
            component: <CreateUnbridgeRequest />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.UNBRIDGING_EVENT,
            component: <Unbridging />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.UNBRIDGE_REQUESTS,
            component: <UnbridgingRequests />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.MANAGE_TENANT_CUSTOMERS,
            component: <ManageTenantCustomers />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ADD_TENANT_CUSTOMER,
            component: <AddTenantCustomer />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.EDIT_TENANT_CUSTOMER,
            component: <EditTenantCustomer />,
            isAuthenticatedUserArea: true,
        },
        {
            path: Page.ADD_TENANT_CUSTOMER_REGISTRY_ID,
            component: <AddEditTenantCustomerRegistryId />,
            isAuthenticatedUserArea: true,
        },
        {
            path: '*',
            component: <NotFound />,
            isAuthenticatedUserArea: true,
        },
    ]

    return (
        <Routes>
            {routes.map((route) => {
                return (
                    <Route
                        key={route.path}
                        path={route.path}
                        element={
                            <RouterGuard isAuthenticatedUserArea={route.isAuthenticatedUserArea}>
                                {route.component}
                            </RouterGuard>
                        }
                    />
                )
            })}
        </Routes>
    )
}
export default RouterWrapper
