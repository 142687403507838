import { SDG } from '../enums/SDG'

interface SdgLogoProps {
    sdg: SDG
    className?: string
}

export enum SdgIconEnum {
    'No Poverty' = '1-no-poverty.svg',
    'Zero Hunger' = '2-zero-hunger.svg',
    'Health and Well Being' = '3-good-health-and-well-being.svg',
    'Quality Education' = '4-quality-education.svg',
    'Gender Equality' = '5-gender-equality.svg',
    'Water Benefit' = '6-clean-water-and-sanitation.svg',
    'Clean Energy' = '7-affordable-and-clean-energy.svg',
    'Economic Growth' = '8-decent-work-and-economic-growth.svg',
    'Infrastructure' = '9-industry-innovation-and-infrastructure.svg',
    'Reduced Inequalities' = '10-reduced-inequalities.svg',
    'Sustainable Cities' = '11-sustainable-cities-and-communities.svg',
    'Responsible Consumption and Production' = '12-resposible-consumption-and-production.svg',
    'Climate Action' = '13-climate-action.svg',
    'Life Below Water' = '14-life-below-water.svg',
    'Life On Land' = '15-life-on-land.svg',
    'Peace and Justice' = '16-peace-justice-and-strong-institutions.svg',
    'Partnerships for the Goals' = '17-partnerships-for-the-goals.svg',
}

const SdgLogo = ({ sdg, className }: SdgLogoProps): JSX.Element => {
    return (
        <div className={`w-6 h-6 ${className}`}>
            <img className="w-6 h-6" src={`/assets/sdg_icons/${SdgIconEnum[sdg]}`} />
        </div>
    )
}

export default SdgLogo
