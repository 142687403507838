import { CookieKey } from '../storage/enums/CookieKey'
import { getCookie } from '../storage/useCookie'
import axios, { AxiosInstance, AxiosRequestHeaders, AxiosResponse } from 'axios'

export const CreateApiClient = async (): Promise<AxiosInstance> => {
    const headers: AxiosRequestHeaders | {} = {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: `Bearer ${getCookie(CookieKey.API_KEY)}`,
    }

    const apiClient = axios.create({
        baseURL: getCookie(CookieKey.API_URL),
        withCredentials: true,
        headers,
        timeout: 10000,
    })

    apiClient.interceptors.response.use(
        (response: AxiosResponse) => {
            return response
        },
        async function (error: Error) {
            return Promise.reject(error)
        },
    )

    return apiClient
}
