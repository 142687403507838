import { CopyButton } from '../../CopyButton'
import { UnbridgeEventTypesViewItemProps } from './UnbridgeEventTypes'
import { ChangeEvent } from 'react'
import Select, { ActionMeta, SingleValue } from 'react-select'

const TableViewRow = ({
    index,
    selectedBlock,
    getSelectOptions,
    onChangeSelectSerialInputHandler,
    getSelectFilterOptions,
    onRemoveBlockHandler,
    onChangeAmountToUnbridgeForSelectedBlockHandler,
    onBlurBlockInput,
    validationUnbridgingBlocksData,
}: UnbridgeEventTypesViewItemProps): JSX.Element => {
    const handleFocus = (e: React.FocusEvent<HTMLInputElement>): void => e.target.select()
    const currentErrorMessage = (fieldName: string) =>
        validationUnbridgingBlocksData?.errors.find((i) => i.fieldName === fieldName)?.message
    const currentWarningMessage = (fieldName: string) =>
        validationUnbridgingBlocksData?.warnings.find((i) => i.fieldName === fieldName)?.message
    return (
        <>
            <tr className="items-center w-full" key={index}>
                <td className="w-[250px] flex text-sm text-center font-normal px-2 align-middle relative">
                    <CopyButton text={selectedBlock.serial ?? ''}></CopyButton>
                    <div id="select-wrapper">
                        <Select
                            menuPortalTarget={document.getElementById('select-wrapper')}
                            defaultValue={
                                /* eslint-disable indent */
                                selectedBlock.serial !== ''
                                    ? { label: selectedBlock.serial, value: index }
                                    : {
                                          label: 'Select',
                                          value: index,
                                      }
                            }
                            className="md:block w-[220px] mx-2 text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            backspaceRemovesValue={true}
                            isClearable={true}
                            components={{
                                DropdownIndicator: () => null,
                                IndicatorSeparator: () => null,
                            }}
                            styles={{
                                option: (defaultOptions, state) => ({
                                    ...defaultOptions,
                                    backgroundColor: state.isFocused
                                        ? 'rgb(178, 212, 255)'
                                        : 'white',
                                    color: 'black',
                                }),
                            }}
                            options={getSelectOptions()}
                            onChange={(
                                opt: SingleValue<{ value: number; label: string }>,
                                action: ActionMeta<{ value: number; label: string }>,
                            ) => onChangeSelectSerialInputHandler(index, opt, action)}
                            filterOption={getSelectFilterOptions}
                        ></Select>
                    </div>
                </td>
                <td className="min-w-[150px] text-sm text-center font-normal px-2 align-middle">
                    {selectedBlock.id}
                </td>
                <td className="min-w-[150px] text-sm text-center font-normal px-2 align-middle">
                    {selectedBlock.registry}
                </td>
                <td className="min-w-[150px] text-sm text-center font-normal px-2 align-middle">
                    {selectedBlock.amount}
                </td>
                <td className="min-w-[200] text-sm text-center font-normal px-2 align-middle">
                    <input
                        type="number"
                        onFocus={handleFocus}
                        step={1}
                        min={1}
                        max={selectedBlock.amount && Number(selectedBlock.amount)}
                        value={selectedBlock.unbridgingAmountForThisBlock}
                        className="w-full h-11 w-30 rounded-lg border-gray-300"
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeAmountToUnbridgeForSelectedBlockHandler(
                                'unbridgingAmountForThisBlock',
                                Number(e.target.value.replace(/\s/g, '')),
                                index,
                            )
                        }
                        onBlur={() => onBlurBlockInput(index, 'unbridgingAmountForThisBlock')}
                    />
                </td>
                <td className=" min-w-[240px] text-sm text-center font-normal px-2 align-middle">
                    <input
                        type="text"
                        value={selectedBlock.unbridgingBlockSerial}
                        className={`h-11 w-40 rounded-lg mx-auto   ${
                            !!currentErrorMessage('unbridgingBlockSerial')
                                ? 'border-red-300'
                                : 'border-gray-300'
                        }`}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeAmountToUnbridgeForSelectedBlockHandler(
                                'unbridgingBlockSerial',
                                e.target.value,
                                index,
                            )
                        }
                        onBlur={() => onBlurBlockInput(index, 'unbridgingBlockSerial')}
                    />
                </td>
                <td className="min-w-[240px] text-sm text-center font-normal px-2 align-middle">
                    <input
                        type="text"
                        value={selectedBlock.remainingBlockSerial}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            onChangeAmountToUnbridgeForSelectedBlockHandler(
                                'remainingBlockSerial',
                                e.target.value,
                                index,
                            )
                        }
                        className={`h-11 w-40 rounded-lg mx-auto ${
                            !!currentErrorMessage('remainingBlockSerial') && 'border-red-300'
                        } ${
                            !!currentWarningMessage('remainingBlockSerial')
                                ? 'border-orange-400'
                                : 'border-gray-300'
                        }`}
                        onBlur={() => onBlurBlockInput(index, 'remainingBlockSerial')}
                    />
                </td>
                <td className="text-sm text-center font-normal px-2 align-middle">
                    <button
                        className="px-4 py-2.5 rounded-lg text-white bg-[#3e3d3d]"
                        onClick={() => onRemoveBlockHandler(index)}
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-6 h-6"
                        >
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M6 18L18 6M6 6l12 12"
                            />
                        </svg>
                    </button>
                </td>
            </tr>
            <tr className="items-center w-full" key={String(index) + 'infoRow'}>
                <td className="w-[250px] flex text-sm text-center font-normal px-2 align-middle relative"></td>
                <td className="min-w-[150px] text-sm text-center font-normal px-2 align-middle"></td>
                <td className="min-w-[150px] text-sm text-center font-normal px-2 align-middle"></td>
                <td className="min-w-[150px] text-sm text-center font-normal px-2 align-middle"></td>
                <td className="min-w-[200] text-sm text-center font-normal px-2 align-middle"></td>
                <td className=" min-w-[240px] text-sm text-center font-normal px-2 align-middle">
                    {currentErrorMessage('unbridgingBlockSerial') && (
                        <span className="text-red-600 w-[200px] mx-auto text-center">
                            {currentErrorMessage('unbridgingBlockSerial')}
                        </span>
                    )}
                </td>
                <td className="min-w-[240px] text-sm text-center font-normal px-2 align-middle">
                    {currentErrorMessage('remainingBlockSerial') && (
                        <span className="text-red-600 w-[200px] mx-auto text-center">
                            {currentErrorMessage('remainingBlockSerial')}
                        </span>
                    )}
                    {currentWarningMessage('remainingBlockSerial') && (
                        <span className="text-primary-600 w-[200px] mx-auto text-center">
                            {currentWarningMessage('remainingBlockSerial')}
                        </span>
                    )}
                </td>
                <td className="text-sm text-center font-normal px-2 align-middle"></td>
            </tr>
        </>
    )
}

export default TableViewRow
