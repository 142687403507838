import { ExpectedBlockApi } from '../../api/expectedBlock'
import { ProjectApi } from '../../api/project'
import { RegistryApi } from '../../api/registry'
import { TenantApi } from '../../api/tenant'
import { TenantCustomerApi } from '../../api/tenantCustomer'
import { ExpectedBlockStatus } from '../../enums/ExpectedBLockStatus'
import { InvoiceStatus } from '../../enums/InvoiceStatus'
import { Page } from '../../enums/Page'
import { ProjectLocation } from '../../enums/ProjectLocation'
import { SDG } from '../../enums/SDG'
import { ErrorResponse } from '../../types/ErrorResponse'
import { ProjectType } from '../../types/ProjectType'
import { Registry } from '../../types/Registry'
import { Tenant } from '../../types/Tenant'
import { TenantCustomer } from '../../types/TenantCustomer'
import { convertUsdToCents } from '../../utils/convertUsdToCents'
import { getCountryNameFromCountryCode } from '../../utils/getCountryNameFromCountryCode'
import { sortArrByStringProp } from '../../utils/sortArrByStringProp'
import CheckBoxWrapper from '../CheckBoxWrapper'
import ErrorLabel from '../ErrorLabel'
import NumberInput from '../NumberInput'
import SdgLogo from '../SdgLogo'
import SelectedExistingProjectAndVintage, {
    SelectExistingProjectError,
} from '../SelectedExistingProjectAndVintage'
import { AxiosError } from 'axios'
import { Spinner } from 'flowbite-react'
import { useEffect, useState, ChangeEvent } from 'react'
import { useNavigate } from 'react-router-dom'

export interface CreateExpectedBlockForm {
    project_name: string
    project_description: string
    type: string
    registry_id: string
    registry_url: string
    registry_project_id: string
    vintage: string
    vintage_registry_url: string
    amount: string
    price_paid_per_credit?: string
    invoice_reference?: string
    invoice_status?: InvoiceStatus
    sdgs: SDG[]
    beneficiary_tenant_id: string
    tenant_customer_id: string | null
    location?: ProjectLocation | 'Choose'
}

export interface CreateExpectedBlockFormErrors {
    amount: string
    price_paid_per_credit: string
    invoice_status: string
    invoice_reference: string
    project_name: string
    project_type: string
    project_description: string
    registry_id: string
    registry_url: string
    registry_project_id: string
    vintage: string
    vintage_registry_url: string
    sdgs: string
    location: string
    beneficiary_tenant_id: string
    tenant_customer_id: string
}

const CreateExpectedBlockForm = () => {
    const [registries, setRegistries] = useState<Registry[]>([])
    const [error, setError] = useState<string | null>(null)
    const [beneficiaryTenants, setBeneficiaryTenants] = useState<Tenant[]>([])
    const [tenantCustomers, setTenantCustomers] = useState<TenantCustomer[]>([])
    const [projectTypes, setProjectTypes] = useState<ProjectType[]>([])
    const [selectedButton, setSelectedButton] = useState<'create' | 'select'>('create')
    const [selectExistingProjectError, setSelectExistingProjectError] =
        useState<SelectExistingProjectError | null>(null)
    const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null)
    const [formData, setFormData] = useState<Partial<CreateExpectedBlockForm>>({})
    const [formError, setFormError] = useState<Partial<CreateExpectedBlockFormErrors>>({})
    const [showSpinner, setShowSpinner] = useState<boolean>(false)

    const navigate = useNavigate()

    const fetchRegistries = async () => {
        const response = await RegistryApi.getAll()
        setRegistries(response.data)
    }

    const fetchBeneficiaries = async () => {
        const response = await TenantApi.getDevelopers()
        setBeneficiaryTenants(response.data)
    }

    const fetchProjectTypes = async () => {
        if (formData.registry_id) {
            const response = await ProjectApi.getProjectTypesByRegistryId(formData.registry_id)
            sortArrByStringProp(response.data, 'project_type')
            setProjectTypes(response.data)
        }
    }

    const fetchTenantCustomers = async () => {
        if (formData.beneficiary_tenant_id) {
            const response = await TenantCustomerApi.getTenantCustomersByTenantId(
                formData.beneficiary_tenant_id,
            )
            setTenantCustomers(response.data.tenant_customers)
        }
    }

    const renderAvailableVintages = (): JSX.Element[] => {
        const vintages = []
        for (let i = 2010; i <= new Date().getFullYear() + 1; i++) {
            vintages.push(String(i))
        }
        return vintages.map((vintage: string) => {
            return (
                <option key={vintage} value={vintage}>
                    {vintage}
                </option>
            )
        })
    }

    useEffect(() => {
        fetchProjectTypes()
    }, [formData.registry_id])

    useEffect(() => {
        fetchTenantCustomers()
    }, [formData.beneficiary_tenant_id])

    useEffect(() => {
        fetchRegistries()
        fetchBeneficiaries()
    }, [])

    const saveForExistingProject = async () => {
        if (formData.amount && Number(formData.amount) <= 0) {
            setFormError({ amount: 'Expected amount must be greater than 0' })
            return
        }
        await ExpectedBlockApi.saveForExistingProject({
            registry_id: formData.registry_id,
            registry_project_id: selectedProject?.registry_project_id,
            project_id: selectedProject?.id,
            vintage: formData.vintage ? Number(formData.vintage) : undefined,
            ...(formData.vintage_registry_url && {
                vintage_registry_url: formData.vintage_registry_url.trim(),
            }),
            price_paid_per_credit: formData.price_paid_per_credit
                ? String(convertUsdToCents(Number(formData.price_paid_per_credit)))
                : null,
            expected_amount: formData.amount,
            status: ExpectedBlockStatus.NOT_READY,
            invoice_status: formData.invoice_status,
            invoice_reference: formData.invoice_reference?.trim(),
            sdgs: formData.sdgs,
            beneficiary_tenant_id: formData.beneficiary_tenant_id,
            tenant_customer_id: formData.tenant_customer_id || null,
        })
        navigate(Page.EXPECTED_BLOCKS)
    }

    const saveAndValidateForExistingProject = async () => {
        if (!formData.vintage || formData.vintage === 'Choose') {
            setSelectExistingProjectError(SelectExistingProjectError.NO_VINTAGE_SELECTED)
            return
        }
        if (!formData.amount) {
            setFormError({ amount: 'Expected amount is required' })
            return
        }
        if (Number(formData.amount) <= 0) {
            setFormError({ amount: 'Expected amount must be greater than 0' })
            return
        }
        if (!formData.beneficiary_tenant_id) {
            setFormError({ beneficiary_tenant_id: 'Beneficiary tenant ID is required' })
            return
        }
        if (tenantCustomers.length > 0 && !formData.tenant_customer_id) {
            setFormError({
                tenant_customer_id:
                    'Tenant customer ID is required when beneficiary tenant has customers',
            })
            return
        }
        setFormError({})
        navigate(Page.EXPECTED_BLOCKS)
        await ExpectedBlockApi.saveForExistingProject({
            registry_id: formData.registry_id,
            registry_project_id: formData.registry_project_id?.trim(),
            project_id: selectedProject?.id,
            vintage: Number(formData.vintage),
            vintage_registry_url: formData.vintage_registry_url
                ? formData.vintage_registry_url.trim()
                : '',
            price_paid_per_credit: formData.price_paid_per_credit
                ? String(convertUsdToCents(Number(formData.price_paid_per_credit)))
                : null,
            expected_amount: formData.amount,
            status: ExpectedBlockStatus.READY_FOR_BRIDGING,
            invoice_status: formData.invoice_status ?? undefined,
            invoice_reference: formData.invoice_reference?.trim(),
            beneficiary_tenant_id: formData.beneficiary_tenant_id,
            tenant_customer_id: formData.tenant_customer_id || null,
        })
    }

    const save = async () => {
        if (!formData.project_name) {
            setFormError({ project_name: 'Project name is required' })
            return
        }
        if (formData.price_paid_per_credit && Number(formData.price_paid_per_credit) <= 0) {
            setFormError({ price_paid_per_credit: 'Price Paid Per Credit must be greater than 0' })
            return
        }
        if (formData.amount && Number(formData.amount) <= 0) {
            setFormError({ amount: 'Expected amount must be greater than 0' })
            return
        }

        await ExpectedBlockApi.saveAsDraft({
            registry_id: formData.registry_id,
            registry_project_id: formData.registry_project_id?.trim(),
            project_name: formData?.project_name?.trim(),
            project_type: formData?.type,
            project_description: formData?.project_description?.trim(),
            vintage: formData.vintage ? Number(formData.vintage) : undefined,
            vintage_registry_url: formData.vintage_registry_url
                ? formData.vintage_registry_url.trim()
                : '',
            price_paid_per_credit: formData.price_paid_per_credit
                ? String(convertUsdToCents(Number(formData.price_paid_per_credit)))
                : null,
            expected_amount: formData.amount,
            invoice_status: formData.invoice_status as InvoiceStatus,
            invoice_reference: formData.invoice_reference?.trim(),
            sdgs: formData.sdgs,
            location: formData.location,
            registry_url: formData.registry_url?.trim(),
            beneficiary_tenant_id: formData.beneficiary_tenant_id,
            tenant_customer_id: formData.tenant_customer_id || null,
        })
        navigate(Page.EXPECTED_BLOCKS)
    }

    const saveAndValidate = async () => {
        setFormError({})
        if (!formData.project_name) {
            setFormError({ project_name: 'Project name is required' })
            return
        }
        if (!formData.registry_id || formData.registry_id === 'Choose') {
            setFormError({ registry_id: 'Registry is required' })
            return
        }
        if (!formData.type || formData.type === 'Choose') {
            setFormError({ project_type: 'Project type is required' })
            return
        }
        if (!formData.location || formData.location === 'Choose') {
            setFormError({ location: 'Location is required' })
            return
        }
        if (!formData.project_description) {
            setFormError({ project_description: 'Project description is required' })
            return
        }
        if (!formData.registry_url) {
            setFormError({ registry_url: 'Registry URL is required' })
            return
        }
        if (!formData.registry_project_id) {
            setFormError({ registry_project_id: 'Registry project ID is required' })
            return
        }
        if (!formData.vintage || formData.vintage === 'Choose') {
            setFormError({ vintage: 'Vintage is required' })
            return
        }
        if (!formData.amount) {
            setFormError({ amount: 'Expected amount is required' })
            return
        }
        if (Number(formData.amount) <= 0) {
            setFormError({ amount: 'Expected amount must be greater than 0' })
            return
        }
        if (!formData.beneficiary_tenant_id) {
            setFormError({ beneficiary_tenant_id: 'Beneficiary tenant ID is required' })
            return
        }
        if (tenantCustomers.length > 0 && !formData.tenant_customer_id) {
            setFormError({
                tenant_customer_id:
                    'Tenant customer ID is required when beneficiary tenant has customers',
            })
            return
        }
        setFormError({})
        await ExpectedBlockApi.save({
            registry_id: formData.registry_id,
            registry_project_id: formData.registry_project_id?.trim(),
            project_name: formData?.project_name?.trim(),
            project_type: formData?.type,
            project_description: formData?.project_description?.trim(),
            vintage: formData.vintage ? Number(formData.vintage) : undefined,
            vintage_registry_url: formData.vintage_registry_url
                ? formData.vintage_registry_url.trim()
                : '',
            price_paid_per_credit: formData.price_paid_per_credit
                ? String(convertUsdToCents(Number(formData.price_paid_per_credit)))
                : null,
            expected_amount: formData.amount,
            invoice_status: formData.invoice_status,
            invoice_reference: formData.invoice_reference?.trim(),
            sdgs: formData.sdgs,
            location: formData.location,
            registry_url: formData.registry_url,
            beneficiary_tenant_id: formData.beneficiary_tenant_id,
            tenant_customer_id: formData.tenant_customer_id || null,
        })
        navigate(Page.EXPECTED_BLOCKS)
    }

    const onSubmit = async (action: 'save' | 'saveAndValidate'): Promise<void> => {
        setShowSpinner(true)
        try {
            if (selectedButton === 'create') {
                if (action === 'save') {
                    await save()
                } else {
                    await saveAndValidate()
                }
            } else {
                if (action === 'save') {
                    await saveForExistingProject()
                } else {
                    setSelectExistingProjectError(null)
                    if (!selectedProject) {
                        setSelectExistingProjectError(
                            SelectExistingProjectError.NO_PROJECT_SELECTED,
                        )
                        return
                    }
                    await saveAndValidateForExistingProject()
                }
            }
            setError(null)
        } catch (e) {
            const err = e as AxiosError<ErrorResponse>
            if (err.response?.data?.message) {
                setError(String(err.response?.data?.message))
            }
        } finally {
            setShowSpinner(false)
        }
    }

    const getCurrentVintageUrl = (vintage: number) => {
        if (selectedProject) {
            const currentVintage = selectedProject.vintages.find((i) => i.vintage === vintage)
            if (!currentVintage) {
                return ''
            }
            return currentVintage.registry_url
        }
        return ''
    }

    return (
        <form>
            <div>
                <div className="flex gap-5 justify-center my-2">
                    <button
                        type="button"
                        className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                            selectedButton === 'create' ? 'bg-primary-600' : 'bg-primary-100'
                        }`}
                        onClick={() => setSelectedButton('create')}
                    >
                        Create Project
                    </button>
                    <button
                        type="button"
                        className={`h-12 rounded-lg w-50 text-white font-semibold text-base ${
                            selectedButton === 'select' ? 'bg-primary-600' : 'bg-primary-100'
                        }`}
                        onClick={() => setSelectedButton('select')}
                    >
                        Select Project
                    </button>
                </div>
            </div>

            {selectedButton === 'create' && (
                <div>
                    <h3 className="bg-gray-300 text-left mb-5">Project</h3>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Project Name
                        </label>
                        <input
                            placeholder="Name"
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setFormData({
                                    ...formData,
                                    project_name: e.target.value,
                                })
                            }
                            className="block p-4 my-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        />
                        <div className="h-5">
                            {formError?.project_name && (
                                <ErrorLabel error={formError.project_name} />
                            )}
                        </div>
                    </div>

                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/3 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Registry
                            </label>
                            <select
                                value={formData.registry_id ?? ''}
                                className="block p-2 my-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setFormData({
                                        ...formData,
                                        registry_id: e.target.value,
                                    })
                                }}
                            >
                                <option>Choose</option>
                                {registries.map((registry: Registry) => (
                                    <option key={registry.id} value={registry.id}>
                                        {registry.name}
                                    </option>
                                ))}
                            </select>
                            <div className="h-5">
                                {formError?.registry_id && (
                                    <ErrorLabel error={formError.registry_id} />
                                )}
                            </div>
                        </div>
                        <div className="w-1/3 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Project type
                            </label>
                            <select
                                value={formData.type ?? ''}
                                className="block p-2 my-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setFormData({
                                        ...formData,
                                        type: e.target.value,
                                    })
                                }}
                            >
                                <option>Choose</option>
                                {projectTypes.map((projectType) => (
                                    <option key={projectType.id} value={projectType.project_type}>
                                        {projectType.project_type}
                                    </option>
                                ))}
                            </select>
                            <div className="h-5">
                                {formError?.project_type && (
                                    <ErrorLabel error={formError.project_type} />
                                )}
                            </div>
                        </div>
                        <div className="w-1/3 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium">Location</label>
                            <select
                                className="block p-2 mb-6 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                    setFormData({
                                        ...formData,
                                        location: e.target.value as ProjectLocation,
                                    })
                                }}
                            >
                                <option>Choose</option>
                                {(
                                    Object.keys(ProjectLocation) as Array<
                                        keyof typeof ProjectLocation
                                    >
                                ).map((key) => (
                                    <option key={key} value={ProjectLocation[key]}>
                                        {getCountryNameFromCountryCode(ProjectLocation[key])}
                                    </option>
                                ))}
                            </select>
                            {formError?.location && <ErrorLabel error={formError.location} />}
                        </div>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">
                            Project Description
                        </label>
                        <textarea
                            rows={5}
                            id="large-input"
                            placeholder="Desription"
                            className="block p-4 my-2 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                                setFormData({
                                    ...formData,
                                    project_description: e.target.value,
                                })
                            }}
                        />
                        <div className="h-5">
                            {formError?.project_description && (
                                <ErrorLabel error={formError.project_description} />
                            )}
                        </div>
                    </div>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Registry Url
                            </label>
                            <input
                                type="text"
                                className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Registry Url"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFormData({
                                        ...formData,
                                        registry_url: e.target.value,
                                    })
                                }}
                            />
                            <div className="h-5">
                                {formError?.registry_url && (
                                    <ErrorLabel error={formError.registry_url} />
                                )}
                            </div>
                        </div>
                        <div className="w-1/2 mb-6 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Registry Project Id
                            </label>
                            <input
                                type="text"
                                className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                placeholder="Registry Project Id"
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setFormData({
                                        ...formData,
                                        registry_project_id: e.target.value,
                                    })
                                }}
                            />
                            <div className="h-5">
                                {formError?.registry_project_id && (
                                    <ErrorLabel error={formError.registry_project_id} />
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="mb-6 text-left">
                        <label className="mb-2 text-sm font-medium text-gray-900">SDGs</label>
                        <div>
                            <div className="flex flex-wrap mt-4">
                                {(Object.keys(SDG) as Array<keyof typeof SDG>).map((key) => {
                                    return (
                                        <div className="w-1/4" key={key}>
                                            <CheckBoxWrapper
                                                key={key}
                                                checked={formData.sdgs?.includes(SDG[key])}
                                                label={
                                                    <div className="flex">
                                                        <SdgLogo sdg={SDG[key]} />
                                                        {SDG[key]}
                                                    </div>
                                                }
                                                onChange={(checked: boolean) => {
                                                    if (checked) {
                                                        setFormData({
                                                            ...formData,
                                                            sdgs: [
                                                                ...(formData.sdgs ?? []),
                                                                SDG[key],
                                                            ],
                                                        })
                                                    } else {
                                                        setFormData({
                                                            ...formData,
                                                            sdgs: formData.sdgs?.filter(
                                                                (sdg) => sdg !== SDG[key],
                                                            ),
                                                        })
                                                    }
                                                }}
                                            />
                                        </div>
                                    )
                                })}
                            </div>
                            <div className="mt-2 h-5">
                                {formError?.sdgs && <ErrorLabel error={formError.sdgs} />}
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {selectedButton === 'select' && (
                <SelectedExistingProjectAndVintage
                    selectExistingProjectError={selectExistingProjectError}
                    onProjectSelected={(project) => setSelectedProject(project)}
                    onSelectedVintage={(vintage) => {
                        setFormData({
                            ...formData,
                            vintage: vintage,
                            /* eslint-disable indent */
                            ...(selectedProject &&
                                getCurrentVintageUrl(Number(vintage)) && {
                                    vintage_registry_url: getCurrentVintageUrl(Number(vintage)),
                                }),
                        })
                    }}
                    onChangeVintageRegistryUrl={(vintageRegistryUrl) =>
                        setFormData({ ...formData, vintage_registry_url: vintageRegistryUrl })
                    }
                    hideRegistryUrlInput={false}
                />
            )}
            {selectedButton === 'create' && (
                <div>
                    <div>
                        <h3 className="bg-gray-300 text-left mb-5">Vintage</h3>
                        <div className="flex flex-row gap-4 justify-between">
                            <div className="w-1/2 mb-6 text-left">
                                <label className="mb-2 text-sm font-medium text-gray-900">
                                    Vintage Registry Url
                                </label>
                                <input
                                    type="text"
                                    className="my-2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                    placeholder="Vintage Registry Url"
                                    onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                        setFormData({
                                            ...formData,
                                            vintage_registry_url: e.target.value,
                                        })
                                    }}
                                />
                                <div className="h-5">
                                    {formError?.vintage_registry_url && (
                                        <ErrorLabel error={formError.vintage_registry_url} />
                                    )}
                                </div>
                            </div>
                            <div className="mb-6 text-left w-1/2">
                                <label className="mb-2 text-sm font-medium text-gray-900">
                                    Vintage
                                </label>
                                <select
                                    value={formData.vintage ?? ''}
                                    className="block h-[42px] p-2 my-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                    onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                        setFormData({
                                            ...formData,
                                            vintage: e.target.value,
                                        })
                                    }}
                                >
                                    <option>Choose</option>
                                    {renderAvailableVintages()}
                                </select>
                                <div className="h-5">
                                    {formError?.vintage && <ErrorLabel error={formError.vintage} />}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <h3 className="bg-gray-300 text-left mb-5">Beneficiary Tenant and Tenant Customer</h3>
            <div className="mb-6">
                <div className="flex flex-row gap-4 justify-between w-3/5">
                    <div className="w-1/3 mb-6 text-left">
                        <label className="mb-2 text-sm font-medium">Beneficiary Tenant</label>
                        <select
                            className="block p-2 mb-6 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                setFormData({
                                    ...formData,
                                    beneficiary_tenant_id: e.target.value as string,
                                })
                            }}
                        >
                            <option>Choose</option>
                            {beneficiaryTenants.map((tenant) => (
                                <option key={tenant.id} value={tenant.id}>
                                    {tenant.entity_name}
                                </option>
                            ))}
                        </select>
                        {formError?.beneficiary_tenant_id && (
                            <ErrorLabel error={formError.beneficiary_tenant_id} />
                        )}
                    </div>
                    <div className="w-[45%] text-left">
                        <label className="mb-2 text-sm font-medium">Tenant Customer</label>
                        <select
                            className="block p-2 mb-6 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                            onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                                setFormData({
                                    ...formData,
                                    tenant_customer_id: e.target.value,
                                })
                            }}
                        >
                            <option value={''} selected>
                                Choose
                            </option>
                            {tenantCustomers.length > 0 ? (
                                tenantCustomers.map((customer) => (
                                    <option key={customer.id} value={customer.id}>
                                        {customer.name}
                                    </option>
                                ))
                            ) : (
                                <></>
                            )}
                        </select>
                        {formError?.tenant_customer_id && (
                            <ErrorLabel error={formError.tenant_customer_id} />
                        )}
                    </div>
                </div>
                <div className="h-5 text-left mb-4">
                    {formError?.tenant_customer_id && (
                        <ErrorLabel error={formError?.tenant_customer_id} />
                    )}
                </div>
            </div>
            <h3 className="pl-5 bg-gray-300 text-left mb-5">Invoice Details</h3>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Price Paid Per Credit
                    </label>
                    <div className="flex flex-row items-center h-[38px] my-2 py-2 pl-3 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500">
                        <p className="border-1.5 mr-1.5 border-gray-300 rounded text-sm font-normal">
                            $
                        </p>
                        <NumberInput
                            initialValue={formData.price_paid_per_credit}
                            onChange={(value) => {
                                setFormData({
                                    ...formData,
                                    price_paid_per_credit: value as string,
                                })
                            }}
                            min={0}
                        />
                    </div>
                    <div className="h-5">
                        {formError?.price_paid_per_credit && (
                            <ErrorLabel error={formError?.price_paid_per_credit} />
                        )}
                    </div>
                </div>
                <div className="w-1/2 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Expected Amount
                    </label>
                    <div
                        className="items-center h-[38px] block my-2 p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                        style={{
                            padding: '8px 0 8px 12px',
                        }}
                    >
                        <NumberInput
                            initialValue={formData.amount}
                            scale={0}
                            placeholder="0"
                            onChange={(value) => {
                                setFormData({
                                    ...formData,
                                    amount: value as string,
                                })
                            }}
                            min={0}
                        />
                    </div>
                    {formError.amount && <ErrorLabel error={formError.amount} />}
                </div>
            </div>
            <div className="flex flex-row gap-4 justify-between">
                <div className="w-1/2 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">Invoice Status</label>
                    <select
                        value={formData.invoice_status ?? ''}
                        className="block p-2 my-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                        onChange={(e: ChangeEvent<HTMLSelectElement>) => {
                            setFormData({
                                ...formData,
                                invoice_status: e.target.value
                                    ? (e.target.value as InvoiceStatus)
                                    : undefined,
                            })
                        }}
                    >
                        <option value={''}>Choose</option>
                        {(Object.keys(InvoiceStatus) as Array<keyof typeof InvoiceStatus>).map(
                            (key) => (
                                <option key={key} value={InvoiceStatus[key]}>
                                    {InvoiceStatus[key]}
                                </option>
                            ),
                        )}
                    </select>
                    <div className="h-5">
                        {formError?.invoice_status && (
                            <ErrorLabel error={formError?.invoice_status} />
                        )}
                    </div>
                </div>
                <div className="w-1/2 mb-6 text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">
                        Invoice Reference
                    </label>
                    <input
                        type="text"
                        placeholder="Invoice Reference..."
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setFormData({
                                ...formData,
                                invoice_reference: e.target.value,
                            })
                        }}
                        className="h-[38px] block my-2 p-4 w-full text-gray-900 bg-gray-50 rounded-lg border border-gray-300 sm:text-md focus:ring-blue-500 focus:border-blue-500"
                    />
                    <div className="h-5">
                        {formError?.invoice_reference && (
                            <ErrorLabel error={formError?.invoice_reference} />
                        )}
                    </div>
                </div>
            </div>
            {error && <ErrorLabel error={error} />}
            <div className="flex flex-row justify-between">
                <div className="flex flex-row">
                    <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                        <button
                            type="button"
                            onClick={() => onSubmit('save')}
                            disabled={
                                showSpinner ||
                                (selectedButton === 'create' && Object.keys(formData).length === 0)
                            }
                            className={`text-white bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
                                showSpinner ? 'bg-gray-200' : null
                            }`}
                        >
                            Save
                        </button>
                    </div>
                    <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                        <button
                            onClick={() => onSubmit('saveAndValidate')}
                            type="button"
                            disabled={showSpinner}
                            className={`text-white bg-blue-700 font-medium rounded-lg text-sm px-5 py-2.5 text-center ${
                                showSpinner ? 'bg-gray-200' : null
                            }`}
                        >
                            Save and mark as ready
                        </button>
                    </div>
                </div>
                <div className="flex items-center p-6 space-x-2 border-t border-gray-200 rounded-b">
                    <button
                        onClick={() => navigate(Page.EXPECTED_BLOCKS)}
                        type="button"
                        className="text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                    >
                        Cancel
                    </button>
                </div>
            </div>
            <div className="absolute bottom-[100px] items-center w-full h-4 justify-center">
                {showSpinner && <Spinner size="xl" />}
            </div>
        </form>
    )
}

export default CreateExpectedBlockForm
