import { BigNumber } from 'ethers'

export const convertCentsToLocaleUsd = (number: BigNumber): string => {
    if (number.toString().length < 4) {
        return Number(number.toNumber() / 100).toLocaleString('en-US', {
            style: 'currency',
            currency: 'USD',
        })
    }
    const dollars = BigInt(number.div(100).toString())
    const cents = number.mod(100).toString()
    if (cents.length === 1) {
        return dollars
            .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
            .slice(0, -2)
            .concat(`0${cents}`)
    }
    return dollars
        .toLocaleString('en-US', { style: 'currency', currency: 'USD' })
        .slice(0, -2)
        .concat(cents)
}
