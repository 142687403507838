import { Page } from '../enums/Page'
import { CookieKey } from '../storage/enums/CookieKey'
import { getCookie } from '../storage/useCookie'
import React from 'react'
import { Navigate } from 'react-router-dom'

interface RouterGuardProps {
    children: JSX.Element | JSX.Element[]
    isAuthenticatedUserArea?: boolean
}

const RouterGuard = ({ children, isAuthenticatedUserArea }: RouterGuardProps): JSX.Element => {
    if (
        isAuthenticatedUserArea &&
        (!getCookie(CookieKey.API_KEY) || !getCookie(CookieKey.API_URL))
    ) {
        return <Navigate to={Page.SIGN_IN} />
    }
    // prevent the authenticated users from viewing /signin page and redirect them to the home page
    if (
        getCookie(CookieKey.API_KEY) &&
        getCookie(CookieKey.API_URL) &&
        location.pathname.includes(Page.SIGN_IN)
    ) {
        return <Navigate to={Page.ASYNC_ITEMS} />
    }
    return <React.Fragment>{children}</React.Fragment>
}

export default RouterGuard
