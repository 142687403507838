import { UserApi } from '../api/user'
import Layout from '../components/Layout'
import UpdateUserForm from '../components/forms/UpdateUserForm'
import { Page } from '../enums/Page'
import { User } from '../types/User'
import { Spinner } from 'flowbite-react'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

const UpdateUser = (): JSX.Element => {
    const [user, setUser] = useState<User>()
    const [showSpinner, setShowSpinner] = useState<boolean>(false)
    const navigate = useNavigate()
    const { id: userId } = useParams<{ id: string }>()
    const intervalRef = useRef<any>(null)

    const fetchUserById = async (id: string) => {
        try {
            setShowSpinner(true)
            const response = await UserApi.getUserById(id)
            setUser(response.data)
            setShowSpinner(false)
        } catch (e) {
            setShowSpinner(false)
        }
    }

    useEffect(() => {
        intervalRef.current = setTimeout(() => {
            if (userId) {
                void fetchUserById(userId)
            }
        }, 0)
        return () => clearTimeout(intervalRef.current)
    }, [userId])

    const handleOnReturn = () => {
        navigate(Page.USERS)
    }

    const handleOnSaved = () => {
        navigate(Page.USERS)
    }

    return (
        <Layout>
            <>
                {user && (
                    <UpdateUserForm user={user} onSaved={handleOnSaved} onReturn={handleOnReturn} />
                )}
                {showSpinner && <Spinner size="xl" />}
            </>
        </Layout>
    )
}

export default UpdateUser
