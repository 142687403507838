export enum Page {
    SIGN_IN = '/signin',
    ASYNC_ITEMS = '/',
    BRIDGE = '/bridge',
    RETIRE = '/retire',
    USERS = '/users',
    RETIREMENT = '/retirement-event/:id',
    RETIREMENT_REQUESTS = '/retirement-requests',
    CREATE_UNBRIDGE_REQUEST = '/create-unbridge-request',
    UNBRIDGING_EVENT = '/unbridging-event/:id',
    UNBRIDGE_REQUESTS = '/unbridge-requests',
    CREATE_USER = '/admin/users/create',
    EXPECTED_BLOCKS = '/admin/expected-blocks',
    CREATE_EXPECTED_BLOCKS = '/admin/expected-blocks/create',
    EDIT_EXPECTED_BLOCKS = '/admin/expected-blocks/edit/:id',
    UPDATE_USER = '/admin/users/:id',
    UPDATE_TENANT = '/admin/tenants/:id',
    WEBHOOKS = '/admin/webhooks',
    CREATE_WEBHOOK = '/admin/webhooks/create',
    EDIT_WEBHOOK = '/admin/webhooks/:id/edit',
    MANAGE_TENANT_CUSTOMERS = '/admin/tenant-customers/:id',
    ADD_TENANT_CUSTOMER = '/admin/tenant-customers/add/:id',
    EDIT_TENANT_CUSTOMER = '/admin/tenant-customers/edit/:id',
    ADD_TENANT_CUSTOMER_REGISTRY_ID = '/admin/tenant-customers/add-registry-id/:id',
}
