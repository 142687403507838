import NavBar from './NavBar'

const Header = (): JSX.Element => {
    return (
        <div className="h-32 bg-[#3e3d3d] flex flex-row align-center items-center overflow-scroll md:overflow-auto">
            <img src="/thallo_logo_stacked_white_text.png" className="h-32" />
            <NavBar />
        </div>
    )
}

export default Header
