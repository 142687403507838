/* eslint-disable indent */
import { BridgeCreditsApi } from '../../api/bridgeCreditsApi'
import { Block } from '../../screens/Retirement'
import { BridgedBlock } from '../../types/BridgedBlock'
import { RetirementRequest } from '../../types/RetirementRequest'
import RetirementEventSelectList from './RetirementEventSelectList'
import RetirementEventSelectTable from './RetirementEventSelectTable'
import { BigNumber } from 'ethers'
import React, { useEffect, useState } from 'react'
import { ActionMeta, SingleValue } from 'react-select'
import { FilterOptionOption } from 'react-select/dist/declarations/src/filters'

interface RetirementEventSelectBlocksProps {
    blocks: Block[]
    onChange: (blocks: Block[]) => void
    bridgedBlocks: BridgedBlock[]
    canRetireBlocks: boolean
    setSerialsValidated: (serialsValidated: boolean) => void
    retirementRequest: RetirementRequest | null
}

export interface RetirementEventSelectBlocksPresentationProps {
    blocks: Block[]
    getSelectOptions: () => SelectOption[]
    onChangeSelectSerialInputHandler: (
        index: number,
        opt: SingleValue<{ value: number; label: string }>,
        action: ActionMeta<{ value: number; label: string }>,
    ) => void
    getSelectFilterOptions: (
        option: FilterOptionOption<{ value: number; label: string }>,
        inputValue: string,
    ) => boolean
    onRemoveBlockButtonClickedHandler: (idx: number) => void
    onChangeAmountToRetireInputHandler: (
        fieldName: string,
        valueUpdate: number | string,
        idx: number,
    ) => void
    onBlurBlockInput: (idx: number, fieldName: string) => void
    validationData: ValidationData[]
}

export interface RetirementEventSelectBlocksPresentationItemProps {
    index: number
    block: Block
    getSelectOptions: () => SelectOption[]
    onChangeSelectSerialInputHandler: (
        index: number,
        opt: SingleValue<{ value: number; label: string }>,
        action: ActionMeta<{ value: number; label: string }>,
    ) => void
    getSelectFilterOption: (
        option: FilterOptionOption<{ value: number; label: string }>,
        inputValue: string,
    ) => boolean
    onRemoveBlockButtonClickedHandler: (idx: number) => void
    onChangeAmountToRetireInputHandler: (
        fieldName: string,
        valueUpdate: number | string,
        idx: number,
    ) => void
    onBlurBlockInput: (idx: number, fieldName: string) => void
    validationData: ValidationData | null
}

interface ValidationError {
    fieldName: string
    message: string
}

export interface ValidationData {
    index: number
    warnings: ValidationError[]
    errors: ValidationError[]
}

export interface SelectOption {
    label: string
    value: number
}

enum SelectedView {
    TABLE = 'TABLE',
    ITEMS_LIST = 'ITEMS_LIST',
}

const RetirementEventSelectBlocks = ({
    blocks,
    onChange,
    bridgedBlocks,
    canRetireBlocks,
    setSerialsValidated,
    retirementRequest,
}: RetirementEventSelectBlocksProps): JSX.Element => {
    const [selectedView, setSelectedView] = useState<SelectedView>(SelectedView.TABLE)
    const [validationData, setValidationData] = useState<ValidationData[]>([])

    useEffect(() => {
        setSerialsValidated(false)
        const errors = validationData.reduce((acc: ValidationError[], currentValue) => {
            acc = [...acc, ...currentValue.errors]
            return acc
        }, [])

        if (errors.length > 0) {
            setSerialsValidated(false)
        } else {
            setSerialsValidated(true)
        }
    }, [validationData])
    const onAddBlockClicked = (): void => {
        onChange([
            ...blocks,
            {
                id: '',
                vintage_id: '',
                registry: '',
                amount: '',
                serial: '',
                retirementAmountForThisBlock: 0,
                retirementBlockSerial: '',
                remainingBlockSerial: '',
                registry_id: '',
                blockchain: '',
            },
        ])
    }

    const isAddBlockButtonDisabled = blocks.length >= bridgedBlocks.length

    const handleValidationError = (
        index: number,
        fieldName: string,
        type: 'warning' | 'error',
        message: string,
    ) => {
        const validationUpdate = [...validationData]
        const currentValidationDataItemIdx = validationUpdate.findIndex((i) => i.index === index)
        if (currentValidationDataItemIdx === -1) {
            const newValidationItem = {
                index,
                errors: [],
                warnings: [],
            } as ValidationData
            if (type === 'error') {
                newValidationItem.errors.push({ fieldName, message } as ValidationError)
            }
            if (type === 'warning') {
                newValidationItem.warnings.push({ fieldName, message } as ValidationError)
            }
            validationUpdate.push(newValidationItem)
            setValidationData(validationUpdate)
            return
        }
        validationUpdate[currentValidationDataItemIdx] = {
            ...validationUpdate[currentValidationDataItemIdx],
            ...(type === 'warning' && {
                warnings: [
                    {
                        fieldName,
                        message,
                    },
                ],
            }),
            ...(type === 'error' && {
                errors: [
                    ...validationUpdate[currentValidationDataItemIdx].errors,
                    { fieldName, message },
                ],
            }),
        } as ValidationData
        setValidationData(validationUpdate)
    }

    const clearValidationError = (index: number, fieldName: string) => {
        const validationUpdate = [...validationData]
        const currentValidationIdx = validationUpdate.findIndex((i) => i.index === index)
        if (currentValidationIdx === -1 && !validationUpdate[currentValidationIdx]) {
            return
        }
        validationUpdate[currentValidationIdx] = {
            ...validationUpdate[currentValidationIdx],
            errors: validationUpdate[currentValidationIdx].errors.filter(
                (i) => i.fieldName !== fieldName,
            ),
            warnings: validationUpdate[currentValidationIdx].warnings.filter(
                (i) => i.fieldName !== fieldName,
            ),
        }
        setValidationData(validationUpdate)
    }

    const onBlockSerialSelected = (index: number, value: string): void => {
        onChange(
            blocks.map((block: Block, i: number) => {
                if (index === i) {
                    const bridgedBlock = bridgedBlocks.find((bb) => bb.serial_number === value)
                    return {
                        ...block,
                        id: bridgedBlock?.id ?? '',
                        vintage_id: bridgedBlock?.vintage_id ?? '',
                        registry: bridgedBlock?.vintage?.project.registry?.name ?? '',
                        amount: bridgedBlock?.quantity
                            ? BigNumber.from(bridgedBlock?.quantity).toString()
                            : '',
                        serial: value,
                        registry_id: bridgedBlock?.registry_id ?? '',
                        blockchain: bridgedBlock?.blockchain ?? '',
                    }
                }
                return block
            }),
        )
        const currentValidationItemIdx = validationData.findIndex((item) => item.index === index)
        const updateValidationData = [...validationData]
        if (currentValidationItemIdx === -1) {
            updateValidationData.push({ index, warnings: [], errors: [] })
        } else {
            updateValidationData[currentValidationItemIdx] = {
                index,
                warnings: [],
                errors: [],
            }
        }
        setValidationData(updateValidationData)
    }

    const getAvailableRequestQuantity = (currentBlockIdx: number): number => {
        let currentQuantityAvailable = BigNumber.from(retirementRequest?.quantity)
        blocks.map((block, index) => {
            if (index === currentBlockIdx) {
                return
            }
            currentQuantityAvailable = currentQuantityAvailable.sub(
                block.retirementAmountForThisBlock,
            )
        })
        return currentQuantityAvailable.toNumber()
    }

    // handler for inputs
    const onChangeSelectSerialInputHandler = (
        index: number,
        opt: SingleValue<{ value: number; label: string }>,
        action: ActionMeta<{ value: number; label: string }>,
    ) => {
        setValidationData(validationData.filter((i) => i.index !== index))
        if (['remove-value', 'deselect-option', 'clear'].includes(action.action)) {
            const currentBlocks = [...blocks]
            currentBlocks[index] = {
                id: '',
                vintage_id: '',
                registry: '',
                amount: '',
                serial: '',
                retirementAmountForThisBlock: 0,
                retirementBlockSerial: '',
                remainingBlockSerial: '',
                registry_id: '',
                blockchain: '',
            }
            onChange(currentBlocks)
        }
        if (!opt) return
        if (action.action === 'select-option') {
            onBlockSerialSelected(index, opt.label)
        }
    }

    const getSelectOptions = (): SelectOption[] => {
        return bridgedBlocks
            .filter(
                (bridgedBlock: BridgedBlock) =>
                    !blocks.find((block: Block) => block.serial === bridgedBlock.serial_number),
            )
            .map((bridgedBlock: BridgedBlock, i: number) => {
                return {
                    label: bridgedBlock.serial_number,
                    value: i,
                }
            })
    }

    const getSelectFilterOptions = (
        opt: FilterOptionOption<SelectOption>,
        inputValue: string,
    ): boolean =>
        !!blocks.find((block) => block.serial !== opt.label) && opt.label.includes(inputValue)

    const onChangeBlockInputHandler = (
        fieldName: string,
        valueUpdate: number | string,
        idx: number,
    ) => {
        const blocksUpdated = [
            ...blocks.map((currentBlock, index) => {
                if (index === idx) {
                    clearValidationError(idx, fieldName)
                    return {
                        ...currentBlock,
                        [fieldName]:
                            fieldName === 'retirementAmountForThisBlock'
                                ? !!retirementRequest?.quantity &&
                                  Number(valueUpdate) > getAvailableRequestQuantity(index)
                                    ? getAvailableRequestQuantity(index)
                                    : valueUpdate
                                : valueUpdate,
                    }
                }
                return currentBlock as Block
            }),
        ]
        onChange(blocksUpdated)
    }

    //onblur
    const onBlurBlockInput = async (idx: number, fieldName: string) => {
        const blocksUpdate = [...blocks]
        if (fieldName === 'retirementBlockSerial' || fieldName === 'remainingBlockSerial') {
            blocksUpdate[idx] = {
                ...blocks[idx],
                [fieldName]: String(blocks[idx][fieldName]).trim(),
            }
            // validate if only serial set
            if (!!blocks[idx].serial) {
                const currentBridgedSerialBlock = bridgedBlocks.find(
                    (i) => i.serial_number === blocks[idx].serial,
                )
                if (
                    Number(blocks[idx].amount) != blocks[idx].retirementAmountForThisBlock &&
                    (blocks[idx][fieldName] === '' || /\s/.test(blocks[idx][fieldName]))
                ) {
                    handleValidationError(
                        idx,
                        fieldName,
                        'error',
                        `Please check ${
                            (fieldName === 'retirementBlockSerial' && 'retired') ||
                            (fieldName === 'remainingBlockSerial' && 'remaining')
                        } block serials`,
                    )
                }

                if (fieldName === 'retirementBlockSerial' || fieldName === 'remainingBlockSerial') {
                    const isRetirementBlockSerial = fieldName === 'retirementBlockSerial'

                    if (Number(blocks[idx].amount) != blocks[idx].retirementAmountForThisBlock) {
                        const { data } = await BridgeCreditsApi.isSerialValid(
                            blocks[idx].registry_id,
                            blocks[idx][fieldName],
                            blocks[idx].id,
                            isRetirementBlockSerial,
                        )
                        if (!data) {
                            handleValidationError(
                                idx,
                                fieldName,
                                'error',
                                `${
                                    isRetirementBlockSerial ? 'Retired' : 'Remaining'
                                } Block Serial should be unique`,
                            )
                        }
                    }

                    if (
                        blocks[idx].remainingBlockSerial === blocks[idx].retirementBlockSerial &&
                        currentBridgedSerialBlock?.vintage?.project?.registry?.name !==
                            'BioCarbon Registry'
                    ) {
                        handleValidationError(
                            idx,
                            fieldName,
                            'error',
                            `${
                                isRetirementBlockSerial ? 'Retired' : 'Remaining'
                            } Block Serial should be unique`,
                        )
                    }
                }

                if (
                    fieldName === 'remainingBlockSerial' &&
                    currentBridgedSerialBlock?.vintage?.project?.registry?.name ===
                        'BioCarbon Registry' &&
                    blocks[idx].serial !== blocks[idx].remainingBlockSerial &&
                    blocks[idx].remainingBlockSerial !== '' &&
                    Number(blocks[idx].amount) != blocks[idx].retirementAmountForThisBlock
                ) {
                    handleValidationError(
                        idx,
                        fieldName,
                        'warning',
                        'Remaining Block Serial does not match Block Serial!',
                    )
                }
            }
        }
        onChange(blocksUpdate)
    }
    const onRemoveBlockButtonClickedHandler = (idx: number) => {
        onChange([...blocks].filter((block: Block, i: number) => idx !== i))
        setValidationData(validationData.filter((i) => i.index !== idx))
    }

    return (
        <div>
            <div className="text-primary-600 mx-auto text-left">
                Pay attention total retirement amount can't be greater than total retirement request
                amount. Currently available retirement request amount:{' '}
                {getAvailableRequestQuantity(-1)}
            </div>
            <div className="flex justify-between items-center m-2">
                {canRetireBlocks && (
                    <div className="mt-4 flex">
                        <button
                            className={`px-4 py-2.5 rounded-lg text-white bg-[#3e3d3d] ${
                                isAddBlockButtonDisabled ? 'bg-gray-300' : ''
                            }`}
                            onClick={onAddBlockClicked}
                            disabled={isAddBlockButtonDisabled}
                        >
                            Add Block
                        </button>
                    </div>
                )}
                <div>
                    <button
                        type="button"
                        className={`rounded-lg p-3 text-white font-semibold text-sm ${
                            selectedView === SelectedView.ITEMS_LIST
                                ? 'bg-primary-600'
                                : 'bg-primary-100'
                        }`}
                        onClick={() => setSelectedView(SelectedView.ITEMS_LIST)}
                        title="List"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            width="160"
                            height="140"
                            className="svg-switch-button"
                        >
                            <path d="M40 48C26.7 48 16 58.7 16 72v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V72c0-13.3-10.7-24-24-24H40zM192 64c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zm0 160c-17.7 0-32 14.3-32 32s14.3 32 32 32H480c17.7 0 32-14.3 32-32s-14.3-32-32-32H192zM16 232v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V232c0-13.3-10.7-24-24-24H40c-13.3 0-24 10.7-24 24zM40 368c-13.3 0-24 10.7-24 24v48c0 13.3 10.7 24 24 24H88c13.3 0 24-10.7 24-24V392c0-13.3-10.7-24-24-24H40z" />
                        </svg>
                    </button>
                    <button
                        type="button"
                        className={`ml-2 rounded-lg p-3 text-white font-semibold text-sm ${
                            selectedView === SelectedView.TABLE
                                ? 'bg-primary-600'
                                : 'bg-primary-100'
                        }`}
                        onClick={() => setSelectedView(SelectedView.TABLE)}
                        title="Table"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 512 512"
                            className="svg-switch-button"
                        >
                            <path d="M0 96C0 60.7 28.7 32 64 32H448c35.3 0 64 28.7 64 64V416c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V96zm64 0v64h64V96H64zm384 0H192v64H448V96zM64 224v64h64V224H64zm384 0H192v64H448V224zM64 352v64h64V352H64zm384 0H192v64H448V352z" />
                        </svg>
                    </button>
                </div>
            </div>
            {selectedView === SelectedView.TABLE && (
                <RetirementEventSelectTable
                    blocks={blocks}
                    getSelectOptions={getSelectOptions}
                    onChangeSelectSerialInputHandler={onChangeSelectSerialInputHandler}
                    getSelectFilterOptions={getSelectFilterOptions}
                    onRemoveBlockButtonClickedHandler={onRemoveBlockButtonClickedHandler}
                    onChangeAmountToRetireInputHandler={onChangeBlockInputHandler}
                    onBlurBlockInput={onBlurBlockInput}
                    validationData={validationData}
                />
            )}
            {selectedView === SelectedView.ITEMS_LIST && (
                <RetirementEventSelectList
                    blocks={blocks}
                    getSelectOptions={getSelectOptions}
                    onChangeSelectSerialInputHandler={onChangeSelectSerialInputHandler}
                    getSelectFilterOptions={getSelectFilterOptions}
                    onRemoveBlockButtonClickedHandler={onRemoveBlockButtonClickedHandler}
                    onChangeAmountToRetireInputHandler={onChangeBlockInputHandler}
                    onBlurBlockInput={onBlurBlockInput}
                    validationData={validationData}
                />
            )}
        </div>
    )
}

export default RetirementEventSelectBlocks
