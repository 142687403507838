import { BridgeCreditsForExistingProjectAndVintagePayload } from '../types/BridgeCreditsForExistingProjectAndVintagePayload'
import { BridgeCreditsPayload } from '../types/BridgeCreditsPayload'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface BridgeCreditsApiApiInterface {
    save(payload: BridgeCreditsPayload): Promise<AxiosResponse<void>>
    saveForExisingProjectAndVintageId(
        payload: BridgeCreditsForExistingProjectAndVintagePayload,
    ): Promise<AxiosResponse<void>>
    isSerialValid(
        registryId: string,
        serialNumber: string,
        block_id: string | null,
        is_retired_serial: boolean,
    ): Promise<AxiosResponse<boolean>>
    cancel(asyncItemId: string): Promise<AxiosResponse<void>>
}

export const BridgeCreditsApi: BridgeCreditsApiApiInterface = Object.freeze({
    save(payload: BridgeCreditsPayload): Promise<AxiosResponse<void>> {
        return ApiService.post('/admin/bridge-credits', payload)
    },
    saveForExisingProjectAndVintageId(
        payload: BridgeCreditsForExistingProjectAndVintagePayload,
    ): Promise<AxiosResponse<void>> {
        return ApiService.post('/admin/bridge-credits/existing-project-and-vintage', payload)
    },
    isSerialValid(
        registry_id: string,
        serial_number: string,
        block_id: string | null,
        is_retired_serial: boolean,
    ): Promise<AxiosResponse<boolean>> {
        const params = {
            registry_id,
            serial_number,
            block_id,
            is_retired_serial,
        }
        return ApiService.get('/admin/bridge-credits/is-serial-valid', null, params)
    },
    cancel(asyncItemId: string): Promise<AxiosResponse<void>> {
        return ApiService.post(`/admin/bridge-credits/cancel/${asyncItemId}`, {})
    },
})
