import { EmptyResponse } from '../types/EmptyResponse'
import { XRPLAccountSetParams } from '../types/xrplAccountSetParams'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface XRPLApiInterface {
    performAccountSet(payload: XRPLAccountSetParams): Promise<AxiosResponse<EmptyResponse>>
}

export const XRPLApi: XRPLApiInterface = Object.freeze({
    performAccountSet(payload: XRPLAccountSetParams): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.put(`/admin/xrpl/account-set`, payload)
    },
})
