import Layout from '../components/Layout'
import CreateWebhookForm from '../components/forms/CreateWebhookForm'

const CreateWebhook = (): JSX.Element => {
    return (
        <Layout>
            <CreateWebhookForm />
        </Layout>
    )
}

export default CreateWebhook
