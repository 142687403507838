import { RegistryIds, registryNames } from '../enums/RegistryIds'
import { BridgedBlock } from '../types/BridgedBlock'
import { RetirementRequest } from '../types/RetirementRequest'

interface RetirementEventSourceBlocksProps {
    bridgedBlocks: BridgedBlock[]
    retirementRequest: RetirementRequest | null
}

const RetirementEventRetiredBlocks = ({
    bridgedBlocks,
    retirementRequest,
}: RetirementEventSourceBlocksProps): JSX.Element => {
    const determineStatus = (block: BridgedBlock): string => {
        if (block.status === 'SUPERSEDED') {
            return block.source_async_item_id === retirementRequest?.async_item_id
                ? 'SUPERSEDED (LIVE)'
                : 'SUPERSEDED (SOURCE)'
        } else {
            return block.status
        }
    }

    return (
        <div className="">
            <table className="overflow-x-scroll md:overflow-auto block md:table md:table-auto w-full">
                <thead className="">
                    <tr className="h-11 items-center bg-[#3e3d3d] text-white px-4 divide-x-[1px] divide-x-stone-300">
                        <th className="font-semibold text-xs text-center px-4">Block Serial</th>
                        <th className="font-semibold text-xs text-center px-4">Id</th>
                        <th className="font-semibold text-xs text-center px-4">Block Registry</th>
                        <th className="font-semibold text-xs text-center px-4">Block Amount</th>
                        <th className="font-semibold text-xs text-center px-4">
                            Retirement Amount For This Block
                        </th>
                        <th className="font-semibold text-xs text-center px-4">Status</th>
                    </tr>
                </thead>
                <tbody>
                    {bridgedBlocks.map((block, index: number) => {
                        return (
                            <tr className="h-18 items-center w-full" key={index}>
                                <td className="text-sm text-center font-normal px-[2px] align-middle">
                                    <div className="block p-2 text-sm">{block.serial_number}</div>
                                </td>
                                <td className="text-sm text-center font-normal px-[2px] align-middle">
                                    {block.id}
                                </td>
                                <td className="text-sm text-center font-normal px-[2px] align-middle">
                                    {registryNames[(block?.registry_id || '') as RegistryIds] || ''}
                                </td>
                                <td className="text-sm text-center font-normal px-[2px] align-middle">
                                    {block.quantity.toString()}
                                </td>
                                <td className="text-sm text-center font-normal px-[2px] align-middle">
                                    <div className="w-3/4 mx-auto">
                                        {block.status === 'RETIRED' &&
                                        block.retirement_request_id === retirementRequest?.id
                                            ? block.quantity.toString()
                                            : '-'}
                                    </div>
                                </td>
                                <td className="text-sm text-center font-normal px-[2px] align-middle">
                                    {determineStatus(block)}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    )
}

export default RetirementEventRetiredBlocks
