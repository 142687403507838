import { EmptyResponse } from '../types/EmptyResponse'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface CreateOrEditInterface {
    tenant_customer_id: string
    registry_id: string
    registry_user_id: string
}

export interface GetByTenantCustomerIdRegistryIdResponse {
    id: string
    tenant_customer_id: string
    registry_id: string
    registry_user_id: string
}

export interface TenantCustomerRegistryIdApiInterface {
    getByTenantCustomerIdAndRegistryId(
        tenantCustomerId: string,
        registryId: string,
    ): Promise<AxiosResponse<GetByTenantCustomerIdRegistryIdResponse>>
    createOrUpdate(payload: CreateOrEditInterface): Promise<AxiosResponse<EmptyResponse>>
}

export const TenantCustomerRegistryIdApi: TenantCustomerRegistryIdApiInterface = Object.freeze({
    getByTenantCustomerIdAndRegistryId(
        tenantCustomerId: string,
        registryId: string,
    ): Promise<AxiosResponse<GetByTenantCustomerIdRegistryIdResponse>> {
        return ApiService.get(
            'admin/tenant-customer-registry-id/' + tenantCustomerId + '/' + registryId,
        )
    },
    createOrUpdate(payload: CreateOrEditInterface): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('admin/tenant-customer-registry-id/createOrUpdate', payload)
    },
})
