import { TenantApi } from '../api/tenant'
import { TenantCustomerApi } from '../api/tenantCustomer'
import Layout from '../components/Layout'
import { Page } from '../enums/Page'
import { Tenant } from '../types/Tenant'
import { TenantCustomer } from '../types/TenantCustomer'
import { useEffect, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'

const ManageTenantCustomers = (): JSX.Element => {
    const { id: tenantId } = useParams<{ id: string }>()
    const [tenantCustomers, setTenantCustomers] = useState<TenantCustomer[]>([])
    const [tenant, setTenant] = useState<Tenant>()
    const navigate = useNavigate()

    const fetchTenantCustomers = async () => {
        const response = await TenantCustomerApi.getTenantCustomersByTenantId(tenantId ?? '')
        setTenantCustomers(response.data.tenant_customers)
    }

    const fetchTenant = async () => {
        const response = await TenantApi.getTenantById(tenantId ?? '')
        setTenant(response.data)
    }

    useEffect(() => {
        void fetchTenant()
        void fetchTenantCustomers()
    }, [tenantId])

    return (
        <Layout>
            <button
                className="px-4 py-2 flex justify-center items-center border border-black rounded-lg"
                onClick={() =>
                    navigate(
                        generatePath(Page.ADD_TENANT_CUSTOMER, {
                            id: tenantId ?? '',
                        }),
                    )
                }
            >
                <svg xmlns="http://www.w3.org/2000/svg" className="w-4 h-4" viewBox="0 0 448 512">
                    <path d="M256 80c0-17.7-14.3-32-32-32s-32 14.3-32 32V224H48c-17.7 0-32 14.3-32 32s14.3 32 32 32H192V432c0 17.7 14.3 32 32 32s32-14.3 32-32V288H400c17.7 0 32-14.3 32-32s-14.3-32-32-32H256V80z" />
                </svg>
                <p className="inline-block ml-2">Add Tenant Customer</p>
            </button>
            <div>
                Tenant Customers for {tenant?.entity_name} (ID: {tenant?.id})
            </div>
            <table className="w-full mt-6">
                <thead>
                    <tr className="h-12 flex items-center w-full bg-gray-100 rounded-md">
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                            Tenant Customer Id
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                            Tenant Customer Name
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                            External Customer Id
                        </th>
                        <th className="flex-1 text-gray-500 font-semibold text-xs text-left pl-6 whitespace-nowrap">
                            Actions
                        </th>
                    </tr>
                </thead>
                {tenantCustomers?.map((tenantCustomer) => (
                    <tbody
                        key={tenantCustomer.id}
                        className="bg-gray-300 rounded-lg block mt-3 first-of-type:mt-1"
                    >
                        <tr className="h-14 flex items-center w-full" key={tenantCustomer.id}>
                            <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                {tenantCustomer.id}
                            </td>
                            <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                {tenantCustomer.name}
                            </td>
                            <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                {tenantCustomer.external_customer_id}
                            </td>
                            <td className="flex-1 text-gray-500 text-sm text-left pl-6 font-normal">
                                <div className="flex flex-row">
                                    <div className="flex-1 items-center">
                                        <img
                                            className="cursor-pointer"
                                            src="/assets/icon_edit.svg"
                                            alt="edit"
                                            onClick={() =>
                                                navigate(
                                                    generatePath(Page.EDIT_TENANT_CUSTOMER, {
                                                        id: tenantCustomer.id,
                                                    }),
                                                )
                                            }
                                        />
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                ))}
            </table>
        </Layout>
    )
}

export default ManageTenantCustomers
