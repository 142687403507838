import { FileType } from '../enums/FileType'
import { EmptyResponse } from '../types/EmptyResponse'
import { Pagination } from '../types/Pagination'
import { TenantCustomer } from '../types/TenantCustomer'
import ApiService from './apiService'
import { AxiosResponse } from 'axios'

export interface TenantCustomerResponse {
    tenant_customers: TenantCustomer[]
}

export interface PaginatedTenantCustomerResponse {
    tenant_customers: TenantCustomer[]
    pagination: Pagination
}

export interface TenantCustomerResponseSingle {
    id: string
    tenant_id: string
    external_id: string
    name?: string
}

export interface CreateTenantCustomerPayload {
    tenant_id: string
    external_customer_id: string
    name?: string
    nft_branding_enabled: boolean
    branding_colour: string | null
    branding_text_colour: string | undefined
    branding_file: string | null
    branding_file_extension: FileType | null
}

export interface TenantCustomerApiInterface {
    getTenantCustomersByTenantId(tenantId: string): Promise<AxiosResponse<TenantCustomerResponse>>
    getById(id: string): Promise<AxiosResponse<TenantCustomer>>
    update(
        payload: CreateTenantCustomerPayload,
        tenantCustomerId: string,
    ): Promise<AxiosResponse<EmptyResponse>>
    create(payload: CreateTenantCustomerPayload): Promise<AxiosResponse<EmptyResponse>>
    getAll(perPage: number, page: number): Promise<AxiosResponse<PaginatedTenantCustomerResponse>>
}

export const TenantCustomerApi: TenantCustomerApiInterface = Object.freeze({
    getTenantCustomersByTenantId(tenantId: string): Promise<AxiosResponse<TenantCustomerResponse>> {
        return ApiService.get('admin/tenant-customers/get-all/' + tenantId)
    },
    getById(id: string): Promise<AxiosResponse<TenantCustomer>> {
        return ApiService.get('admin/tenant-customers/get/' + id)
    },
    update(
        payload: CreateTenantCustomerPayload,
        tenantCustomerId: string,
    ): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('admin/tenant-customers/update/' + tenantCustomerId, payload)
    },
    create(payload: CreateTenantCustomerPayload): Promise<AxiosResponse<EmptyResponse>> {
        return ApiService.post('admin/tenant-customers/', payload)
    },
    getAll(perPage: number, page: number): Promise<AxiosResponse<PaginatedTenantCustomerResponse>> {
        return ApiService.get(`admin/tenant-customers?per_page=${perPage}&page=${page}`)
    },
})
