import { ProjectApi } from '../api/project'
import { ExpectedBlock } from '../types/ExpectedBlock'
import { ProjectType } from '../types/ProjectType'
import ErrorLabel from './ErrorLabel'
import { useEffect, useState } from 'react'
import { SingleValue } from 'react-select'
import AsyncSelect from 'react-select/async'

export enum SelectExistingProjectError {
    NO_PROJECT_SELECTED = 'No project selected',
    NO_VINTAGE_SELECTED = 'No vintage selected',
}

export interface SelectedExistingProjectAndVintageProps {
    selectExistingProjectError: SelectExistingProjectError | null
    onProjectSelected: (project: ProjectType) => void
    onSelectedVintage: (vintageId: string) => void
    onChangeVintageRegistryUrl: (vintageRegistryUrl: string) => void
    hideRegistryUrlInput?: boolean
    expectedBlock?: ExpectedBlock
}

type Option = { value: string; label: string }

const SelectedExistingProjectAndVintage = ({
    selectExistingProjectError,
    onSelectedVintage,
    onProjectSelected,
    onChangeVintageRegistryUrl,
    hideRegistryUrlInput = false,
    expectedBlock,
}: SelectedExistingProjectAndVintageProps): JSX.Element => {
    const [selectedProject, setSelectedProject] = useState<ProjectType | null>(null)
    const [projects, setProjects] = useState<ProjectType[]>([])
    const [vintage, setVintage] = useState<number | string | null>(null)
    const [vintageRegistryUrl, setVintageRegistryUrl] = useState<string>('')

    const fetchProjects = async () => {
        const response = await ProjectApi.searchProjects({})
        setProjects(response.data.results)
    }

    useEffect(() => {
        void fetchProjects()
    }, [])

    useEffect(() => {
        if (expectedBlock && projects.length > 0) {
            const currentProject = projects.find(
                (p) =>
                    p.registry_project_id === expectedBlock.registry_project_id &&
                    p.registry_id === expectedBlock.registry_id,
            )
            if (!currentProject) {
                return
            }
            setVintage(String(expectedBlock.vintage))
            setSelectedProject(currentProject)
            setVintageRegistryUrl(expectedBlock.vintage_registry_url ?? '')
            onSelectedVintage(String(expectedBlock.vintage))
            onProjectSelected(currentProject)
        }
    }, [projects])

    const filterOptions = (name: string): Option[] => {
        return projects
            ?.filter((project) => project.name.toLowerCase().includes(name.toLowerCase()))
            .map((project) => {
                return {
                    value: project.id,
                    label: project.name,
                }
            })
    }

    const loadOptions = (inputValue: string, callback: (options: Option[]) => void) => {
        callback(filterOptions(inputValue))
    }

    const checkVintageExists = (vintage: string): boolean => {
        if (selectedProject) {
            return (
                selectedProject.vintages.filter((i) => i.vintage.toString() === vintage).length > 0
            )
        }
        return false
    }

    const renderVintages = (): JSX.Element[] => {
        const vintages = []
        for (let i = 2010; i <= new Date().getFullYear() + 1; i++) {
            vintages.push(String(i))
        }
        return vintages.map((vintage: string) => {
            return (
                <option className="flex justify-between" key={vintage} value={vintage}>
                    {vintage} {checkVintageExists(vintage) && ' - Existing'}
                </option>
            )
        })
    }

    const showRegistryUrlInput =
        selectedProject &&
        vintage &&
        vintage !== 'Choose' &&
        !selectedProject.vintages.map((vintage) => vintage.vintage).includes(Number(vintage))

    return (
        <div>
            <h3 className="pl-5 bg-gray-300 text-left mb-5">Select existing project</h3>
            <div className="flex flex-row gap-4">
                <div className="w-full text-left">
                    <label className="mb-2 text-sm font-medium text-gray-900">Select Project</label>
                    <AsyncSelect
                        loadOptions={loadOptions}
                        defaultOptions={projects.map((project) => {
                            return {
                                value: project.id,
                                label: `Project Registry Id: ${project.registry_project_id} - Project Name: ${project.name} - Registry Name: ${project.registry?.name}`,
                            }
                        })}
                        value={
                            /* eslint-disable indent */
                            selectedProject
                                ? {
                                      value: selectedProject.id,
                                      label: `Project Registry Id: ${selectedProject.registry_project_id} - Project Name: ${selectedProject.name} - Registry Name: ${selectedProject.registry?.name}`,
                                  }
                                : undefined
                        }
                        isLoading={false}
                        isSearchable={true}
                        onChange={(value: SingleValue<{ value: string; label: string }>) => {
                            const project = projects.find((project) => project.id === value?.value)
                            if (project) {
                                setSelectedProject(project)
                                onProjectSelected(project)
                            }
                        }}
                        className="mt-2 mb-2"
                    />
                    <div className="h-5">
                        {selectExistingProjectError ===
                            SelectExistingProjectError.NO_PROJECT_SELECTED && (
                            <ErrorLabel error="Project is required" />
                        )}
                    </div>
                </div>
            </div>
            {selectedProject && (
                <div>
                    <h3 className="bg-gray-300 text-left mb-5">Vintage</h3>
                    <div className="flex flex-row gap-4 justify-between">
                        <div className="w-1/2 text-left">
                            <label className="mb-2 text-sm font-medium text-gray-900">
                                Vintage
                            </label>
                            <select
                                className="block my-2 p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                onChange={(e) => {
                                    setVintage(e.target.value)
                                    onSelectedVintage(e.target.value)
                                }}
                                value={vintage ? vintage : undefined}
                            >
                                <option>Choose</option>
                                {renderVintages()}
                            </select>
                            <div className="h-5">
                                {selectExistingProjectError ===
                                    SelectExistingProjectError.NO_VINTAGE_SELECTED && (
                                    <ErrorLabel error="Vintage is required" />
                                )}
                            </div>
                        </div>
                        {showRegistryUrlInput && !hideRegistryUrlInput && (
                            <div className="mb-6 text-left w-1/2">
                                <label className="mb-2 text-sm font-medium text-gray-900">
                                    Vintage Registry url
                                </label>
                                <input
                                    value={vintageRegistryUrl}
                                    onChange={(e) => {
                                        onChangeVintageRegistryUrl(e.target.value)
                                        setVintageRegistryUrl(e.target.value)
                                    }}
                                    onBlur={(e) => setVintageRegistryUrl(e.target.value.trim())}
                                    placeholder="Vintage registry url"
                                    className="block p-2 mt-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
                                ></input>
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default SelectedExistingProjectAndVintage
